import { OptionHTMLAttributes } from "react";

export const preparationProcedureOptions: OptionHTMLAttributes<
  HTMLOptionElement
>[] = [
  {
    label: "Swim Up",
    value: "SwimUp",
  },
  {
    label: "Density Gradient",
    value: "DensityGradient",
  },
  {
    label: "Others",
    value: "Others",
  },
];
