import { format } from "date-fns/esm";
import { FormikProps } from "formik";
import React from "react";
import Button from "react-bootstrap/esm/Button";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import { IEmbryoTransferUltrasoundScan } from "../../app/models/embryoTransfers";


interface IProps {
  formikProps: FormikProps<any>;
}

const EmbryoTransferUltrasoundScan: React.FC<IProps> = ({ formikProps }) => {
  const { ultrasoundScans } = formikProps.values;

  const editableColumns = [
   
    {
      title: "Date",
      field: "date",
      editor: DateEditor,
      
      editorParams: { format: "YYYY-MM-dd" },
      headerSort: false,
    },
    {
      title: "No. of Sacs",
      field: "numberOfSacs",
      editor: "number",
     
      headerSort: false,
      cssClass: "text-center",
    },
     {
      title: "CRL",
      field: "crl",
      editor: "input",
     
      headerSort: false,
      cssClass: "text-center",
    },
     {
          field: "fh",
          title: "FH: Y/N",
          headerSort: false,
          editor: "select",
          
          editorParams: {
            Yes: "Yes",
            No: "No",
          },
          cssClass: "text-center",
        },
  ];

  const handleAddRow = () => {
    const data = [...ultrasoundScans];
    const newRow: IEmbryoTransferUltrasoundScan = {
      id: 0,
      date: format(new Date(), "yyyy-MM-dd"),
      numberOfSacs: 0,
      crl: "",
      fh: true,
    };
    data.push(newRow);
    formikProps.setFieldValue("ultrasoundScans", data);
  };

  
  const handleRemoveRow = () => {
    const data = [...ultrasoundScans];
    data.pop();
    formikProps.setFieldValue("ultrasoundScans", data);
  };
  return (
    <>
      
      <div className="mb-3">
        <Button className="mr-2" onClick={handleAddRow} >
          Add
        </Button>
        <Button
        onClick={handleRemoveRow} disabled={ultrasoundScans.length === 0}
        >
          Remove
        </Button>
      </div>
      <ReactTabulator
        className="table-sm mb-3 table-bordered"
        columns={editableColumns}
        data={formikProps.values.ultrasoundScans}
      />
    </>
  );
}


export default EmbryoTransferUltrasoundScan;
