import React from "react";

const RequiredIcon = () => {
 return (
  <span
   style={{
    fontSize: "14px",
    color: "red",
    marginLeft: "6px",
   }}
  >
   *
  </span>
 );
};

export default RequiredIcon;
