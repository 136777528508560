import { RouteProps } from "react-router-dom";
import FormEmbryoFreezingReport from "../../components/EmbryoFreezingReport/FormEmbryoFreezingReport";
import FormEmbryologySummaryReport from "../../components/EmbryologySummaryReport/FormEmbryologySummaryReport";
import FormSpermCryopreservationReport from "../../components/SpermCryopreservationReport/FormSpermCryopreservationReport";

const reportRoutes: RouteProps[] = [
  {
    path: "/reports/embryo-freezing-report/edit/:id",
    component: FormEmbryoFreezingReport,
  },
  {
    path: "/reports/sperm-cryopreservation/edit/:id",
    component: FormSpermCryopreservationReport,
  },
  {
    path: "/reports/embryology-summary-report/edit/:id",
    component: FormEmbryologySummaryReport,
  },
];

export default reportRoutes;
