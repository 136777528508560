import { OptionHTMLAttributes } from "react";

export const finalOutcomeOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "Not Pregnant",
    value: "notpregnant",
  },
  {
    label: "Pregnant",
    value: "pregnant",
  },
  {
    label: "Biochemical Pregnancy",
    value: "biochemicalPregnancy",
  },
  {
    label: "Blighted Ovum",
    value: "blightedOvum",
  },
  {
    label: "Missed Abortion",
    value: "missedAbortion ",
  },
  {
    label: "Ectopic",
    value: "extopic",
  },
  {
    label: "TOP",
    value: "top",
  },
];