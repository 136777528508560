import React, { useContext, useEffect } from "react";
import Table from "react-bootstrap/esm/Table";
import Row from "react-bootstrap/esm/Row";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import { RouteComponentProps } from "react-router-dom";
import FormSpinner from "../../app/common/forms/FormSpinner";
import { useState } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import moment from "moment";

interface IParams {
  id: string;
}

const FormSpermCryopreservationReport: React.FC<
  RouteComponentProps<IParams>
> = ({
  match: {
    params: { id },
  },
}) => {
  const { Body } = Card;
  const [isLoading, setIsLoading] = useState(false);
  const [reported, setReported] = useState("");

  const {
    spermFreezingStore: { getSpermFreezingById, selectedSpermFreezing },
  } = useContext(RootStoreContext);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getSpermFreezingById(+id).finally(() => setIsLoading(false));
    }
  }, [getSpermFreezingById, id]);

  return (
    <Col className="col-lg-14 my-5 reportFont">
      <Card>
        <Container className="text-center ">
          <h1 className=" font-weight-bold reportTextTitle">
            In-Vitro Fertilization-Davao, Inc
          </h1>
          <article className="reportTextTitle reportFontSize">
            Ground Floor, Brokenshire WomanCenter Building
            <br />
            Brokenshire Hospital, Madapo Hills, Davao City
            <br />
            Tel No. +63 (082) 298-0311 Cell No. 0917-712-3327 / 0946-368-8104
            <br />
            Email Add: ivfdavaoinc@gmail.com
            <br />
            <br />
          </article>
        </Container>
        <Body>
          <h3 className="text-center mb-5 reportTextTitle mt-4">
            SPERM CRYOPRESERVATION REPORT
          </h3>
          {isLoading ? (
            <FormSpinner />
          ) : (
            <div className="reportFontSize">
              <Row className=" offset-lg-1 mt-1">
                <Col md={{ span: 8, offset: 7 }}>
                  <text className="pr-3 ">Storage Code:</text>
                  <text>
                    <b>{selectedSpermFreezing?.storageCode}</b>
                  </text>
                </Col>
              </Row>
              <Row className="offset-lg-1 mt-1">
                <Col md={{ span: 8, offset: 7 }}>
                  <text className="pr-3 ">Storage Date</text>
                  <text>
                    Date
                    {moment(selectedSpermFreezing?.storageDate).format(
                      "DD MMM YYYY"
                    )}
                  </text>
                </Col>
              </Row>

              <Row className=" offset-lg-1 mt-4">
                <Col sm={8} md={7}>
                  <text className="pr-3 ">Patient's Name:</text>
                  <text>
                    <b>
                      {`${selectedSpermFreezing?.patient?.lastName}, ${selectedSpermFreezing?.patient?.firstName}`}
                    </b>
                  </text>
                </Col>
                <Col sm={8} md={1}>
                  <text>Age: </text>
                  <text>{selectedSpermFreezing?.patient?.age}</text>
                </Col>
              </Row>
              <Row className=" offset-lg-1 mt-1">
                <Col sm={8} md={7}>
                  <text className="pr-3 ">Partner's Name:</text>
                  <text>
                    <b>
                      {`${selectedSpermFreezing?.partner?.lastName}, ${selectedSpermFreezing?.partner?.firstName}`}
                    </b>
                  </text>
                </Col>
                <Col sm={8} md={1}>
                  <text>Age: </text>
                  <text>{selectedSpermFreezing?.partner?.age}</text>
                </Col>
              </Row>
              <Row className=" offset-lg-1 mt-1">
                <Col sm={12} md={7}>
                  <text className="pr-3 ">Method: </text>
                  <text>{selectedSpermFreezing?.method} </text>
                </Col>
                <Col sm={12} md={4}>
                  <text>Abstinence </text>
                  <text>{`${selectedSpermFreezing?.abstinence} Days`}</text>
                </Col>
              </Row>
              <Row className=" offset-lg-1 mt-1">
                <Col sm={12} md={7}>
                  <text className="pr-3 ">Purpose of Storage: </text>
                  <text>{selectedSpermFreezing?.purposeOfStorage}</text>
                </Col>
                <Col sm={12} md={4}>
                  <text>Physician: </text>
                  <text>{`${selectedSpermFreezing?.physician?.lastName}, ${selectedSpermFreezing?.physician?.firstName} ${selectedSpermFreezing?.physician?.middleName}`}</text>
                </Col>
              </Row>
              <Row className="offset-lg-1 mt-1">
                <Col sm={12} md={10}>
                  <h5 className="font-weight-bold mt-4 ">SEMEN ANALYSIS</h5>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Appearance:</td>
                        <td>{selectedSpermFreezing?.appearance}</td>
                      </tr>
                      <tr>
                        <td>Volume:</td>
                        <td>{`${selectedSpermFreezing?.volume} ml`}</td>
                      </tr>
                      <tr>
                        <td>Density:</td>
                        <td>{selectedSpermFreezing?.density}</td>
                      </tr>
                      <tr>
                        <td>Total Motility (Progressive + Non-Progressive):</td>
                        <td>77 (23 + 54)</td>
                      </tr>
                      <tr>
                        <td>Morpohology (%):</td>
                        <td>{selectedSpermFreezing?.morphology}</td>
                      </tr>
                      <tr>
                        <td>Round Cell:</td>
                        <td>{selectedSpermFreezing?.roundCell}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="offset-lg-1 mt-1">
                <Col sm={12} md={10}>
                  <h5 className="font-weight-bold mt-4 ">Pre- Freeze Report</h5>

                  <article>
                    Total Motility: {selectedSpermFreezing?.totalMotility}
                  </article>
                  <article className=" mt-1 ">
                    No. of Cryotubes: &nbsp; &nbsp; &nbsp;{" "}
                    {selectedSpermFreezing?.numberOfCryotubes} &nbsp; &nbsp;
                    &nbsp; Volume: &nbsp; &nbsp; &nbsp;{" "}
                    {selectedSpermFreezing?.preFreezeVolume} &nbsp; &nbsp;
                    &nbsp; Dewar Location: &nbsp; &nbsp; &nbsp;
                    {selectedSpermFreezing?.dewarLocation} &nbsp;
                  </article>
                  <article className=" mt-1 offset-lg-2">
                    Placed under "Storage Code", under the same patient name
                  </article>
                  <article className=" mt-4 ">Lab Use only</article>
                  <article className=" mt-4 ">Storage Fee Payment</article>
                  <Container className=" mt-1">
                    <Col sm={12} md={10}>
                      <Table>
                        <tbody>
                          <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            {/* <th>Method</th> */}
                            <th>Next Due</th>
                            <th>Remarks</th>
                          </tr>
                          {selectedSpermFreezing?.storagePayments.map(
                            (item) => {
                              return (
                                <tr key={item.id}>
                                  <td>
                                    {moment(item.date).format("DD MMM YYYY")}
                                  </td>
                                  <td>{`P ${item.amount}`}</td>
                                  {/* <td>
                                    {method}
                                    <input
                                      placeholder="Method"
                                      type="text"
                                      className="form-control hide-on-print"
                                      value={method}
                                      onChange={(e) =>
                                        setMethod(e.target.value)
                                      }
                                    />
                                  </td> */}
                                  <td>
                                    {moment(item.nextDate).format(
                                      "DD MMM YYYY"
                                    )}
                                  </td>
                                  <td>{item.remarks}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Container>
                </Col>
              </Row>

              <Row className=" offset-lg-1 mt-1">
                <Col sm={8} md={7}>
                  <text className="pr-3 ">Reported By: </text>
                  <text>{reported}</text>
                  <input
                    placeholder="Reported By"
                    type="text"
                    className="form-control hide-on-print"
                    value={reported}
                    onChange={(e) => setReported(e.target.value)}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Body>
      </Card>
    </Col>
  );
};

export default FormSpermCryopreservationReport;
