import React, { useContext, useState, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextInput from "../../app/common/forms/TextInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import {
  ISpermFreezing,
  SpermFreezingFormValues,
} from "../../app/models/spermFreezing";
import { RouteComponentProps } from "react-router-dom";
import FormSpinner from "../../app/common/forms/FormSpinner";
import PatientSelect from "../../app/common/forms/PatientSelect";
import { IPatientOption } from "../../app/models/patient";
import StoragePaymentTable from "./StoragePaymentTable";
import SelectInput from "../../app/common/forms/SelectInput";
import { methodOptions } from "../../app/common/options/methodOptions";
import TransactionCodeInput from "../../app/common/forms/TransactionCodeInput";
import DoctorSelect from "../../app/common/forms/DoctorSelect";
import { purposeOfStorageOptions } from "../../app/common/options/purposeOfStorageOptions";
import Container from "react-bootstrap/esm/Container";
import BackHomeButton from "../../app/common/button/BackHomeButton";

interface IParams {
  id: string;
}

const validationSchema = Yup.object<ISpermFreezing>().shape({
  storageCode: Yup.string().required("Storage Code is required"),
  storageCodeYear: Yup.number(),
  storageDate: Yup.date().required("Storage Date is required"),
  patientId: Yup.number().required("Patient Id is required"),
  partnerId: Yup.number().required("Partner Id is required"),
  method: Yup.string().required("Method is required"),
  abstinence: Yup.number().required("Abstinence is required"),
  purposeOfStorage: Yup.string().required("Purpose of Storage is required"),
  others: Yup.string(),
  physicianId: Yup.number().required("Physician is required"),
  appearance: Yup.string().required("Appearance is required"),
  volume: Yup.string().required("Volume is required"),
  density: Yup.string().required("Density is required"),
  totalMotility: Yup.string().required("Total Motility is required"),
  morphology: Yup.string().required("Morphology is required"),
  roundCell: Yup.string().required("Round Cell is required"),
  numberOfCryotubes: Yup.number().required("Number of Cryotubes is required"),
  preFreezeVolume: Yup.string().required("Pre-freeze Volume is required"),
  dewarLocation: Yup.number().required("Dewar location is required"),
  storagePayments: Yup.array().required("Payment is required"),
});

const FormSpermFreezing: React.FC<RouteComponentProps<IParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createSpermFreezing,
    getSpermFreezingById,
    updateSpermFreezing,
  } = rootStore.spermFreezingStore;
  const [spermFreezing, setSpermFreezing] = useState(
    new SpermFreezingFormValues()
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getSpermFreezingById(+id)
        .then((data) => {
          setSpermFreezing(new SpermFreezingFormValues(data));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <BackHomeButton/>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-5 text-primary">Sperm Freezing</h2>
          {isLoading ? (
            <FormSpinner />
          ) : (
            <Formik
              validationSchema={validationSchema}
              onSubmit={(values: ISpermFreezing) => {
                if (id) {
                  updateSpermFreezing(values);
                } else {
                  createSpermFreezing(values);
                }
              }}
              initialValues={spermFreezing}
              enableReinitialize={true}
            >
              {(formikProps) => {
                const setPatientAge = (selectedOption: IPatientOption) => {
                  formikProps.setFieldValue("patientAge", selectedOption.age);
                };

                const setPartnerAge = (selectedOption: IPatientOption) => {
                  formikProps.setFieldValue("partnerAge", selectedOption.age);
                };

                return (
                  <Form>
                    <Row>
                      <Col>
                        <TransactionCodeInput
                          name="storageCode"
                          label="Storage Code"
                          className="text-uppercase"
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="storageDate"
                          label="Storage Date"
                          type="date"
                          formikProps={formikProps}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={9}>
                        <PatientSelect
                          name="patientId"
                          label="Patient Name"
                          placeholder="Select patient..."
                          formikProps={formikProps}
                          setOnChange={setPatientAge}
                        />
                      </Col>
                      <Col sm={12} md={3}>
                        <TextInput
                          name="patientAge"
                          label="Patient Age"
                          type="number"
                          formikProps={formikProps}
                          readOnly={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={9}>
                        <PatientSelect
                          name="partnerId"
                          label="Partner's Name"
                          placeholder="Select partner..."
                          formikProps={formikProps}
                          setOnChange={setPartnerAge}
                        />
                      </Col>
                      <Col sm={12} md={3}>
                        <TextInput
                          name="partnerAge"
                          label="Age"
                          type="number"
                          formikProps={formikProps}
                          readOnly={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={9}>
                        <SelectInput
                          name="method"
                          label="Method"
                          options={methodOptions}
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col sm={12} md={3}>
                        <TextInput
                          name="abstinence"
                          label="Abstinence"
                          placeholder="(Days)"
                          type="number"
                          formikProps={formikProps}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SelectInput
                          name="purposeOfStorage"
                          label="Purpose of Storage"
                          options={purposeOfStorageOptions}
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="others"
                          label="Others"
                          type="text"
                          className="text-uppercase"
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col>
                        <DoctorSelect
                          name="physicianId"
                          label="Physician"
                          placeholder="Select Doctor..."
                          formikProps={formikProps}
                        />
                      </Col>
                    </Row>
                    <h4 className="mb-3 text-primary">Semen Analysis</h4>
                    <Row>
                      <Col>
                        <TextInput
                          name="appearance"
                          label="Appearance"
                          type="text"
                          className="text-uppercase"
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="volume"
                          label="Volume"
                          type="number"
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="density"
                          label="Density"
                          type="number"
                          formikProps={formikProps}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          name="totalMotility"
                          label="Total Motility"
                          type="number"
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="morphology"
                          label="Morphology"
                          type="text"
                          className="text-uppercase"
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="roundCell"
                          label="Round Cell"
                          type="text"
                          className="text-uppercase"
                          formikProps={formikProps}
                        />
                      </Col>
                    </Row>
                    <h4 className="mb-3 text-primary">Pre-Freeze Report</h4>
                    <Row>
                      <Col>
                        <TextInput
                          name="numberOfCryotubes"
                          label="Number of Cryotubes"
                          type="number"
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="preFreezeVolume"
                          label="Pre-Freeze Volume"
                          type="number"
                          formikProps={formikProps}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="dewarLocation"
                          label="Dewar Location"
                          type="number"
                          formikProps={formikProps}
                        />
                      </Col>
                    </Row>

                    <StoragePaymentTable formikProps={formikProps} />

                    <Button
                      className="float-right"
                      type="submit"
                      size="lg"
                      variant="primary"
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    >
                      Submit
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FormSpermFreezing;
