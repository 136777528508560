import React, { useContext } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextInput from "../../app/common/forms/TextInput";
import Spinner from "react-bootstrap/esm/Spinner";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import Alert from "react-bootstrap/esm/Alert";
import { IUpdatePasswordForm } from "../../app/models/auth";

const validationSchema = Yup.object<IUpdatePasswordForm>().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .min(6)
    .matches(new RegExp("[a-z]"), "password must contain a lowercase letter")
    .matches(new RegExp("[A-Z]"), "password must contain an uppercase letter")
    .matches(new RegExp("[0-9]"), "password must contain a number")
    .matches(
      new RegExp("[^a-zA-Z0-9]"),
      "password must contain a non-alphanumeric character"
    )
    .required("New password is required"),
});

const FormUpdatePassword = () => {
  const rootStore = useContext(RootStoreContext);
  const { updatePassword } = rootStore.userStore;

  return (
    <Col className="col-lg-4 offset-lg-4 my-5">
      <Card>
        <Card.Body>
          <h2 className="text-center mb-5">Update Password</h2>
          <Formik
            validationSchema={validationSchema}
            onSubmit={(
              values: IUpdatePasswordForm,
              { setErrors, setSubmitting }
            ) => {
              updatePassword(values).catch((error) => {
                if (error) {
                  setErrors({
                    submitError: error.data.message,
                  });
                }
                setSubmitting(false);
              });
            }}
            initialValues={{
              oldPassword: "",
              newPassword: "",
            }}
          >
            {(formikProps) => (
              <Form>
                {formikProps.errors.submitError !== undefined ? (
                  <Alert variant="danger">
                    {formikProps.errors.submitError}
                  </Alert>
                ) : null}
                <TextInput
                  name="oldPassword"
                  label="Current Password"
                  type="password"
                  formikProps={formikProps}
                />
                <TextInput
                  name="newPassword"
                  label="New Password"
                  type="password"
                  formikProps={formikProps}
                />
                <Button
                  variant="primary"
                  type="submit"
                  block
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                >
                  {formikProps.isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-2"
                    />
                  )}
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default observer(FormUpdatePassword);
