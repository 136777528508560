import React from "react";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";

const FormThawedCycleReport = () => {
  return (
    <Col className="col-lg-14 my-5">
      <Card>
        <Card.Body>
          <Container className="text-center ">
            <h1 className=" font-weight-bold text-primary">
              In-Vitro Fertilization-Davao, Inc
            </h1>
            <article className="text-primary">
              Ground Floor, Brokenshire WomanCenter Building
              <br />
              Brokenshire Hospital, Madapo Hills, Davao City
              <br />
              Tel No. +63 (082) 298-0311 Cell No. 0927-2019568
              <br />
              Email Add: ivfdavaoinc@gmail.com
              <br />
              <br />
            </article>
          </Container>
          <h2 className="text-center mb-5 text-primary mt-2">
            Thawed Cycle Report
          </h2>

          <Row>
            <Col>
              <article className="offset-lg-2 mt-1">
                {" "}
                1) Natural Cycle / Artificial Cycle
              </article>
              <text className="offset-lg-2 mt-1">(Low Dose)</text>
              <article className="mt-5 offset-lg-2">
                {" "}
                Date of E.T: "20 August 2019"{" "}
              </article>
              <article className="offset-lg-2 mt-1">
                {" "}
                E<sub>2</sub> level on day of HCG: "No.
              </article>
            </Col>
            <Col>
              <Card>
                <article className="offset-lg-1">
                  {" "}
                  Patient's Particulars
                </article>
                <br />
                <article className="offset-lg-3">"patient particulars"</article>
                <article className="offset-lg-4"> 2nd FET </article>
              </Card>
              <Card>
                <article className="offset-lg-1">TW No.: 53-2019</article>
                <article className="offset-lg-1">
                  Lab.Code Storage No.: EF 84-2019
                </article>
              </Card>
            </Col>
          </Row>
          <Card className="mt-3 offset-lg-1">
            <Row>
              <Col>
                <h5>Clinical part</h5>
                <Col>
                  <text className="mt-1"> {"1) Ease of E.T"} </text>
                  <text className="offset-lg-2"> : Easy / Difficult</text>
                </Col>
                <Col>
                  <text className="mt-1"> {"2) Catheter Used"} </text>

                  <text className="offset-lg-1 pl-3">
                    {" "}
                    : Wallace Surepro with STYLET{" "}
                  </text>
                </Col>
                <Col>
                  <text className="mt-1"> {"3) Other remarks"} </text>
                  <text className="offset-lg-1 pl-3"> : Smooth UD-ET</text>
                </Col>
                <Col>
                  <text className="mt-1"> {"4) E.T by Doctor"} : </text>
                  <text className="offset-lg-1 pl-3">: Irene Uy</text>
                </Col>
              </Col>
              <Col>
                <h5> Post thaw</h5>
                <image> image 1</image>
              </Col>
            </Row>
          </Card>
          <h5 className="mt-3 offset-lg-1"> Laboratory part</h5>
          <Row>
            <Col className="mt-1 offset-lg-1">
              <Col>
                <text className="mt-1"> {"1) Type of Thawing : "}</text>
                <text> Day 3 to Day 5</text>
              </Col>
              <Col>
                <text className="mt-1"> {"2) Embryos From: "}</text>
                <text> IVF / ICSI </text>
              </Col>
              <Col>
                <text className="mt-1">
                  {" "}
                  {"3) No. of embryos Available :"}{" "}
                </text>
                <text> 3 </text>
              </Col>
              <Col>
                <text className="mt-1"> {"4) No. of embryos thawed :"} </text>
                <text> 3</text>
              </Col>
              <Col>
                <text className="mt-1">
                  {"5) Number of Remaining Embryos :"}{" "}
                </text>
                <text> 0</text>
              </Col>
            </Col>
            <Col>
              <image>Image 2</image>
            </Col>
          </Row>
          <Row>
            <Col className="offset-lg-1">
              <Col>
                <text className="mt-1">
                  {" "}
                  {"6) No. of embryos survived (>50%) :"}{" "}
                </text>
                <text> 2</text>
              </Col>
              <Col>
                <text className="mt-1"> {"7) No. of embryos cleaved:"} </text>
                <text> IVF / ICSI </text>
              </Col>
              <Col>
                <text className="mt-1">
                  {" "}
                  {"8) No. of embryos transferred :"}{" "}
                </text>
                <text> 3 </text>
              </Col>
              <Col>
                <text className="mt-1">
                  {" "}
                  {"9) No. of non-usable thawed embryos :"}{" "}
                </text>
                <text> 3</text>
              </Col>
              <Col>
                <text className="mt-1">
                  {" "}
                  {"10) Reported by Embryologist :"}{" "}
                </text>
                <text> Kristine Joy Dela Fuente</text>
              </Col>
            </Col>
            <Col>
              <image>Image 3</image>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default FormThawedCycleReport;
