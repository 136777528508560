import { OptionHTMLAttributes } from "react";

export const methodOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "Masturbation",
    value: "Masturbation",
  },
  {
    label: "TESA/TESE",
    value: "TESA/TESE",
  },
];
