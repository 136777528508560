import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomTable from "../../app/common/table/CustomTable";
import { IEFTLData } from "../../app/models/eftl";
import { RootStoreContext } from "../../app/stores/rootStore";

interface IProps {
  printButton?: boolean;
}

const EFTLsList: React.FC<IProps> = ({ printButton = false }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    getEFTLs,
    eftlsList,
    total,
    pageSize,
    pageIndex,
    keywords,
  } = rootStore.eftlStore;

  useEffect(() => {
    getEFTLs();
  }, [getEFTLs]);

  const columns: IDataTableColumn[] = [
    {
      name: "EF Number",
      selector: "efNumber",
    },
    {
      name: "OPU Date",
      selector: "opuDate",
      format: (row) => format(new Date(row.opuDate), "yyyy-MM-dd"),
    },
    {
      name: "Protocol",
      selector: "protocol",
    },
    {
      name: "",
      button: true,
      cell: (row: IEFTLData) => (
        <>
          {!printButton ? (
            <Button
              as={Link}
              to={`/embryo-freezing-and-thawing/edit/${row.id}`}
              variant="outline-primary"
            >
              Edit
            </Button>
          ) : (
            <Button
              as={Link}
              to={`/reports/embryo-freezing-report/edit/${row.id}`}
              variant="outline-primary"
            >
              Print
            </Button>
          )}
        </>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    getEFTLs(newPage, pageSize, keywords);
  };

  const handlePerRowsChange = (newPageSize: number, page: number) => {
    getEFTLs(page, newPageSize, keywords);
  };

  const handleSearch = (keywords: string) => {
    getEFTLs(pageIndex, pageSize, keywords);
  };

  return (
    <Container>
      <CustomTable
        title="EFTL"
        columns={columns}
        data={eftlsList}
        paginationTotalRows={total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSearch={handleSearch}
        rightComponent={
          <>
            {!printButton ? (
              <Button as={Link} to="/embryo-freezing-and-thawing/add">
                Add EFTL
              </Button>
            ) : null}
          </>
        }
      />
    </Container>
  );
};

export default observer(EFTLsList);
