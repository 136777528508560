import React, { useContext, useState, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextInput from "../../app/common/forms/TextInput";
import { RouteComponentProps } from "react-router-dom";
import SelectInput from "../../app/common/forms/SelectInput";
import Row from "react-bootstrap/esm/Row";
import Spinner from "react-bootstrap/esm/Spinner";
import { boolOptions } from "../../app/common/options/boolOptions";
import { preparationProcedureOptions } from "../../app/common/options/preparationProcedureOptions";
import { spermSourceOptions } from "../../app/common/options/spermSourceOptions";
import { IIVF, IVFFormValues } from "../../app/models/ivf";
import { RootStoreContext } from "../../app/stores/rootStore";
import FormSpinner from "../../app/common/forms/FormSpinner";
import { sourceOptions } from "../../app/common/options/sourceOptions";
import PatientSelect from "../../app/common/forms/PatientSelect";
import DoctorSelect from "../../app/common/forms/DoctorSelect";
import { noOfAttemptsOptions } from "../../app/common/options/noOfAttemptsOption";
import TransactionCodeInput from "../../app/common/forms/TransactionCodeInput";
import { progressionOptions } from "../../app/common/options/ivfProgressionOptions";
import convertTime from "../../app/common/util";
import Container from "react-bootstrap/esm/Container";
import BackHomeButton from "../../app/common/button/BackHomeButton";

interface IParams {
 id: string;
}

const validationSchema = Yup.object<IIVF>().shape({
 artNumber: Yup.string().required("ART Number is required"),
 follicle: Yup.string().required("Follicle is required"),
 wifeId: Yup.number()
  .required("Wife is required")
  .notOneOf([0], "Wife ID cannot be zero"),
 husbandId: Yup.number()
  .required("Husband is required")
  .notOneOf([0], "Husband ID cannot be zero"),
 doctorId: Yup.number()
  .required("Doctor is required")
  .notOneOf([0], "Doctor ID cannot be zero"),
 pxDataCheckedBy: Yup.string().required(
  "Px Data Checked By is required"
 ),
 hcg: Yup.string().required("HCG is required"),
 gravida: Yup.string().required("Gravida is required"),
 para: Yup.string().required("Para is required"),
 // previousIVF: Yup.string().required("Previous IVF is required"),
 prevVolume: Yup.string().required("Previous Volume is required"),
 prevDensity: Yup.string().required("Previous Density is required"),
 prevMotility: Yup.string().required("Previous Motility is required"),
 prevMorphology: Yup.string().required(
  "Previous Morphology is required"
 ),
 volume: Yup.string().required("Volume is required"),
 liquefaction: Yup.string().required("Liquefaction is required"),
 density: Yup.string().required("Density is required"),
 motility: Yup.string().required("Motility is required"),
 roundCell: Yup.string().required("Round Cell is required"),
 morphology: Yup.string().required("Morphology is required"),
 agglutination: Yup.string().required("Agglutinaion is required"),
 debris: Yup.string().required("Debris is required"),
 finalVolume: Yup.string().required("Final Volume is required"),
 finalDensity: Yup.string().required("Final Density is required"),
 finalMotility: Yup.string().required("Final Motility is required"),
 doneBy: Yup.string().required("Done By is required"),
 finalPreparationRemarks: Yup.string().required(
  "Final Preparation Remarks is required"
 ),
});

const FormIvf: React.FC<RouteComponentProps<IParams>> = ({
 match: {
  params: { id },
 },
}) => {
 const rootStore = useContext(RootStoreContext);
 const { createIvf, getIVFById, updateIVF } = rootStore.ivfStore;
 const [ivf, setIVF] = useState(new IVFFormValues());
 const [isLoading, setIsLoading] = useState(false);

 useEffect(() => {
  if (id) {
   setIsLoading(true);
   getIVFById(+id)
    .then((ivf) => {
     setIVF(new IVFFormValues(ivf));
    })
    .finally(() => {
     setIsLoading(false);
    });
  }
 }, [getIVFById, id]);

 return (
  <Container>
   <BackHomeButton />
   <Card>
    <Card.Body>
     <h2 className="text-center mb-5 text-primary">
      {id ? "Edit" : "New"}
     </h2>
     {isLoading ? (
      <FormSpinner />
     ) : (
      <Formik
       validationSchema={validationSchema}
       onSubmit={(values: IIVF) => {
        values.isScreeningTestDone = Boolean(
         values.isScreeningTestDone
        );
        values.artNumberYear = +values.artNumberYear;
        values.attempt = +values.attempt;
        values.opuDishPreparationTime = convertTime(
         values.opuDishPreparationTime
        );
        values.timeReceived = convertTime(values.timeReceived);
        values.timeExamined = convertTime(values.timeExamined);

        if (id) {
         updateIVF(values);
        } else {
         createIvf(values);
        }
       }}
       initialValues={ivf}
       enableReinitialize={true}
      >
       {(formikProps) => (
        <Form>
         <h3 className="text-center mb-3 text-primary">
          IN VITRO FERTILIZATION
         </h3>
         <Row className="mt-4">
          <Col>
           <TransactionCodeInput
            name="artNumber"
            label="ART No."
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon={true}
           />
          </Col>
          <Col>
           <TextInput
            name="procedureDate"
            label="Procedure Date"
            type="date"
            formikProps={formikProps}
           />
          </Col>
          <Col>
           <SelectInput
            name="attempt"
            label="No. of Attempts"
            options={noOfAttemptsOptions}
            formikProps={formikProps}
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="stimulationRegimen"
            label="Stimulation Regimen"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
           />
          </Col>
          <Col>
           <DoctorSelect
            name="doctorId"
            label="Doctor"
            placeholder="Select Doctor..."
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col>
           <TextInput
            name="follicle"
            label="Follicle"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon={true}
           />
          </Col>
         </Row>

         <h3 className=" mb-3 text-primary mt-3 text-center">
          Patient Data
         </h3>

         <Row className="mt-4">
          <Col>
           <PatientSelect
            name="wifeId"
            label="Wife"
            patientType="Wife"
            placeholder="Select Wife..."
            formikProps={formikProps}
            showRequiredIcon={true}
           />
          </Col>
          <Col>
           <TextInput
            name="wifeDiagnosis"
            label="Wife Diagnosis"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
           />
          </Col>
          <Col>
           <SelectInput
            name="oocyte"
            label="Oocyte"
            options={sourceOptions}
            formikProps={formikProps}
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="wifeRemarks"
            label="Wife Remarks"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
           />
          </Col>
          <Col>
           <TextInput
            name="wifeDonorName"
            label="Wife Donor Name"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <PatientSelect
            name="husbandId"
            label="Husband"
            patientType="Husband"
            placeholder="Select Husband..."
            formikProps={formikProps}
            showRequiredIcon={true}
           />
          </Col>
          <Col>
           <TextInput
            name="husbandDiagnosis"
            label="Husband Diagnosis"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
           />
          </Col>

          <Col>
           <SelectInput
            name="sperm"
            label="Sperm"
            options={sourceOptions}
            formikProps={formikProps}
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="husbandRemarks"
            label="Husband Remarks"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
           />
          </Col>
          <Col>
           <TextInput
            name="husbandDonorName"
            label="Husband Donor Name"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
           />
          </Col>
         </Row>
         <br />
         <Row>
          <Col>
           <TextInput
            name="pxDataCheckedBy"
            label="PX Data Checked By"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon={true}
           />
          </Col>
          <Col>
           <TextInput
            name="hcg"
            label="HCG"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon={true}
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="gravida"
            label="Gravida"
            type="number"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col>
           <TextInput
            name="para"
            label="Para"
            type="number"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col>
           <TextInput
            name="previousIVF"
            label="Previous IVF"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
           />
          </Col>

          <Col>
           <SelectInput
            name="isScreeningTestDone"
            label="Is Screening Test Done"
            options={boolOptions}
            formikProps={formikProps}
           />
          </Col>
         </Row>
         <Row>
          <Col md={6}>
           <TextInput
            name="opuDishPreparationTime"
            label="OPU Dish Preparation Time"
            type="time"
            formikProps={formikProps}
           />
          </Col>
         </Row>

         <h3 className=" text-primary text-center mt-3 mb-3 ">
          Sperm Quality Preparation
         </h3>
         <Row>
          <Col>
           <h5 className=" mt-3 mb-3 ">Previous Analysis</h5>
          </Col>
          <Col>
           <TextInput
            name="spermPreparationDate"
            label="Date"
            type="date"
            formikProps={formikProps}
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="prevVolume"
            label="Previous Volume"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col>
           <TextInput
            name="prevDensity"
            label="Previous Density"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>

          <Col>
           <TextInput
            name="prevMotility"
            label="Previous Motility"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col>
           <TextInput
            name="prevMorphology"
            label="Previous Morphology"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="timeReceived"
            label="Time Received"
            type="time"
            formikProps={formikProps}
           />
          </Col>
          <Col>
           <TextInput
            name="daysOfAbstinence"
            label="Days of Abstinence"
            type="number"
            formikProps={formikProps}
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="timeExamined"
            label="Timed Examined"
            type="time"
            formikProps={formikProps}
           />
          </Col>
          <Col>
           <SelectInput
            name="preparationProcedure"
            label="Preparation Procedure"
            options={preparationProcedureOptions}
            formikProps={formikProps}
           />
          </Col>
         </Row>
         {formikProps.values["preparationProcedure"] === "Others" ? (
          <Row>
           <Col>
            <TextInput
             name="otherProcedure"
             label="Other Procedure"
             type="text"
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
          </Row>
         ) : null}
         <br />
         <Row>
          <Col md={6}>
           <SelectInput
            name="spermSource"
            label="Sperm Source"
            options={spermSourceOptions}
            formikProps={formikProps}
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="volume"
            label="Volume"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col>
           <h5 className=" mt-4 mb-3 ">Final Preparation</h5>
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="liquefaction"
            label="Liquefaction"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col>
           <TextInput
            name="finalVolume"
            label="Final Volume"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
         </Row>
         <Row>
          <Col sm={12} md={6}>
           <TextInput
            name="density"
            label="Density"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col sm={12} md={6}>
           <TextInput
            name="finalDensity"
            label="Final Density"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col></Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="motility"
            label="Motility"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col>
           <TextInput
            name="finalMotility"
            label="Final Motility"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
         </Row>

         <Row>
          <Col>
           <SelectInput
            name="progression"
            label="Progression"
            options={progressionOptions}
            formikProps={formikProps}
           />
          </Col>
          <Col>
           <TextInput
            name="doneBy"
            label="Done By"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
         </Row>

         <Row>
          <Col sm={12} md={6}>
           <TextInput
            name="roundCell"
            label="Round Cell"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>

          <Col sm={12} md={6}>
           <TextInput
            name="finalPreparationRemarks"
            label="Final Preparation Remarks"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
         </Row>
         <Row>
          <Col sm={12} md={6}>
           <TextInput
            name="morphology"
            label="Morphology"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
         </Row>
         <Row>
          <Col sm={12} md={3}>
           <TextInput
            name="agglutination"
            label="Agglutination"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
          <Col sm={12} md={3}>
           <TextInput
            name="debris"
            label="Debris"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
            showRequiredIcon
           />
          </Col>
         </Row>
         <Row>
          <Col>
           <TextInput
            name="ivfRemarks"
            label="IVF Remarks"
            type="text"
            className="text-uppercase"
            formikProps={formikProps}
           />
          </Col>
         </Row>

         <Button
          className="float-right"
          type="submit"
          size="lg"
          variant="primary"
          disabled={formikProps.isSubmitting || !formikProps.isValid}
         >
          {formikProps.isSubmitting && (
           <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
           />
          )}
          Submit
         </Button>
        </Form>
       )}
      </Formik>
     )}
    </Card.Body>
   </Card>
  </Container>
 );
};

export default FormIvf;
