import React from "react";
import { AiFillHome } from "react-icons/ai";
import Button from "react-bootstrap/esm/Button";
import { history } from "../../..";

const BackHomeButton = () =>{
    return(
        <>
            <Button className="mb-3 bg-transparent text-primary" onClick={()=> history.push("/")}><AiFillHome/>Back to Home</Button>
        </>
    )
}

export default BackHomeButton;
