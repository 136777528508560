import { RouteProps } from "react-router-dom";
import Dashboard from "../../components/Dashboard/Dashboard";
import doctorRoutes from "./routesByFeature/doctorRoutes";
import embryoTransferRoutes from "./routesByFeature/embryoTransferRoutes";
import icsiRoutes from "./routesByFeature/icsiRoutes";
import ivftRoutes from "./routesByFeature/ivfRoutes";
import eftlRoutes from "./routesByFeature/eftlRoutes";
import patientRoutes from "./routesByFeature/patientRoutes";
import userRoutes from "./routesByFeature/userRoutes";
import embryoFreezingReportRoutes from "./routesByFeature/embryoFreezingReportRoutes";
import spermCryoPreservationRoutes from "./routesByFeature/spermCryoPreservationRoutes";
import embryologySummaryRoutes from "./routesByFeature/embryologySummaryRoutes";
import spermfreezingRoutes from "./routesByFeature/spermFreezingRoutes";
import thawedCycleReportRoutes from "./routesByFeature/thawedCycleReportRoutes";
import accountRoutes from "./routesByFeature/accountRoutes";

const privateRoutes: RouteProps[] = [
  ...userRoutes,
  ...doctorRoutes,
  ...patientRoutes,
  ...ivftRoutes,
  ...embryoTransferRoutes,
  ...icsiRoutes,
  ...eftlRoutes,
  ...embryoFreezingReportRoutes,
  ...spermCryoPreservationRoutes,
  ...embryologySummaryRoutes,
  ...spermfreezingRoutes,
  ...thawedCycleReportRoutes,
  ...accountRoutes,
  {
    path: "/",
    component: Dashboard,
  },
];

export default privateRoutes;
