import { OptionHTMLAttributes } from "react";

export const spermSourceOptions: OptionHTMLAttributes<
  HTMLOptionElement
>[] = [
  {
    label: "Ejaculate",
    value: "Ejaculate",
  },
  {
    label: "TESA",
    value: "TESA",
  },
  {
    label: "TESE",
    value: "TESE",
  },
  {
    label: "Frozen Sperm",
    value: "FrozenSperm",
  },
];