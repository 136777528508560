import { FormikProps } from "formik";
import React from "react";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";

interface IProps {
  formikProps: FormikProps<any>;
}

const ICSITable: React.FC<IProps> = ({ formikProps }) => {
  const { icsiDetails } = formikProps.values;
  const createInputPlaceholder = function (cell: any) {
    const cellValue = cell.getValue();
    if (cellValue === "") {
      return "Enter ICSI";
    } else {
      return cellValue;
    }
  };
  const editableColumns = [
    {
      title: "ICSI",
      field: "icsi",
      editor: "input",
      formatter: createInputPlaceholder,
      headerSort: false,
    },
  ];

  return (
    <ReactTabulator
      className="table-sm mb-3 table-bordered table-responsive"
      columns={editableColumns}
      data={icsiDetails}
    />
  );
};

export default ICSITable;
