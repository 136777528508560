import { ILoginForm, IResetPasswordForm } from "../models/auth";
import { IUserCredentials } from "../models/user";
import requests from "./httpService";

const baseApi = "/auth";

const Auth = {
  login: (user: ILoginForm): Promise<IUserCredentials> =>
    requests.post(`${baseApi}/login`, user),
  forgotPassword: (email: string) =>
    requests.post(`${baseApi}/forgot-password`, { email }),
  resetPassword: (user: IResetPasswordForm) =>
    requests.post(`${baseApi}/reset-password`, user),
};

export default Auth;
