import React from "react";
import Container from "react-bootstrap/esm/Container";
import { RouteComponentProps } from "react-router-dom";
import PrivateLayoutHeader from "./PrivateLayoutHeader";

interface IProps extends RouteComponentProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const AdminLayout: React.FC<IProps> = ({ component, ...rest }) => {
  const Component = component;

  return (
    <>
      <PrivateLayoutHeader />
      <Container fluid className="py-5">
        <Component {...rest} />
      </Container>
    </>
  );
};

export default AdminLayout;
