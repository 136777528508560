import { ISpermFreezing } from "./../models/spermFreezing";
import requests from "./httpService";
import { ISpermFreezingPagination } from "../models/spermFreezing";

const api = "/sperm-freezings";

const SpermFreezings = {
  get: (
    pageIndex: number,
    pageSize: number,
    search?: string
  ): Promise<ISpermFreezingPagination> =>
    requests.get(
      `${api}?pageIndex=${pageIndex}&pageSize=${pageSize}${
        search ? `&search=${search}` : ``
      }`
    ),
  getById: (id: number): Promise<ISpermFreezing> =>
    requests.get(`${api}/${id}`),
  create: (values: ISpermFreezing): Promise<ISpermFreezing> =>
    requests.post(`${api}`, values),
  update: (values: ISpermFreezing): Promise<ISpermFreezing> =>
    requests.put(`${api}/${values.id}`, values),
};

export default SpermFreezings;
