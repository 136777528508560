import { OptionHTMLAttributes } from "react";

export const progressionOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "Progressive",
    value: "Progressive",
  },
  {
    label: "Non-Progressive",
    value: "NonProgressive",
  },
];
