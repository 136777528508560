import { RouteProps } from "react-router-dom";
import FormUser from "../../../components/User/FormUser";
import UsersList from "../../../components/User/UsersList";

const userRoutes: RouteProps[] = [
  {
    path: "/users/add",
    component: FormUser,
  },
  {
    path: "/users",
    component: UsersList,
  },
];

export default userRoutes;
