import { OptionHTMLAttributes } from "react";

export const noOfAttemptsOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
    {
    label: "3",
    value: "3",
  },
];
