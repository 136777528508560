import format from "date-fns/format";
import { FormikProps } from "formik";
import React from "react";
import Button from "react-bootstrap/esm/Button";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import { IEFTLDetail } from "../../app/models/eftl";

interface IProps {
  formikProps: FormikProps<any>;
}

const EFTLDetails: React.FC<IProps> = ({ formikProps }) => {
  const { eftlDetails } = formikProps.values;

  const editableColumns = [
    {
      title: "",
      cssClass: "text-center",
      columns: [
        {
          field: "index",
          title: "No.",
          width: 50,
          headerSort: false,
          cssClass: "text-center",
        },
        {
          title: "Cell Stage",
          field: "cellStage",
          width: 100,
          headerSort: false,
          editor: "input",
          cssClass: "text-center",
        },
      ],
    },
    {
      title: "Embryo Morphology",
      cssClass: "text-center",
      columns: [
        {
          field: "emFrag",
          title: "Frag",
          width: 100,
          headerSort: false,
          editor: "input",
          // editorParams: {
          //   Yes: "Yes",
          //   No: "No",
          //   Slight: "Slight",
          // },
          cssClass: "text-center",
        },
        {
          field: "emDark",
          title: "Dark",
          headerSort: false,
          editor: "select",
          editorParams: {
            Yes: "Yes",
            No: "No",
            Slight: "Slight",
          },
          cssClass: "text-center",
        },
        {
          field: "emUneq",
          title: "Uneq",
          headerSort: false,
          editor: "select",
          editorParams: {
            Yes: "Yes",
            No: "No",
            Slight: "Slight",
          },
          cssClass: "text-center",
        },
        {
          field: "emgrade",
          title: "Grade",
          headerSort: false,
          editor: "input",
          cssClass: "text-center",
        },
        {
          field: "remarks",
          title: "Remarks",
          headerSort: false,
          editor: "input",
          width: 150,
          cssClass: "text-capitalize text-center",
        },
      ],
    },
    {
      title: "",
      cssClass: "text-center",
      columns: [
        
        {
          field: "strawNumber",
          title: "Straw No.",
          width: 100,
          headerSort: false,
          editor: "input",
          cssClass: "text-center",
        },
        {
          field: "color",
          title: "Color",
          width: 100,

          headerSort: false,
          editor: "input",
          cssClass: "text-capitalize text-center",
        },
      ],
    },
  ];

  const handleAddRow = () => {
    const data = [...eftlDetails];
    const newRow: IEFTLDetail = {
      index: data.length + 1,
      embryoFreezingAndThawingId: 0,
      cellStage: "",
      emFrag: "",
      emDark: "",
      emUneq: "",
      emGrade: "G1",
      remarks: "",
      strawNumber: "",
      color: "",
      thawDateTime: format(new Date(), "yyyy-MM-dd"),
      embQualityPThaw: format(new Date(), "yyyy-MM-dd"),
      etDateTime: format(new Date(), "yyyy-MM-dd"),
      embQualityET: "",
      udetSmooth: "",
      udetBlood: "",
      udetMucus: "",
      emb: "",
      id: 0,
    };
    data.push(newRow);
    formikProps.setFieldValue("eftlDetails", data);
  };

  const handleRemoveRow = () => {
    const data = [...eftlDetails];
    data.pop();
    formikProps.setFieldValue("eftlDetails", data);
  };

  return (
    <>
      <h4 className="mb-3 text-primary">Embryo Morphology</h4>
      <div className="mb-3">
        <Button className="mr-2" onClick={handleAddRow}>
          Add
        </Button>
        <Button onClick={handleRemoveRow} disabled={eftlDetails.length === 0}>
          Remove
        </Button>
      </div>
      <ReactTabulator
        className="table-sm mb-3 table-bordered text-center"
        columns={editableColumns}
        data={formikProps.values.eftlDetails}
      />
    </>
  );
};

export default EFTLDetails;
