import React from "react";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControl, {
 FormControlProps,
} from "react-bootstrap/esm/FormControl";
import Form from "react-bootstrap/esm/Form";
import { FormikProps } from "formik";
import _ from "lodash";
import RequiredIcon from "./RequiredIcon";

interface IProps extends FormControlProps {
 formikProps: FormikProps<any>;
 label?: string;
 name: string;
 placeholder?: string;
 showRequiredIcon?: boolean;
}

const yearOptions: number[] = [];

for (let index = new Date().getFullYear(); index >= 2016; index--) {
 yearOptions.push(index);
}

const TransactionCodeInput: React.FC<IProps> = (props) => {
 const {
  formikProps,
  label = "",
  name,
  placeholder = "",
  showRequiredIcon,
 } = props;
 const baseProps = _.omit(props, ["formikProps"]);
 const yearName = name.concat("Year");

 return (
  <>
   <Form.Group controlId={name}>
    <Form.Label>
     {label}
     {showRequiredIcon && <RequiredIcon />}
    </Form.Label>
    <InputGroup>
     <FormControl
      placeholder={placeholder}
      value={formikProps.values[name]}
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
      {...baseProps}
     />
     <InputGroup.Prepend>
      <Form.Control
       as="select"
       name={yearName}
       value={formikProps.values[yearName]}
       onChange={(e) =>
        formikProps.setFieldValue(yearName, +e.target.value)
       }
      >
       {yearOptions.map((year) => (
        <option key={year} value={year}>
         {year}
        </option>
       ))}
      </Form.Control>
     </InputGroup.Prepend>
    </InputGroup>
   </Form.Group>
  </>
 );
};

export default TransactionCodeInput;
