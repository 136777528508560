import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomTable from "../../app/common/table/CustomTable";
import { ISpermFreezingData } from "../../app/models/spermFreezing";
import { RootStoreContext } from "../../app/stores/rootStore";

interface IProps {
  printButton?: boolean;
}

const SpermFreezingsList: React.FC<IProps> = ({ printButton = false }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    getSpermFreezings,
    spermFreezingsList,
    total,
    pageSize,
    pageIndex,
    keywords,
  } = rootStore.spermFreezingStore;

  useEffect(() => {
    getSpermFreezings();
  }, [getSpermFreezings]);

  const columns: IDataTableColumn[] = [
    {
      name: "Id",
      selector: "id",
    },
    {
      name: "Storage Code",
      selector: "storageCode",
    },
    {
      name: "Storage Date",
      selector: "storageDate",
      format: (row) => format(new Date(row.storageDate), "yyyy-MM-dd"),
    },
    {
      name: "",
      button: true,
      cell: (row: ISpermFreezingData) => (
        <>
          {!printButton ? (
            <Button
              as={Link}
              to={`/sperm-freezings/edit/${row.id}`}
              variant="outline-primary"
            >
              Edit
            </Button>
          ) : (
            <Button
              as={Link}
              to={`/reports/sperm-cryopreservation/edit/${row.id}`}
              variant="outline-primary"
            >
              Print
            </Button>
          )}
        </>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    getSpermFreezings(newPage, pageSize, keywords);
  };

  const handlePerRowsChange = (newPageSize: number, page: number) => {
    getSpermFreezings(page, newPageSize, keywords);
  };

  const handleSearch = (keywords: string) => {
    getSpermFreezings(pageIndex, pageSize, keywords);
  };

  return (
    <Container>
      <CustomTable
        title="Sperm Freezing"
        columns={columns}
        data={spermFreezingsList}
        paginationTotalRows={total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSearch={handleSearch}
        rightComponent={
          <>
            {!printButton ? (
              <Button as={Link} to="/sperm-freezings/add">
                Add Sperm Freezing
              </Button>
            ) : null}
          </>
        }
      />
    </Container>
  );
};

export default observer(SpermFreezingsList);
