import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import { Form, Formik } from "formik";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import TextInput from "../../app/common/forms/TextInput";
import SelectInput from "../../app/common/forms/SelectInput";
import { boolOptions } from "../../app/common/options/boolOptions";
import { vaginalDischargeOptions } from "../../app/common/options/vaginalDischargeOptions";
import { cervixOptions } from "../../app/common/options/cervixOptions";
import { etPositionOption } from "../../app/common/options/etPositionOptions";
import { transferOptions } from "../../app/common/options/transferOptions";
import { attemptOptions } from "../../app/common/options/attemptOptions";
import { finalOutcomeOptions } from "../../app/common/options/finalOutcomeOptions";
import { treatmentOptions } from "../../app/common/options/treatmentOptions";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";
import {
 IEmbryoTransfers,
 EmbryoTransfersFormValues,
} from "../../app/models/embryoTransfers";
import FormSpinner from "../../app/common/forms/FormSpinner";
import Spinner from "react-bootstrap/esm/Spinner";
import PatientSelect from "../../app/common/forms/PatientSelect";
import DoctorSelect from "../../app/common/forms/DoctorSelect";
import EmbryoTransferCells from "./EmbryoTransferTable";
import EmbryoTransferHCGLevelTable from "./EmbryoTransferHCGLevelTable";
import EmbryoTransferUltrasoundScan from "./EmbryoTransferUltrasounScan";
import EmbryoTransferPlaceOfDelivery from "./EmbryoTransferPlaceOfDeliveryTable";
import { embOptions } from "../../app/common/options/embOptions";
import convertTime from "../../app/common/util";
import Container from "react-bootstrap/esm/Container";
import BackHomeButton from "../../app/common/button/BackHomeButton";

interface IParams {
 id: string;
}

const validationSchema = Yup.object<IEmbryoTransfers>().shape({
 wifeId: Yup.string().required("Patient Name is required"),
 artNumber: Yup.string().required("Art Number is required"),
 medicalDoctorId: Yup.string().required("Medical doctor is required"),
});

const FormEmbryoTransfer: React.FC<RouteComponentProps<IParams>> = ({
 match: {
  params: { id },
 },
}) => {
 const rootStore = useContext(RootStoreContext);
 const {
  createEmbryoTransfers,
  getEmbryoTransfersById,
  updateEmbryoTransfers,
 } = rootStore.embryoTransferStore;
 const [embryoTransfers, setEmbryoTransfers] = useState(
  new EmbryoTransfersFormValues()
 );
 const [isLoading, setIsLoading] = useState(false);

 useEffect(() => {
  if (id) {
   setIsLoading(true);
   getEmbryoTransfersById(+id)
    .then((data) => {
     setEmbryoTransfers(new EmbryoTransfersFormValues(data));
    })
    .finally(() => {
     setIsLoading(false);
    });
  }
  // eslint-disable-next-line
 }, []);

 return (
  <Container>
   <BackHomeButton />
   <Card>
    <Card.Body>
     <h2 className="text-center mb-5 text-primary">
      Embryo Transfer
     </h2>
     {isLoading ? (
      <FormSpinner />
     ) : (
      <Formik
       validationSchema={validationSchema}
       onSubmit={(values: IEmbryoTransfers) => {
        if (values.time.toString().length === 5) {
         values.dateTime = `${values.time}:00`;
        }

        values.dateTime = `${values.date}T${values.time}:00.000Z`;
        values.duration =
         values.duration !== ""
          ? convertTime(values.duration)
          : convertTime("00:00");
        values.numberOfAttempts = +values.numberOfAttempts;
        values.mucusOnCatheter = Boolean(values.mucusOnCatheter);
        values.ga = Boolean(values.ga);
        values.styletUsed = Boolean(values.styletUsed);
        values.tenaculum = Boolean(values.tenaculum);
        values.bloodOnCatheter = Boolean(values.bloodOnCatheter);

        if (id) {
         updateEmbryoTransfers(values);
        } else {
         createEmbryoTransfers(values);
        }
       }}
       initialValues={embryoTransfers}
       enableReinitialize={true}
      >
       {(formikProps) => {
        return (
         <Form>
          <Row>
           <Col>
            <PatientSelect
             name="wifeId"
             label="Name"
             patientType="Wife"
             placeholder="Select Name"
             showRequiredIcon
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInput
             name="artNumber"
             label="ART / TW No."
             type="text"
             formikProps={formikProps}
             showRequiredIcon
             className="text-uppercase"
            />
           </Col>
          </Row>
          <Row>
           <Col>
            <TextInput
             name="date"
             label="Date"
             type="date"
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInput
             name="time"
             label="Time"
             type="time"
             formikProps={formikProps}
            />
           </Col>

           <Col>
            <TextInput
             name="intervalTimeAfterOrIns"
             label="Interval Time after OR/Ins"
             type="text"
             formikProps={formikProps}
             className="text-uppercase"
            />
           </Col>
          </Row>
          <br />
          <EmbryoTransferCells formikProps={formikProps} />
          <br />

          <Row>
           <Col>
            <TextInput
             name="catheterUsed"
             label="Catheter Used"
             type="text"
             formikProps={formikProps}
             className="text-uppercase"
            />
           </Col>
           <Col>
            <TextInput
             name="etMedium"
             label="ET Medium"
             type="text"
             formikProps={formikProps}
             className="text-uppercase"
            />
           </Col>
          </Row>
          <Row>
           <Col>
            <SelectInput
             name="numberOfAttempts"
             label="No. of Attempts"
             options={attemptOptions}
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInput
             name="lot"
             label="Lot"
             type="text"
             formikProps={formikProps}
             className="text-uppercase"
            />
           </Col>
           <Col>
            <TextInput
             name="lotExpiry"
             label="Expiry"
             type="date"
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInput
             name="volume"
             label="Volume"
             type="number"
             formikProps={formikProps}
            />
           </Col>
          </Row>
          <Row>
           <Col>
            <SelectInput
             name="mucusOnCatheter"
             label="Mucus on Catheter"
             options={boolOptions}
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <SelectInput
             name="embryologist"
             label="Embryologist"
             formikProps={formikProps}
             options={embOptions}
            />
           </Col>
           <Col>
            <TextInput
             name="witness"
             label="Witness"
             type="text"
             formikProps={formikProps}
             className="text-uppercase"
            />
           </Col>
          </Row>
          <Row>
           <Col sm={12} md={4}>
            <SelectInput
             name="bloodOnCatheter"
             label="Blood on Catheter"
             options={boolOptions}
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInput
             name="rn"
             label="RN"
             type="text"
             formikProps={formikProps}
             className="text-uppercase"
            />
           </Col>
           <Col>
            <DoctorSelect
             name="medicalDoctorId"
             label="MD"
             placeholder="Select Doctor"
             showRequiredIcon
             formikProps={formikProps}
            />
           </Col>
           <Col>
            {/* <TextInput
             name="sono"
             label="Sono"
             type="text"
             formikProps={formikProps}
             className="text-uppercase"
            /> */}
            <DoctorSelect
             name="sono"
             label="Sono"
             //  placeholder="Select Doctor"
             formikProps={formikProps}
            />
           </Col>
          </Row>
          <Card className="border-muted">
           <br />
           <Row>
            <Col className="ml-3 mr-3">
             <TextInput
              name="endoThickness"
              label="Endo Thickness (mm)"
              type="number"
              placeholder="enter distance in mm"
              formikProps={formikProps}
             />
            </Col>
            <Col className="mr-3">
             <TextInput
              name="distanceFromFundus"
              label="Distance from Fundus (cm)"
              type="number"
              formikProps={formikProps}
             />
            </Col>
           </Row>
           <Row>
            <Col className="ml-3 mr-3">
             <SelectInput
              name="tenaculum"
              label="Tenaculum"
              options={boolOptions}
              formikProps={formikProps}
             />
            </Col>
            <Col>
             <SelectInput
              name="styletUsed"
              label="Stylet Used"
              options={boolOptions}
              formikProps={formikProps}
             />
            </Col>
            <Col className="mr-3">
             <SelectInput
              name="ga"
              label="G.A"
              options={boolOptions}
              formikProps={formikProps}
             />
            </Col>
           </Row>
           <Row>
            <Col className="ml-3 mr-3">
             <SelectInput
              name="etPosition"
              label="ET Position"
              options={etPositionOption}
              formikProps={formikProps}
             />
            </Col>
            <Col className="mr-3">
             <TextInput
              name="sonologist"
              label="Sonologist"
              type="text"
              formikProps={formikProps}
              className="text-uppercase"
             />
            </Col>
           </Row>
           <Row>
            <Col className="ml-3 mr-3">
             <SelectInput
              name="vaginalDischarge"
              label="Vaginal Discharge"
              options={vaginalDischargeOptions}
              formikProps={formikProps}
             />
            </Col>
            <Col className="mr-3">
             <SelectInput
              name="cervix"
              label="Cervix"
              options={cervixOptions}
              formikProps={formikProps}
             />
            </Col>
           </Row>
           <Row>
            <Col className="ml-3 mr-3">
             <SelectInput
              name="transfer"
              label="Transfer"
              options={transferOptions}
              formikProps={formikProps}
             />
            </Col>
            <Col className="mr-3">
             <TextInput
              name="myometrialContractility"
              label="Myometrial Contractility"
              type="text"
              formikProps={formikProps}
              className="text-uppercase"
             />
            </Col>
           </Row>

           <Row>
            <Col className="ml-3 mr-3">
             <TextInput
              name="ifDifficultReason"
              label="If difficult, reason"
              type="text"
              formikProps={formikProps}
              className="text-uppercase"
             />
            </Col>
           </Row>
           <Row>
            <Col className="ml-3 mr-3">
             <TextInput
              name="bleedingOrDiscomfort"
              label="Bleeding or Discomfort"
              type="text"
              formikProps={formikProps}
              className="text-uppercase"
             />
            </Col>
           </Row>
           <Row>
            <Col className="ml-3 mr-3">
             <SelectInput
              name="postTransferPosition"
              label="Post Transfer Position"
              options={transferOptions}
              formikProps={formikProps}
             />
            </Col>
            <Col className="mr-3">
             <TextInput
              name="duration"
              label="Duration"
              type="time"
              formikProps={formikProps}
             />
            </Col>
           </Row>
          </Card>
          <Card className="border-muted">
           <Row>
            <Col sm={12} md={6} className="pr-0">
             <Card className="border-muted pb-4">
              <h4 className="mt-3 ml-3 text-primary">
               Summary of Treatment Cycle
              </h4>

              <Row>
               <Col className="ml-3 mr-3">
                <SelectInput
                 name="treatment"
                 label="Treatment"
                 options={treatmentOptions}
                 formikProps={formikProps}
                />
               </Col>
              </Row>
              <Row>
               <Col className="mr-3 ml-3">
                <TextInput
                 name="treatmentCycleArtNumber"
                 label="ART /TW No."
                 type="text"
                 formikProps={formikProps}
                 className="text-uppercase"
                />
               </Col>
              </Row>
              <Row>
               <Col className="ml-3 mr-3">
                <TextInput
                 name="totalNumberOocytesEmbryos"
                 label="Total No. of Oocytes"
                 type="number"
                 formikProps={formikProps}
                />
               </Col>
              </Row>
              <Row>
               <Col className="ml-3 mr-3">
                <TextInput
                 name="numberFertilisedNormal"
                 label="No. Fertilised:N:"
                 type="number"
                 formikProps={formikProps}
                />
               </Col>
               <Col className="mr-3">
                <TextInput
                 name="numberFertilisedAbnormal"
                 label="Abn"
                 type="number"
                 formikProps={formikProps}
                />
               </Col>
              </Row>
             </Card>
             <Card className="border-muted">
              <article className="mt-3 ml-3 text-primary">
               {" "}
               Failed Fertilization / Failed Cleavage
              </article>
              <Row>
               <Col className="ml-3 mr-3">
                <TextInput
                 name="anyAbnormalities"
                 label="Any Abnormalities"
                 type="text"
                 formikProps={formikProps}
                 className="text-uppercase"
                />
               </Col>
              </Row>
              <Row>
               <Col className="ml-3 mr-3">
                <TextInput
                 name="eggQuality"
                 label="Egg Quality"
                 type="text"
                 formikProps={formikProps}
                 className="text-uppercase"
                />
               </Col>
              </Row>
              <Row>
               <Col className="ml-3 mr-3">
                <TextInput
                 name="spermQuality"
                 label="Sperm Quality"
                 type="text"
                 formikProps={formikProps}
                 className="text-uppercase"
                />
               </Col>
              </Row>
             </Card>
             <Card className="border-muted">
              <Row>
               <Col className="ml-3 mr-3 mt-3">
                <TextInput
                 name="numberStageOfEmbryosFrozen"
                 label="No. Stage of Embryos Frozen"
                 type="text"
                 formikProps={formikProps}
                 className="text-uppercase"
                />
               </Col>
              </Row>
              <Row>
               <Col className="ml-3 mr-3">
                <TextInput
                 name="efNumber"
                 label="EF No."
                 type="text"
                 formikProps={formikProps}
                 className="text-uppercase"
                />
               </Col>
              </Row>
              <Row>
               <Col className="ml-3 mr-3">
                <TextInput
                 name="remarks"
                 label="Remarks"
                 type="text"
                 formikProps={formikProps}
                 className="text-uppercase"
                />
               </Col>
              </Row>
             </Card>
            </Col>

            <Col sm={12} md={6} className="pl-0">
             <Card className="border-muted pb-5">
              <h4 className="text-primary mt-3 ml-3 mr-3">OUTCOME</h4>
              <Row>
               <Col className="ml-3 mr-3">
                <EmbryoTransferHCGLevelTable
                 formikProps={formikProps}
                />
               </Col>
              </Row>
              <Row>
               <article className="mt-3 ml-4 text-primary pl-2">
                Ultrasound Scan :
               </article>
              </Row>
              <Row>
               <Col className="ml-3 mr-3">
                <EmbryoTransferUltrasoundScan
                 formikProps={formikProps}
                />
               </Col>
              </Row>

              <Row>
               <Col className="ml-3 mr-3">
                <SelectInput
                 name="finalOutcome"
                 label="Final Outcome"
                 options={finalOutcomeOptions}
                 formikProps={formikProps}
                />
               </Col>
              </Row>
              <Row>
               <article className="mb-2 mt-2 text-primary ml-4 pl-2">
                DELIVERY:
               </article>
              </Row>
              <br />
              <br />
              <Row>
               <article className="mb-3 mt-2 text-primary ml-4 pl-2">
                Place of Delivery:
               </article>
              </Row>

              <Row>
               <Col className="ml-3 mr-3">
                <EmbryoTransferPlaceOfDelivery
                 formikProps={formikProps}
                />
                <Button
                 variant="primary"
                 type="submit"
                 className="float-right"
                 disabled={
                  formikProps.isSubmitting || !formikProps.isValid
                 }
                >
                 {formikProps.isSubmitting && (
                  <Spinner
                   as="span"
                   animation="border"
                   size="sm"
                   role="status"
                   aria-hidden="true"
                   className="mr-2"
                  />
                 )}
                 Submit
                </Button>
               </Col>
              </Row>
             </Card>
            </Col>
           </Row>
          </Card>
         </Form>
        );
       }}
      </Formik>
     )}
    </Card.Body>
   </Card>
  </Container>
 );
};

export default FormEmbryoTransfer;
