import { IICSIData, IICSI } from "./../models/icsi";
import { observable, action, runInAction } from "mobx";

import ICSIs from "../services/icsiService";
import { RootStore } from "./rootStore";
import { history } from "../..";

export default class ICSIStore {
 rootStore: RootStore;

 constructor(rootStore: RootStore) {
  this.rootStore = rootStore;
 }

 @observable icsisList: IICSIData[] = [];
 @observable selectedICSI: IICSI | undefined = undefined;
 @observable pageIndex: number = 1;
 @observable pageSize: number = 10;
 @observable total: number = 0;
 @observable keywords: string | undefined = undefined;

 @action getICSIs = async (
  pageIndex: number = 1,
  pageSize: number = 10,
  search?: string
 ) => {
  this.icsisList = [];
  this.rootStore.commonStore.isDataLoading = true;
  try {
   const response = await ICSIs.get(pageIndex, pageSize, search);
   runInAction(() => {
    this.pageIndex = response.pageIndex;
    this.pageSize = response.pageSize;
    this.total = response.count;
    this.icsisList = response.data;
    this.keywords = search;
   });
  } catch (error) {
   console.log(error);
  } finally {
   runInAction(() => {
    this.rootStore.commonStore.isDataLoading = false;
   });
  }
 };

 @action getICSIById = async (id: number) => {
  try {
   const icsi = await ICSIs.getById(id);
   runInAction(() => {
    this.selectedICSI = icsi;
   });
   return icsi;
  } catch (error) {
   console.log(error);
  }
 };

 @action createICSI = async (values: IICSI) => {
  this.rootStore.commonStore.isOverlayLoading = true;
  try {
   await ICSIs.create(values);
   history.push("/icsis");
  } catch (error) {
   console.log(error);
  } finally {
   runInAction(() => {
    this.rootStore.commonStore.isOverlayLoading = false;
   });
  }
 };

 @action updateICSI = async (values: IICSI) => {
  this.rootStore.commonStore.isOverlayLoading = true;
  try {
   await ICSIs.update(values);
   history.push("/icsis");
  } catch (error) {
   console.log(error);
  } finally {
   runInAction(() => {
    this.rootStore.commonStore.isOverlayLoading = false;
   });
  }
 };
}
