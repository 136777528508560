import { RouteProps } from "react-router-dom";
import FormThawedCycleReport from "../../../components/ThawedCycleReport/FormThawedCycleReport";

const thawedCycleReportRoutes: RouteProps[] = [
  {
    path: "/reports/thawed-cycle-report",
    component: FormThawedCycleReport,
  },
];

export default thawedCycleReportRoutes;
