import { action, observable, runInAction } from "mobx";
import { ILogData } from "../models/log";
import Logs from "../services/logService";
import { RootStore } from "./rootStore";

export default class LogStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable userLogsList: ILogData[] = [];
  @observable pageIndex: number = 1;
  @observable pageSize: number = 10;
  @observable total: number = 0;
  @observable keywords: string | undefined = undefined;

  @action getUserLogs = async (
    pageIndex: number = 1,
    pageSize: number = 10,
    search?: string
  ) => {
    this.userLogsList = [];
    this.rootStore.commonStore.isDataLoading = true;
    try {
      const response = await Logs.getUserLogs(pageIndex, pageSize, search);
      runInAction(() => {
        this.pageIndex = response.pageIndex;
        this.pageSize = response.pageSize;
        this.total = response.count;
        this.userLogsList = response.data;
        this.keywords = search;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isDataLoading = false;
      });
    }
  };
}
