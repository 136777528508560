import { IPatientData, IPatientOption } from "./../models/patient";
import { observable, action, runInAction } from "mobx";
import { IPatient } from "../models/patient";
import Patients from "../services/patientService";
import { RootStore } from "./rootStore";
import { history } from "../..";

export default class PatientStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable patientsList: IPatientData[] = [];
  @observable selectedPatient: IPatient | undefined = undefined;
  @observable pageIndex: number = 1;
  @observable pageSize: number = 10;
  @observable total: number = 0;
  @observable keywords: string | undefined = undefined;
  @observable selectedPatientOption: IPatientOption | undefined = undefined;

  @action getPatients = async (
    pageIndex: number = 1,
    pageSize: number = 10,
    search?: string
  ) => {
    this.patientsList = [];
    this.rootStore.commonStore.isDataLoading = true;
    try {
      const response = await Patients.get(pageIndex, pageSize, search);
      runInAction(() => {
        this.pageIndex = response.pageIndex;
        this.pageSize = response.pageSize;
        this.total = response.count;
        this.patientsList = response.data;
        this.keywords = search;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isDataLoading = false;
      });
    }
  };

  @action getPatientById = async (id: number) => {
    try {
      const patient = await Patients.getById(id);
      runInAction(() => {
        this.selectedPatient = patient;
      });
      return patient;
    } catch (error) {
      console.log(error);
    }
  };

  @action createPatient = async (values: IPatient) => {
    this.rootStore.commonStore.isOverlayLoading = true;
    try {
      await Patients.create(values);
      history.push("/patients");
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isOverlayLoading = false;
      });
    }
  };

  @action updatePatient = async (values: IPatient) => {
    try {
      await Patients.update(values);
      history.push("/patients");
    } catch (error) {
      console.log(error);
    }
  };

  @action setSelectedPatientOption = (
    selectedPatientOption: IPatientOption
  ) => {
    this.selectedPatientOption = selectedPatientOption;
    console.log(selectedPatientOption);
  };
}
