export default function convertTime(time: string | Date) {
  const year = new Date().getFullYear();
  const month = new Date().getMonth().toString().padStart(2, "0");
  const day = new Date().getDate().toString().padStart(2, "0");

  if (time.toString().length === 5) {
    time = `${time}:00`;
  }

  return `${year}-${month}-${day}T${time}.000Z`;
}
