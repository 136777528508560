import React from "react";
import InputGroup from "react-bootstrap/esm/InputGroup";
import Button from "react-bootstrap/esm/Button";
import FormControl from "react-bootstrap/esm/FormControl";
import { Form, Formik } from "formik";

interface IProps {
  handleSearch: (keywords: string) => void;
}

const SearchInput: React.FC<IProps> = ({ handleSearch }) => {
  return (
    <Formik
      onSubmit={(values: any) => {
        handleSearch(values.keywords);
      }}
      initialValues={{
        keywords: "",
      }}
    >
      {(formikProps) => (
        <Form>
          <InputGroup>
            <FormControl
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              value={formikProps.values.keywords}
              onChange={formikProps.handleChange}
              name="keywords"
            />
            <InputGroup.Append>
              <Button type="submit" variant="outline-primary">
                Search
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
      )}
    </Formik>
  );
};

export default SearchInput;
