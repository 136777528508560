import React, { useContext} from "react";
import {
  RouteProps,
  RouteComponentProps,
  Route,
  Redirect,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { IdleTimerProvider } from "react-idle-timer";
import { toast } from "react-toastify";


interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
}

const RouterPrivate: React.FC<IProps> = ({ component: Component, ...rest }) => {
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn } = rootStore.userStore;

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    toast.warn("You were idle, Please save your changes!", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <IdleTimerProvider timeout={1000 * 60 * 5} onIdle={onIdle}>
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? <Component {...props} /> : <Redirect to={"/login"} />
        }
      />
    </IdleTimerProvider>
  );
};

export default observer(RouterPrivate);
