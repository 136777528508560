import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { RouteComponentProps } from "react-router";
import Background from "../../assets/images/public_background.jpg";

const style = {
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
  opacity: 0.95,
  overflow: "auto",
};

interface IParams {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const LayoutPublic: React.FC<RouteComponentProps & IParams> = ({
  component,
  ...rest
}) => {
  const Component = component;
  return (
    <div style={style}>
      <Container>
        <Row className="min-vh-100 align-items-center justify-content-center">
          <Component {...rest} />
        </Row>
      </Container>
    </div>
  );
};

export default LayoutPublic;
