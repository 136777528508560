import React from "react";
import ICSIsList from "../ICSI/ICSIsList";

const FormEmbryologySummaryReportIndex = () => {
    return (
        <>
            <ICSIsList printButton={true}/>
        </>
    );
};

export default FormEmbryologySummaryReportIndex;