import { RouteProps } from "react-router-dom";
import FormEmbryologySummaryReportIndex from "../../../components/EmbryologySummaryReport/FormEmbryologySummaryReportIndex";

const embryologySummaryRoutes: RouteProps[] = [
  {
    path: "/reports/embryology-summary-report",
    component: FormEmbryologySummaryReportIndex,
  },
];

export default embryologySummaryRoutes;
