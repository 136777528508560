import React, { useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import { RouteComponentProps } from "react-router-dom";


interface IProps extends RouteComponentProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const ReportLayout: React.FC<IProps> = ({ component, ...rest }) => {
  const Component = component;

  useEffect(() => {
    window.onbeforeprint = function() {

      let elems =  document.getElementsByClassName("hide-on-print") as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < elems.length; i += 1) {
         elems[i].style.display = "none";
      }

      let inputs = this.document.getElementsByClassName("text-on-print") as HTMLCollectionOf<HTMLElement>;
      for (let j = 0; j < inputs.length; j += 1) {
        inputs[j].style.border = "none";
      }

      let elements = document.getElementsByClassName("show-on-print") as HTMLCollectionOf<HTMLElement>;
      for (let k = 0; k < elements.length; k += 1) {
        elements[k].style.display = "block";
      }
    };

    window.onafterprint = function() {
      var elems = document.getElementsByClassName("hide-on-print") as HTMLCollectionOf<HTMLElement>;
      for (var i = 0; i < elems.length; i += 1) {
        elems[i].style.display = "block";
      }

      let inputs = this.document.getElementsByClassName("text-on-print") as HTMLCollectionOf<HTMLElement>;
      for (let j = 0; j < inputs.length; j += 1) {
        inputs[j].style.border = "1px black solid";
      }

      let elements = document.getElementsByClassName("show-on-print") as HTMLCollectionOf<HTMLElement>;
      for (let k = 0; k < elements.length; k += 1) {
        elements[k].style.display = "none";
      }
    };
  }, []);
 
  return (
    <>
      <Container className="py-5">
        <Component {...rest} />
      </Container>
    </>
  );
};

export default ReportLayout;
