import { FormikProps } from "formik";
import React from "react";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";

interface IProps {
 formikProps: FormikProps<any>;
}

const ICSIDay5Table: React.FC<IProps> = ({ formikProps }) => {
 const { icsiDetails } = formikProps.values;

 const editableColumns = [
  {
   title: "EGG",
   field: "day5Egg",
   editor: "label",
   headerSort: false,
  },
  {
   title: "Dish",
   field: "day5Dish",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Cell",
   field: "day5Cell",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Frag",
   field: "day5Frag",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Score",
   field: "day5Score",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Notes",
   field: "day5Notes",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Option",
   field: "option",
   editor: "input",
   headerSort: false,
  },
 ];

 return (
  <ReactTabulator
   className="table-sm mb-3 table-bordered table-responsive text-uppercase"
   columns={editableColumns}
   data={icsiDetails}
  />
 );
};

export default ICSIDay5Table;
