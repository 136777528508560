import { FormikProps } from "formik";
import React from "react";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";

interface IProps {
  formikProps: FormikProps<any>;
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  prepend?: any;
  append?: any;
}

const TextInputGroup: React.FC<IProps> = ({
  formikProps,
  label = "",
  name,
  type = "text",
  placeholder = "",
  prepend,
  append,
}) => {
  return (
    <>
      <Form.Group controlId={name}>
        <Form.Label>{label}</Form.Label>
        <InputGroup className="mb-3">
          {prepend && <InputGroup.Prepend>{prepend}</InputGroup.Prepend>}
          <Form.Control
            name={name}
            type={type}
            placeholder={placeholder}
            value={formikProps.values[name]}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
          {append && <InputGroup.Prepend>{append}</InputGroup.Prepend>}
        </InputGroup>
        {formikProps.errors[name] && (
          <Form.Control.Feedback className="d-block" type="invalid">
            <p className="m-0">{formikProps.errors[name]}</p>
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

export default TextInputGroup;
