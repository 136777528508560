import { OptionHTMLAttributes } from "react";

export const sourceOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "Donor",
    value: "Donor",
  },
  {
    label: "Patient",
    value: "Patient",
  },

];
