/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import Card from "react-bootstrap/esm/Card";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import { Form, Formik } from "formik";
import TextInput from "../../app/common/forms/TextInput";
import TextInputGroup from "../../app/common/forms/TextInputGroup";
import PatientSelect from "../../app/common/forms/PatientSelect";
import DoctorSelect from "../../app/common/forms/DoctorSelect";
import { RootStoreContext } from "../../app/stores/rootStore";
import { IEFTL, EFTLFormValues } from "../../app/models/eftl";
import FormSpinner from "../../app/common/forms/FormSpinner";
import { RouteComponentProps } from "react-router-dom";
import EFTLDetails from "./EFTLDetails";
import { IPatientOption } from "../../app/models/patient";
import Spinner from "react-bootstrap/esm/Spinner";
import InputGroup from "react-bootstrap/esm/InputGroup";
import SelectInput from "../../app/common/forms/SelectInput";
import { embOptions } from "../../app/common/options/embOptions";
import Container from "react-bootstrap/esm/Container";
import BackHomeButton from "../../app/common/button/BackHomeButton";
import IvfSelect from "../../app/common/forms/IvfSelect";

interface IParams {
 id: string;
}

const validationSchema = Yup.object<IEFTL>().shape({
 efNumber: Yup.string().required("EF number is required"),
 wifeId: Yup.number().required("Wife is required"),
 husbandId: Yup.number().required("Husband is required"),
 doctorId: Yup.number().required("MD is required"),
});

const FormEFTL: React.FC<RouteComponentProps<IParams>> = ({
 match: {
  params: { id },
 },
}) => {
 const rootStore = useContext(RootStoreContext);
 const { createEFTL, getEFTLById, updateEFTL } = rootStore.eftlStore;
 const [eftl, setEFTL] = useState(new EFTLFormValues());
 const [isLoading, setIsLoading] = useState(false);

 useEffect(() => {
  if (id) {
   setIsLoading(true);
   getEFTLById(+id)
    .then((eftl) => {
     setEFTL(new EFTLFormValues(eftl));
    })
    .finally(() => {
     setIsLoading(false);
    });
  }
  // eslint-disable-next-line
 }, []);

 return (
  <Container>
   <BackHomeButton />
   <Card>
    <Card.Body>
     <h2 className="text-center mb-5 text-primary">
      Embryo Freezing and Thawing Laboratory Form
     </h2>
     {isLoading ? (
      <FormSpinner />
     ) : (
      <Formik
       validationSchema={validationSchema}
       onSubmit={(values: IEFTL) => {
        if (values.freezingTime.toString().length === 5) {
         values.freezingTime = `${values.freezingTime}:00`;
        }

        values.freezingDateTime = `${values.freezingDate}T${values.freezingTime}.000Z`;

        if (id) {
         updateEFTL(values);
        } else {
         createEFTL(values);
        }
       }}
       initialValues={eftl}
       enableReinitialize={true}
      >
       {(formikProps) => {
        const setPatientAge = (selectedOption: IPatientOption) => {
         formikProps.setFieldValue("patientAge", selectedOption.age);
        };

        const setPartnerAge = (selectedOption: IPatientOption) => {
         formikProps.setFieldValue("partnerAge", selectedOption.age);
        };
        return (
         <Form>
          <Row>
           <Col>
            <TextInput
             name="efNumber"
             label="EF No."
             type="text"
             showRequiredIcon
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
           <Col>
            {/* <TextInput
                          name="artNumber"
                          label="ART No."
                          type="text"
                          className="text-uppercase"
                          formikProps={formikProps}
                        /> */}
            <IvfSelect
             name="artNumber"
             label="ART No."
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInput
             name="opuDate"
             label="OPU Date"
             type="date"
             formikProps={formikProps}
            />
           </Col>
          </Row>
          <Row>
           <Col sm={12} md={6}>
            <PatientSelect
             name="wifeId"
             label="Wife"
             showRequiredIcon
             placeholder="Select Wife..."
             patientType="Wife"
             formikProps={formikProps}
             setOnChange={setPartnerAge}
            />
           </Col>

           <Col sm={12} md={3}>
            <TextInput
             name="partnerAge"
             label="Age"
             type="number"
             formikProps={formikProps}
             readOnly={true}
            />
           </Col>
           <Col sm={12} md={3}>
            <TextInput
             name="freezingDate"
             label="Freezing Date"
             type="date"
             formikProps={formikProps}
            />
           </Col>
          </Row>
          <Row>
           <Col sm={12} md={6}>
            <PatientSelect
             name="husbandId"
             label="Husband"
             showRequiredIcon
             placeholder="Select Husband..."
             patientType="Husband"
             formikProps={formikProps}
             setOnChange={setPatientAge}
            />
           </Col>
           <Col sm={12} md={3}>
            <TextInput
             name="patientAge"
             label="Age"
             type="number"
             formikProps={formikProps}
             readOnly={true}
            />
           </Col>
           <Col sm={12} md={3}>
            <TextInput
             name="freezingTime"
             label="Freezing Time"
             type="time"
             formikProps={formikProps}
            />
           </Col>
          </Row>
          <Row>
           <Col>
            <TextInput
             name="protocol"
             label="Protocol"
             type="text"
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInput
             name="freezingMedium"
             label="Freezing Medium"
             type="text"
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInput
             name="lot"
             label="Lot"
             type="string"
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInput
             name="expiry"
             label="Expiry"
             type="string"
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
          </Row>
          <Row>
           <Col md={6}>
            <DoctorSelect
             name="doctorId"
             label="MD"
             showRequiredIcon
             placeholder="Select Doctor..."
             formikProps={formikProps}
            />
           </Col>
          </Row>

          <br />
          <EFTLDetails formikProps={formikProps} />
          <br />

          <Row>
           <Col>
            <TextInput
             name="day"
             label="Day"
             type="number"
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <TextInputGroup
             name="efHours"
             label="EF"
             type="number"
             formikProps={formikProps}
             append={<InputGroup.Text>hrs</InputGroup.Text>}
            />
           </Col>
           <Col>
            <TextInput
             name="gobletColor"
             label="Goblet Colour"
             type="text"
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
          </Row>
          <Row>
           <Col>
            <TextInput
             name="efSummary"
             label="EF Summary"
             type="text"
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
          </Row>
          <Row>
           <Col>
            <TextInput
             name="dewarLocation"
             label="Dewar Location"
             type="text"
             className="text-uppercase"
             formikProps={formikProps}
            />
           </Col>
           <Col>
            <SelectInput
             name="embryologist"
             label="Embryologist"
             formikProps={formikProps}
             options={embOptions}
            />
           </Col>
          </Row>
          <Button
           className="float-right"
           type="submit"
           size="lg"
           variant="primary"
           disabled={
            formikProps.isSubmitting ||
            !formikProps.isValid ||
            !formikProps.values.wifeId ||
            !formikProps.values.husbandId ||
            !formikProps.values.doctorId
           }
          >
           {formikProps.isSubmitting && (
            <Spinner
             as="span"
             animation="border"
             size="sm"
             role="status"
             aria-hidden="true"
             className="mr-2"
            />
           )}
           Submit
          </Button>
         </Form>
        );
       }}
      </Formik>
     )}
    </Card.Body>
   </Card>
  </Container>
 );
};

export default FormEFTL;
