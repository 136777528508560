import { createContext } from "react";
import { configure } from "mobx";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import AuthStore from "./authStore";
import DoctorStore from "./doctorStore";
import PatientStore from "./patientStore";
import SpermFreezingStore from "./spermFreezingStore";
import IVFStore from "./ivfStore";
import ICSIStore from "./icsiStore";
import EFTLStore from "./eftlStore";
import LogStore from "./logStore";
import EmbryoTransferStore from "./embryoTransferStore";

configure({ enforceActions: "always" });

export class RootStore {
  userStore: UserStore;
  commonStore: CommonStore;
  modalStore: ModalStore;
  authStore: AuthStore;
  doctorStore: DoctorStore;
  patientStore: PatientStore;
  spermFreezingStore: SpermFreezingStore;
  ivfStore: IVFStore;
  icsiStore: ICSIStore;
  eftlStore: EFTLStore;
  logStore: LogStore;
  embryoTransferStore: EmbryoTransferStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.commonStore = new CommonStore(this);
    this.modalStore = new ModalStore(this);
    this.authStore = new AuthStore(this);
    this.doctorStore = new DoctorStore(this);
    this.patientStore = new PatientStore(this);
    this.spermFreezingStore = new SpermFreezingStore(this);
    this.ivfStore = new IVFStore(this);
    this.icsiStore = new ICSIStore(this);
    this.eftlStore = new EFTLStore(this);
    this.logStore = new LogStore(this);
    this.embryoTransferStore = new EmbryoTransferStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
