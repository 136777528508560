import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/esm/Nav";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomTable from "../../app/common/table/CustomTable";
import { RootStoreContext } from "../../app/stores/rootStore";
import UserLogsList from "../Logs/UserLogsList";

const UsersList = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getUsers,
    usersList,
    total,
    pageSize,
    pageIndex,
    keywords,
  } = rootStore.userStore;
  const [currentPage, setCurrentPage] = useState("users");

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const columns: IDataTableColumn[] = [
    {
      name: "Id",
      selector: "id",
    },
    {
      name: "First Name",
      selector: "firstName",
    },
    {
      name: "Last Name",
      selector: "lastName",
    },
  ];

  const handlePageChange = (newPage: number) => {
    getUsers(newPage, pageSize, keywords);
  };

  const handlePerRowsChange = (newPageSize: number, page: number) => {
    getUsers(page, newPageSize, keywords);
  };

  const handleSearch = (keywords: string) => {
    getUsers(pageIndex, pageSize, keywords);
  };

  return (
    <Container>
      <Nav justify variant="tabs" defaultActiveKey="users">
        <Nav.Item>
          <Nav.Link eventKey="users" onClick={() => setCurrentPage("users")}>
            Users
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="users-logs"
            onClick={() => setCurrentPage("users-logs")}
          >
            Logs
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <br />
      {currentPage === "users" ? (
        <CustomTable
          title="Users"
          columns={columns}
          data={usersList}
          paginationTotalRows={total}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSearch={handleSearch}
          rightComponent={
            <>
              <Button as={Link} to="/users/add">
                Add User
              </Button>
            </>
          }
        />
      ) : (
        <UserLogsList />
      )}
    </Container>
  );
};

export default observer(UsersList);
