import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextInput from "../../app/common/forms/TextInput";
import Spinner from "react-bootstrap/esm/Spinner";
import { RootStoreContext } from "../../app/stores/rootStore";
import { IPatient, PatientFormValues } from "../../app/models/patient";
import Row from "react-bootstrap/esm/Row";
import SelectInput from "../../app/common/forms/SelectInput";
import { boolOptions } from "../../app/common/options/boolOptions";
import { genderOptions } from "../../app/common/options/genderOptions";
import { patientTypeOptions } from "../../app/common/options/patientTypeOptions";
import FileInput from "../../app/common/forms/FileInput";
import { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ImageCropper from "../../app/common/forms/ImageCropper";
import { RouteComponentProps } from "react-router-dom";
import InputGroup from "react-bootstrap/esm/InputGroup";
import TextInputGroup from "../../app/common/forms/TextInputGroup";
import FormSpinner from "../../app/common/forms/FormSpinner";
import { observer } from "mobx-react-lite";
import { differenceInYears } from "date-fns";
import DoctorSelect from "../../app/common/forms/DoctorSelect";
import { idTypeOptions } from "../../app/common/options/idTypeOptions";
import defaultUser from "../../app/assets/images/default_user.png";
import Container from "react-bootstrap/esm/Container";
import BackHomeButton from "../../app/common/button/BackHomeButton";

interface IParams {
 id: string;
}

const validationSchema = Yup.object<IPatient>().shape({
 firstName: Yup.string().required("First Name is required"),
 lastName: Yup.string().required("Last name is required"),
 address: Yup.string().required("Address is required"),
 contactNumber: Yup.string().required("Contact Number is required"),
 email: Yup.string().required("Email is required"),
 birthDate: Yup.string().required("Birth Date is required."),
 age: Yup.number().required("Age is required."),
 idType: Yup.string().required("Id Type is required"),
 idNumber: Yup.string().required("Id Number is required"),
});

const FormPatient: React.FC<RouteComponentProps<IParams>> = ({
 match: {
  params: { id },
 },
}) => {
 const rootStore = useContext(RootStoreContext);
 const { createPatient, getPatientById, updatePatient } =
  rootStore.patientStore;
 const [patient, setPatient] = useState(new PatientFormValues());
 const [isLoading, setIsLoading] = useState(false);
 const [selectedImage, setSelectedImage] = useState<any>();
 const previewCanvasRef = useRef<any>(null);
 const [completedCrop, setCompletedCrop] = useState<any>(null);
 const [crop, setCrop] = useState<Crop>({
  unit: "%",
  width: 30,
  aspect: 1,
 });

 useEffect(() => {
  if (id) {
   setIsLoading(true);
   getPatientById(+id)
    .then((patient) => {
     setPatient(new PatientFormValues(patient));
    })
    .finally(() => {
     setIsLoading(false);
    });
  }
  // eslint-disable-next-line
 }, []);

 const getResizedCanvas = (
  canvas: HTMLCanvasElement,
  newWidth: number,
  newHeight: number
 ) => {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx!.drawImage(
   canvas,
   0,
   0,
   canvas.width,
   canvas.height,
   0,
   0,
   newWidth,
   newHeight
  );

  return tmpCanvas;
 };

 const handleFileSelect = (e: any) => {
  if (e.target.files && e.target.files.length > 0) {
   const reader = new FileReader();
   reader.addEventListener("load", () => setSelectedImage(reader.result));
   reader.readAsDataURL(e.target.files[0]);
  }
 };

 const handleImageCrop = (previewCanvas: any, crop: any) => {
  if (!crop || !previewCanvas) {
   return;
  }

  const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

  setSelectedImage(null);

  return canvas.toDataURL("image/jpeg");
 };

 return (
  <Container>
   <BackHomeButton />
   <Card>
    <Card.Body>
     <h2 className="text-center mb-5 text-primary">Add Patient</h2>
     {isLoading ? (
      <FormSpinner />
     ) : (
      <Formik
       validationSchema={validationSchema}
       onSubmit={(values: IPatient) => {
        values.withHMO = Boolean(values.withHMO);
        if (id) {
         updatePatient(values);
        } else {
         createPatient(values);
        }
       }}
       initialValues={patient}
       enableReinitialize={true}
      >
       {(formikProps) => {
        const handleBirthDateChange = (value: Date | string) => {
         const age = differenceInYears(new Date(), new Date(value));
         formikProps.setFieldValue("age", age);
        };

        const genderChange = (value: string) => {
         const newGender = value === "Female" ? "Wife" : "Husband";
         formikProps.setFieldValue("gender", value);
         formikProps.setFieldValue("patientType", newGender);
        };

        return (
         <Form>
          <Row>
           <Col md={3}>
            {selectedImage ? (
             <>
              <canvas ref={previewCanvasRef} className="w-100 mb-3" />
              <Button
               variant="primary"
               onClick={() => {
                const img = handleImageCrop(
                 previewCanvasRef.current,
                 completedCrop
                );
                formikProps.setFieldValue("imageUrl", img);
               }}
               block
              >
               Crop
              </Button>
              <Button
               variant="danger"
               block
               onClick={() => setSelectedImage(null)}
              >
               Cancel
              </Button>
             </>
            ) : (
             <>
              <img
               src={
                formikProps.values.imageUrl
                 ? formikProps.values.imageUrl
                 : defaultUser
               }
               alt="patient-img"
               className="w-100 mb-3"
              />
              <FileInput
               label="Select Image"
               accept="image/*"
               onChange={handleFileSelect}
              />
             </>
            )}
           </Col>
           <Col>
            {selectedImage && (
             <ImageCropper
              selectedImage={selectedImage}
              previewCanvasRef={previewCanvasRef}
              crop={crop}
              setCrop={setCrop}
              completedCrop={completedCrop}
              setCompletedCrop={setCompletedCrop}
             />
            )}
            <Row>
             <Col>
              <TextInput
               name="firstName"
               label="First Name"
               placeholder="First Name"
               type="text"
               className="text-uppercase"
               formikProps={formikProps}
              />
             </Col>
             <Col>
              <TextInput
               name="middleName"
               label="Middle Name"
               type="text"
               className="text-uppercase"
               formikProps={formikProps}
              />
             </Col>
            </Row>
            <Row>
             <Col className="col-lg-6">
              <TextInput
               name="lastName"
               label="Last Name"
               placeholder="Last Name"
               type="text"
               className="text-uppercase"
               formikProps={formikProps}
              />
             </Col>
            </Row>
            <Row>
             <Col>
              <TextInput
               name="address"
               label="Address"
               type="text"
               className="text-uppercase"
               formikProps={formikProps}
              />
             </Col>
             <Col>
              <TextInput
               name="contactNumber"
               label="Contact Number"
               type="text"
               placeholder="Contact No."
               className="text-uppercase"
               formikProps={formikProps}
              />
             </Col>
            </Row>
            <Row>
             <Col>
              <TextInput
               name="email"
               label="Email"
               type="email"
               formikProps={formikProps}
              />
             </Col>
             <Col>
              <TextInput
               name="occupation"
               label="Occupation"
               type="text"
               className="text-uppercase"
               formikProps={formikProps}
              />
             </Col>
            </Row>
            <Row>
             <Col>
              <TextInputGroup
               name="height"
               label="Height"
               type="text"
               formikProps={formikProps}
               append={<InputGroup.Text>ft</InputGroup.Text>}
              />
             </Col>
             <Col>
              <TextInputGroup
               name="weight"
               label="Weight"
               type="number"
               formikProps={formikProps}
               append={<InputGroup.Text>kg</InputGroup.Text>}
              />
             </Col>
             <Col>
              <TextInput
               name="birthDate"
               label="Date Of Birth"
               type="date"
               formikProps={formikProps}
               afterOnChange={handleBirthDateChange}
              />
             </Col>
             <Col>
              <TextInput
               name="age"
               label="Age"
               type="number"
               readOnly={true}
               formikProps={formikProps}
              />
             </Col>
            </Row>
            <Row>
             <Col>
              <SelectInput
               name="idType"
               label="Select Id Type"
               options={idTypeOptions}
               formikProps={formikProps}
              />
             </Col>
             <Col>
              <TextInput
               name="idNumber"
               label="ID Number"
               type="text"
               className="text-uppercase"
               formikProps={formikProps}
              />
             </Col>
            </Row>
            <Row>
             <Col>
              <SelectInput
               name="gender"
               label="Gender"
               formikProps={{
                ...formikProps,
                handleChange: (e: any) => {
                 genderChange(e.target.value);
                },
               }}
               options={genderOptions}
              />
             </Col>
             <Col>
              <SelectInput
               name="withHMO"
               label="With HMO?"
               formikProps={formikProps}
               options={boolOptions}
              />
             </Col>
            </Row>
            <Row>
             <Col>
              <DoctorSelect
               name="doctorId"
               label="Doctor"
               placeholder="Select doctor"
               formikProps={formikProps}
              />
             </Col>
             <Col>
              <SelectInput
               name="patientType"
               label="Patient Type"
               formikProps={formikProps}
               options={patientTypeOptions}
              />
             </Col>
            </Row>
            <Button
             className="float-right"
             size="lg"
             variant="primary"
             type="submit"
             disabled={formikProps.isSubmitting || !formikProps.isValid}
            >
             {formikProps.isSubmitting && (
              <Spinner
               as="span"
               animation="border"
               size="sm"
               role="status"
               aria-hidden="true"
               className="mr-2"
              />
             )}
             Submit
            </Button>
           </Col>
          </Row>
         </Form>
        );
       }}
      </Formik>
     )}
    </Card.Body>
   </Card>
  </Container>
 );
};

export default observer(FormPatient);
