import { format } from "date-fns";
import { IDoctorOption } from "./doctor";
import { IPagination } from "./pagination";
import { IPatientOption } from "./patient";
import { PurposeOfStorage } from "./types";

export interface ISpermFreezing {
  id?: number;
  storageCode: string;
  storageCodeYear: number;
  storageDate: Date | string;
  patientId: number;
  patient?: IPatientOption;
  partnerId: number;
  partner?: IPatientOption;
  method: string;
  abstinence: number;
  purposeOfStorage: PurposeOfStorage;
  others: string;
  physicianId: number;
  physician?: IDoctorOption;
  appearance: string;
  volume: number;
  density: number;
  totalMotility: number;
  morphology: string;
  roundCell: string;
  numberOfCryotubes: number;
  preFreezeVolume: number;
  dewarLocation: number;
  storagePayments: IStoragePayment[];
}

export interface IStoragePayment {
  id?: number;
  date: Date | string;
  amount: number;
  nextDate: Date | string;
  remarks: string;
}

export interface ISpermFreezingData {
  id: number;
  storageCode: string;
  storageDate: Date | string;
}

export interface ISpermFreezingPagination extends IPagination {
  data: ISpermFreezingData[];
}

export class SpermFreezingFormValues implements ISpermFreezing {
  storageCode = "";
  storageCodeYear = new Date().getFullYear();
  storageDate = format(new Date(), "yyyy-MM-dd");
  patientId = 0;
  partnerId = 0;
  method = "Masturbation";
  abstinence = 0;
  purposeOfStorage = "IVF";
  others = "";
  physicianId = 0;
  appearance = "";
  volume = 0;
  density = 0;
  totalMotility = 0;
  morphology = "";
  roundCell = "";
  numberOfCryotubes = 0;
  preFreezeVolume = 0;
  dewarLocation = 0;
  storagePayments = [] as IStoragePayment[];
  constructor(init?: ISpermFreezing) {
    Object.assign(this, init);
    if (init && init.storageDate) {
      this.storageDate = format(new Date(init.storageDate), "yyyy-MM-dd");
    }
    if (init && init.storagePayments) {
      this.storagePayments = this.storagePayments.map((item) => {
        item.date = format(new Date(item.date), "yyyy-MM-dd");
        item.nextDate = format(new Date(item.nextDate), "yyyy-MM-dd");
        return item;
      });
    }
  }
}
