import { FormikProps } from "formik";
import React from "react";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";

interface IProps {
 formikProps: FormikProps<any>;
}

const ICSIDay1Table: React.FC<IProps> = ({ formikProps }) => {
 const { icsiDetails } = formikProps.values;

 const editableColumns = [
  {
   title: "EGG",
   field: "day1Egg",
   headerSort: false,
   width: 50,
  },
  {
   title: "PN",
   field: "day1PN",
   editor: "input",
   headerSort: false,
  },
  {
   title: "PB",
   field: "day1PB",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Remarks",
   field: "day1Remarks",
   editor: "input",
   headerSort: false,
   width: 150,
  },
 ];

 return (
  <ReactTabulator
   className="table-sm mb-3 table-bordered table-responsive text-uppercase"
   columns={editableColumns}
   data={icsiDetails}
  />
 );
};

export default ICSIDay1Table;
