import React from "react";
import Spinner from "react-bootstrap/esm/Spinner";

const FormSpinner = () => {
  return (
    <div className="text-center">
      <Spinner variant="primary" animation="border" />
    </div>
  );
};

export default FormSpinner;
