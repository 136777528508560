import { OptionHTMLAttributes } from "react";

export const transferOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "Easy",
    value: "easy",
  },
  {
    label: "Difficult",
    value: "difficult",
  },
  {
    label: "Pain",
    value: "pain",
  },
];