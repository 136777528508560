import { format } from "date-fns/esm";
import { FormikProps } from "formik";
import React from "react";
import Button from "react-bootstrap/esm/Button";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import { IEmbryoTransferPlaceOfDelivery } from "../../app/models/embryoTransfers";


interface IProps {
  formikProps: FormikProps<any>;
}

const EmbryoTransferPlaceOfDelivery: React.FC<IProps> = ({ formikProps }) => {
  const { placeOfDeliveries } = formikProps.values;

  const editableColumns = [
   
    {
      title: "Date",
      field: "date",
      editor: DateEditor,
      editorParams: { format: "YYYY-MM-dd" },
      headerSort: false,
    },
    {
      title: "Gestation",
      field: "gestation",
      editor: "input",
     
      headerSort: false,
      cssClass: "text-center",
    },
    {
      title: "Weight",
      field: "weight",
      editor: "number",
     
      headerSort: false,
      cssClass: "text-center",
    },
    {
          field: "gender",
          title: "Sex",
          headerSort: false,
          editor: "select",
          editorParams: {
            Male: "Male",
            Female: "Female",
          },
          cssClass: "text-center",
        },
    {
      title: "Cong. Abnormality",
      field: "congAbnormality",
      editor: "input",
      headerSort: false,
      cssClass: "text-center",
    },
  ];

  const handleAddRow = () => {
    const data = [...placeOfDeliveries];
    const newRow: IEmbryoTransferPlaceOfDelivery = {
      id: 0,
      date: format(new Date(), "yyyy-MM-dd"),
      gestation: "",
      weight: 0,
      gender: "Male",
      congAbnormality: "",
    };
    data.push(newRow);
    formikProps.setFieldValue("placeOfDeliveries", data);
  };

  
  const handleRemoveRow = () => {
    const data = [...placeOfDeliveries];
    data.pop();
    formikProps.setFieldValue("placeOfDeliveries", data);
  };
  return (
    <>
      
      <div className="mb-3">
        <Button className="mr-2" onClick={handleAddRow} >
          Add
        </Button>
        <Button
        onClick={handleRemoveRow} disabled={placeOfDeliveries.length === 0}
        >
          Remove
        </Button>
      </div>
      <ReactTabulator
        className="table-sm mb-3 table-bordered"
        columns={editableColumns}
        data={formikProps.values.placeOfDeliveries}
      />
    </>
  );
}


export default EmbryoTransferPlaceOfDelivery;
