import { RouteProps } from "react-router-dom";
import FormForgotPassword from "../../components/Account/FormForgotPassword";
import FormLogin from "../../components/Account/FormLogin";
import FormResetPassword from "../../components/Account/FormResetPassword";

const publicRoutes: RouteProps[] = [
  {
    path: "/reset-password/:code",
    component: FormResetPassword,
  },
  {
    path: "/forgot-password",
    component: FormForgotPassword,
  },
  {
    path: "/login",
    component: FormLogin,
  },
];

export default publicRoutes;
