import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomTable from "../../app/common/table/CustomTable";
import { IIVFData } from "../../app/models/ivf";
import { RootStoreContext } from "../../app/stores/rootStore";

const IVFsList = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getIVFs,
    ivfsList,
    total,
    pageSize,
    pageIndex,
    keywords,
  } = rootStore.ivfStore;

  useEffect(() => {
    getIVFs();
  }, [getIVFs]);

  const columns: IDataTableColumn[] = [
    {
      name: "Id",
      selector: "id",
    },
    {
      name: "ART Number",
      cell: (row: IIVFData) => `${row.artNumber}-${row.artNumberYear}`,
    },
    {
      name: "Procedure Date",
      selector: "procedureDate",
      format: (row) => format(new Date(row.procedureDate), "yyyy-MM-dd"),
    },
    {
      name: "",
      button: true,
      cell: (row: IIVFData) => (
        <Button as={Link} to={`/ivfs/edit/${row.id}`} variant="outline-primary">
          Edit
        </Button>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    getIVFs(newPage, pageSize, keywords);
  };

  const handlePerRowsChange = (newPageSize: number, page: number) => {
    getIVFs(page, newPageSize, keywords);
  };

  const handleSearch = (keywords: string) => {
    getIVFs(pageIndex, pageSize, keywords);
  };

  return (
    <Container>
      <CustomTable
        title="IVF"
        columns={columns}
        data={ivfsList}
        paginationTotalRows={total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSearch={handleSearch}
        rightComponent={
          <>
            <Button as={Link} to="/ivfs/add">
              Add IVF
            </Button>
          </>
        }
      />
    </Container>
  );
};

export default observer(IVFsList);
