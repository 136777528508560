import { OptionHTMLAttributes } from "react";

export const embOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "GEPTE",
    value: "GEPTE",
  },
  {
    label: "AMBOLODTO",
    value: "AMBOLODTO",
  },
];
