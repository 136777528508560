import { IPatientOption, IPatientPagination } from "./../models/patient";
import { IPatient } from "../models/patient";
import requests from "./httpService";
import { PatientType } from "../models/types";

const api = "/patients";

const Patients = {
  get: (
    pageIndex: number,
    pageSize: number,
    search?: string
  ): Promise<IPatientPagination> =>
    requests.get(
      `${api}?pageIndex=${pageIndex}&pageSize=${pageSize}${
        search ? `&search=${search}` : ``
      }`
    ),
  getOptions: (keywords: string, patientType?: PatientType): Promise<IPatientOption[]> =>
    requests.get(`${api}/options?search=${keywords}&patientType=${patientType}`),
  getById: (id: number): Promise<IPatient> => requests.get(`${api}/${id}`),
  create: (values: IPatient): Promise<IPatient> =>
    requests.post(`${api}`, values),
  update: (values: IPatient): Promise<IPatient> =>
    requests.put(`${api}/${values.id}`, values),
};

export default Patients;
