import { observable, action, runInAction } from "mobx";
import { IDoctor, IDoctorData } from "../models/doctor";
import Doctors from "../services/doctorService";
import { RootStore } from "./rootStore";
import { history } from "../..";

export default class DoctorStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable doctorsList: IDoctorData[] = [];
  @observable selectedDoctor: IDoctor | undefined = undefined;
  @observable pageIndex: number = 1;
  @observable pageSize: number = 10;
  @observable total: number = 0;
  @observable keywords: string | undefined = undefined;

  @action createDoctor = async (values: IDoctor) => {
    this.rootStore.commonStore.isOverlayLoading = true;
    try {
      await Doctors.create(values);
      history.push("/doctors");
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isOverlayLoading = false;
      });
    }
  };

  @action getDoctors = async (
    pageIndex: number = 1,
    pageSize: number = 10,
    search?: string
  ) => {
    this.doctorsList = [];
    this.rootStore.commonStore.isDataLoading = true;
    try {
      
      const response = await Doctors.getDoctors(pageIndex, pageSize, search);
      runInAction(() => {
        this.pageIndex = response.pageIndex;
        this.pageSize = response.pageSize;
        this.total = response.count;
        this.doctorsList = response.data;
        this.keywords = search;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isDataLoading = false;
      });
    }
  };

  @action getDoctorById = async (id: number) => {
    // this.rootStore.commonStore.isOverlayLoading = true;
    try {
      const doctor = await Doctors.getById(id);
      runInAction(() => {
        this.selectedDoctor = doctor;
      });
      return doctor;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        // this.rootStore.commonStore.isOverlayLoading = false;
      });
    }
  };

  @action updateDoctor = async (values: IDoctor) => {
    this.rootStore.commonStore.isOverlayLoading = true;
    try {
      await Doctors.update(values);
      history.push("/doctors");
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isOverlayLoading = false;
      });
    }
  };
}
