import { IIVF } from "./../models/ivf";
import requests from "./httpService";
import { IIVFPagination } from "../models/ivf";

const api = "/invitrofertilizations";

const IVFs = {
  get: (
    pageIndex: number,
    pageSize: number,
    search?: string
  ): Promise<IIVFPagination> =>
    requests.get(
      `${api}?pageIndex=${pageIndex}&pageSize=${pageSize}${
        search ? `&search=${search}` : ``
      }`
    ),
  getById: (id: number): Promise<IIVF> =>
    requests.get(`${api}/${id}`),
  
  create: (values: IIVF): Promise<IIVF> =>
    requests.post(`${api}`, values),
  update: (values: IIVF): Promise<IIVF> =>
    requests.put(`${api}/${values.id}`, values),

 
};

export default IVFs;