import React from "react";
import SpermFreezingsList from "../SpermFreezing/SpermFreezingsList";

const FormSpermCryopreservationReportIndex = () => {
    return (
        <>
            <SpermFreezingsList printButton={true}/>
        </>
    );
};

export default FormSpermCryopreservationReportIndex;