import { OptionHTMLAttributes } from "react";

export const denPipetteOptions: OptionHTMLAttributes<HTMLOptionElement>[] =
 [
  {
   label: "R1",
   value: "R1",
  },
  {
   label: "E2",
   value: "E2",
  },
  {
   label: "TIP",
   value: "TIP",
  },
  {
   label: "DENUPET",
   value: "DENUPET",
  },
  {
   label: "OTHERS",
   value: "OTHERS",
  },
 ];
