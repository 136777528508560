import { RouteProps } from "react-router-dom";
import FormSpermFreezing from "../../../components/SpermFreezing/FormSpermFreezing";
import SpermFreezingsList from "../../../components/SpermFreezing/SpermFreezingsList";

const spermFreezingRoutes: RouteProps[] = [
  {
    path: "/sperm-freezings/edit/:id",
    component: FormSpermFreezing,
  },
  {
    path: "/sperm-freezings/add",
    component: FormSpermFreezing,
  },
  {
    path: "/sperm-freezings",
    component: SpermFreezingsList,
  },
];

export default spermFreezingRoutes;
