import { FormikProps } from "formik";
import React from "react";
import Form from "react-bootstrap/esm/Form";

interface IProps {
  formikProps: FormikProps<any>;
  label?: string;
  name: string;
}

const CheckInput: React.FC<IProps> = ({ formikProps, label = "", name }) => {
  return (
    <>
      <Form.Group controlId={name}>
        <Form.Check
          type="checkbox"
          label={label}
          name={name}
          value={formikProps.values[name]}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          checked={formikProps.values[name]}
        />
        {formikProps.errors[name] && (
          <Form.Control.Feedback className="d-block" type="invalid">
            <p className="m-0">{formikProps.errors[name]}</p>
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

export default CheckInput;
