import { IDoctor, IDoctorPagination, IDoctorOption } from "../models/doctor";
import requests from "./httpService";

const api = "/doctors";

const Doctors = {

  getDoctors: (
    pageIndex: number,
    pageSize: number,
    search?: string
  ): Promise<IDoctorPagination> =>
    requests.get(
      `${api}?pageIndex=${pageIndex}&pageSize=${pageSize}${
        search ? `&search=${search}` : ``
      }`
    ),
  getOptions: (keywords: string): Promise<IDoctorOption[]> =>
  requests.get(`${api}/options?search=${keywords}`),
  getById: (id: number): Promise<IDoctor> => requests.get(`${api}/${id}`),
  create: (values: IDoctor): Promise<IDoctor> =>
    requests.post(`${api}`, values),
  update: (values: IDoctor): Promise<IDoctor> =>
    requests.put(`${api}/${values.id}`, values),
};
export default Doctors;
