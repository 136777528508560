import { IPagination } from "./pagination";
import { format } from "date-fns";
import { IPatientOption } from "./patient";
import { IDoctorOption } from "./doctor";

export interface IEFTL {
  id?: number;
  efNumber: string;
  artNumber: string;
  opuDate: Date | string;
  wifeId: number;
  wife?: IPatientOption;
  wifeAge?: number;
  husbandId: number;
  husband?: IPatientOption;
  husbandAge?: number;
  freezingDateTime: Date | string;
  freezingDate: Date | string;
  freezingTime: Date | string;
  protocol: string;
  freezingMedium: string;
  lot: string;
  expiry: string;
  doctorId: number;
  doctor?: IDoctorOption;
  isThawingVisible: boolean;
  eftlDetails: IEFTLDetail[];
  day: number;
  efHours: number;
  gobletColor: string;
  efSummary: string;
  dewarLocation: string;
  embryologist: string;
}

export interface IEFTLDetail {
  id?: number;
  index?: number;
  embryoFreezingAndThawingId?: number;
  cellStage: string;
  emFrag: string;
  emDark: string;
  emUneq: string;
  emGrade: string;
  remarks: string;
  strawNumber: string;
  color: string;
  thawDateTime: Date | string;
  etDateTime: Date | string;
  embQualityPThaw: string;
  embQualityET: string;
  udetSmooth: string;
  udetBlood: string;
  udetMucus: string;
  emb: string;
}

export interface IEFTLData {
  id: number;
  efNumber: number;
  opuDate: Date | string;
  protocol: string;
}

export interface IEFTLPagination extends IPagination {
  data: IEFTLData[];
}

export class EFTLFormValues implements IEFTL {
  efNumber = "";
  artNumber = "";
  opuDate = format(new Date(), "yyyy-MM-dd");
  wifeId = 0;
  husbandId = 0;
  freezingDateTime = "";
  freezingDate = format(new Date(), "yyyy-MM-dd");
  freezingTime = format(new Date(), "HH:mm");
  protocol = "";
  freezingMedium = "";
  lot = "";
  expiry = "";
  doctorId = 0;
  isThawingVisible = true;
  eftlDetails = [] as IEFTLDetail[];
  day = 0;
  efHours = 0;
  gobletColor = "";
  efSummary = "";
  dewarLocation = "";
  embryologist = "";

  constructor(init?: IEFTL) {
    Object.assign(this, init);
    if (init && init.opuDate) {
      this.opuDate = format(new Date(this.opuDate), "yyyy-MM-dd");
    }
    if (init && init.freezingDateTime) {
      this.freezingDate = format(new Date(init.freezingDateTime), "yyyy-MM-dd");
      this.freezingTime = format(new Date(init.freezingDateTime), "HH:mm");
    }
    if (init && init.eftlDetails) {
      this.eftlDetails = init.eftlDetails.map((item, index: number) => {
        item.index = index + 1;
        return item;
      });
    }
  }
}
