import { FormikProps } from "formik";
import React from "react";
import Button from "react-bootstrap/esm/Button";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import { IEmbryoTransferCells } from "../../app/models/embryoTransfers";


interface IProps {
  formikProps: FormikProps<any>;
}

const EmbryoTransferCells: React.FC<IProps> = ({ formikProps }) => {
  const { embryoTransferCells } = formikProps.values;

  const editableColumns = [
    {
      title: "No.",
      field: "index",
      width: 50,
      headerSort: false,
      cssClass: "text-center",
    },
    {
      title: "Cells",
      field: "cells",
      editor: "input",
      width: 80,
      headerSort: false,
      cssClass: "text-center",
    },
    {
      title: "Grade",
      field: "grade",
      editor: "input",
      width: 80,
      headerSort: false,
      cssClass: "text-center",
    },
    {
      title: "Remarks",
      field: "remarks",
      editor: "input",
      headerSort: false,
      cssClass: "text-center",
    },
      {
      title: "Comments",
      field: "comments",
      editor: "input",
      headerSort: false,
      cssClass: "text-center",
    },
  ];

  const handleAddRow = () => {
    const data = [...embryoTransferCells];
    const newRow: IEmbryoTransferCells = {
      id: 0,
      index: data.length + 1,
      cells: "",
      grade: "",
      remarks: "",
      comments: "",
      
    };
    data.push(newRow);
    formikProps.setFieldValue("embryoTransferCells", data);
  };

  
  const handleRemoveRow = () => {
    const data = [...embryoTransferCells];
    data.pop();
    formikProps.setFieldValue("embryoTransferCells", data);
  };
  return (
    <>
      
      <div className="mb-3">
        <Button className="mr-2" onClick={handleAddRow} >
          Add
        </Button>
        <Button
        onClick={handleRemoveRow} disabled={embryoTransferCells.length === 0}
        >
          Remove
        </Button>
      </div>
      <ReactTabulator
        className="table-sm mb-3 table-bordered"
        columns={editableColumns}
        data={formikProps.values.embryoTransferCells}
      />
    </>
  );
}


export default EmbryoTransferCells;
