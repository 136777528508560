import { FormikProps } from "formik";
import React from "react";
import Form from "react-bootstrap/esm/Form";

interface IProps {
  formikProps: FormikProps<any>;
  label?: string;
  name: string;
  placeholder?: string;
  options: any[];
}

const SelectInput: React.FC<IProps> = ({
  formikProps,
  label = "",
  name,
  placeholder = "",
  options,
}) => {
  return (
    <>
      <Form.Group controlId={name}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="select"
          name={name}
          placeholder={placeholder}
          value={formikProps.values[name]}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
        >
          {options.map((option: any, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
        {formikProps.errors[name] && (
          <Form.Control.Feedback className="d-block" type="invalid">
            <p className="m-0">{formikProps.errors[name]}</p>
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

export default SelectInput;
