import { OptionHTMLAttributes } from "react";

export const attemptOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
 
];