import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { withRouter, Route, Switch, RouteProps } from "react-router-dom";
import { RootStoreContext } from "./app/stores/rootStore";
import privateRoutes from "./app/routes/privateRoutes";
import LayoutPublic from "./app/layouts/Public/LayoutPublic";
import publicRoutes from "./app/routes/publicRoutes";
import ErrorNotFound from "./app/layouts/ErrorNotFound";
import reportRoutes from "./app/routes/reportRoutes";
import LayoutReport from "./app/layouts/Report/LayoutReport";
import RouterPrivate from "./app/layouts/Private/RouterPrivate";
import LayoutPrivate from "./app/layouts/Private/LayoutPrivate";
import RouterPublic from "./app/layouts/Public/RouterPublic";

const App: React.FC = () => {
  // app changes
  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getCurrentUser } = rootStore.userStore;

  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    if (token) {
      getCurrentUser().finally(() => setAppLoaded());
    } else {
      setAppLoaded();
    }
  }, [getCurrentUser, setAppLoaded, token]);

  if (!appLoaded)
    return (
      <div className="min-vh-100 d-flex justify-content-center align-items-center">
        <h1>Loading...</h1>
      </div>
    );

  return (
    <Switch>
      {privateRoutes.map((route, key) => {
        const { component, path } = route;
        return (
          <RouterPrivate
            exact
            path={path}
            key={key}
            component={(route) => (
              <LayoutPrivate component={component!} {...route} />
            )}
          />
        );
      })}
      {reportRoutes.map((route: RouteProps, key) => {
        const { component, path } = route;
        return (
          <RouterPrivate
            exact
            path={path}
            key={key}
            component={(route) => (
              <LayoutReport component={component!} {...route} />
            )}
          />
        );
      })}
      {publicRoutes.map((route: RouteProps, key) => {
        const { component, path } = route;
        return (
          <RouterPublic
            exact
            path={path}
            key={key}
            component={(route) => (
              <LayoutPublic component={component!} {...route} />
            )}
          />
        );
      })}

      <Route component={ErrorNotFound} />
    </Switch>
  );
};

export default withRouter(observer(App));
