import { OptionHTMLAttributes } from "react";

export const purposeOfStorageOptions: OptionHTMLAttributes<
  HTMLOptionElement
>[] = [
  {
    label: "IVF",
    value: "IVF",
  },
  {
    label: "IUI",
    value: "IUI",
  },
];
