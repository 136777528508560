import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { IDataTableColumn } from "react-data-table-component";
import CustomTable from "../../app/common/table/CustomTable";
import { ILogData } from "../../app/models/log";
import { RootStoreContext } from "../../app/stores/rootStore";

const UserLogsList = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getUserLogs,
    userLogsList,
    total,
    pageSize,
    pageIndex,
    keywords,
  } = rootStore.logStore;

  useEffect(() => {
    getUserLogs();
  }, [getUserLogs]);

  const columns: IDataTableColumn[] = [
    {
      name: "Id",
      selector: "id",
      width: "50px",
    },
    {
      name: "Description",
      selector: "description",
    },
    {
      name: "Date",
      selector: "createdAt",
      format: (row: ILogData) => format(new Date(row.createdAt), "yyyy-MM-dd"),
    },
  ];

  const handlePageChange = (newPage: number) => {
    getUserLogs(newPage, pageSize, keywords);
  };

  const handlePerRowsChange = (newPageSize: number, page: number) => {
    getUserLogs(page, newPageSize, keywords);
  };

  const handleSearch = (keywords: string) => {
    getUserLogs(pageIndex, pageSize, keywords);
  };

  return (
    <div>
      <CustomTable
        title="Logs"
        columns={columns}
        data={userLogsList}
        paginationTotalRows={total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSearch={handleSearch}
      />
    </div>
  );
};

export default observer(UserLogsList);
