import { RouteProps } from "react-router-dom";
import FormIvf from "../../../components/IVF/FormIvf";
import IVFsList from "../../../components/IVF/IVFsList";


const ivftRoutes: RouteProps[] = [
  {
    path: "/ivfs/edit/:id",
    component: FormIvf,
  },
  {
    path: "/ivfs/add",
    component: FormIvf,
  },
  {
    path: "/ivfs",
    component: IVFsList,
  },
];

export default ivftRoutes;
