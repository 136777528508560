import { format } from "date-fns/esm";
import { FormikProps } from "formik";
import React from "react";
import Button from "react-bootstrap/esm/Button";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import { IEmbryoTransferHCGLevel } from "../../app/models/embryoTransfers";


interface IProps {
  formikProps: FormikProps<any>;
}

const EmbryoTransferHCGLevelTable: React.FC<IProps> = ({ formikProps }) => {
  const { hcgLevels } = formikProps.values;

  const editableColumns = [
   
    {
      title: "Date",
      field: "date",
      editor: DateEditor,
      
      editorParams: { format: "YYYY-MM-dd" },
      headerSort: false,
    },
    {
      title: "hCG Levels",
      field: "hcgLevel",
      editor: "input",
     
      headerSort: false,
      cssClass: "text-center",
    },
  ];

  const handleAddRow = () => {
    const data = [...hcgLevels];
    const newRow: IEmbryoTransferHCGLevel = {
      id: 0,
      date: format(new Date(), "yyyy-MM-dd"),
      hcgLevel: "",
    };
    data.push(newRow);
    formikProps.setFieldValue("hcgLevels", data);
  };

  
  const handleRemoveRow = () => {
    const data = [...hcgLevels];
    data.pop();
    formikProps.setFieldValue("hcgLevels", data);
  };
  return (
    <>
      
      <div className="mb-3">
        <Button className="mr-2" onClick={handleAddRow} >
          Add
        </Button>
        <Button
        onClick={handleRemoveRow} disabled={hcgLevels.length === 0}
        >
          Remove
        </Button>
      </div>
      <ReactTabulator
        className="table-sm mb-3 table-bordered"
        columns={editableColumns}
        data={formikProps.values.hcgLevels}
      />
    </>
  );
}


export default EmbryoTransferHCGLevelTable;
