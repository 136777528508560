import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import Form from "react-bootstrap/esm/Form";
import { RootStoreContext } from "../../stores/rootStore";

interface IProps {
  formikProps: FormikProps<any>;
  label?: string;
  name: string;
  placeholder?: string;
}

const IvfSelect: React.FC<IProps> = ({
  formikProps,
  label = "",
  name,
  placeholder = "",
}) => {
  
  const rootStore = useContext(RootStoreContext);
  const { selectedPatientOption } = rootStore.patientStore;
  
  return (
    <>
      <Form.Group controlId={name}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="select"
          name={name}
          placeholder={placeholder}
          value={formikProps.values[name]}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
        >
          {selectedPatientOption !== undefined && selectedPatientOption.ivfs !== undefined ? (
            <>
              {selectedPatientOption.ivfs.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.artNumber}-{option.artNumberYear}
                </option>
              ))}
            </>
          ) : null}
        </Form.Control>
        {formikProps.errors[name] && (
          <Form.Control.Feedback className="d-block" type="invalid">
            <p className="m-0">{formikProps.errors[name]}</p>
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

export default observer(IvfSelect);
