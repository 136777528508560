import { OptionHTMLAttributes } from "react";

export const genderOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
];
