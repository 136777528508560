import React, { useContext } from "react";
import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/esm/Navbar";
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../stores/rootStore";
import { FiLogOut, FiList, FiPlus, FiLock } from "react-icons/fi";

const PrivateLayoutHeader = () => {
  const rootStore = useContext(RootStoreContext);
  const { logout } = rootStore.authStore;
  const { user } = rootStore.userStore;

  return (
    <Navbar bg="primary" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to="/">
          IVF Davao
        </Navbar.Brand>
        <Nav className="mr-auto">
          <NavDropdown title="Users" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/users">
              <FiList />
              View Users
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/users/add">
              <FiPlus /> Add User
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Doctors" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/doctors">
              <FiList />
              View Doctors
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/doctors/add">
              <FiPlus />
              Add Doctor
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Patients" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/patients">
              <FiList />
              View Patients
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/patients/add">
              <FiPlus />
              Add Patient
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="IVF" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/ivfs">
              <FiList />
              View IVF
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/ivfs/add">
              <FiPlus />
              New IVF
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="ICSI" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/icsis">
              <FiList />
              View ICSI
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/icsis/add">
              <FiPlus />
              New ICSI
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="EFTL" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/embryo-freezing-and-thawing">
              <FiList />
              View EFTLs
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/embryo-freezing-and-thawing/add">
              <FiPlus />
              New EFTLs
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Embryo Transfers" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/embryo-transfers">
              <FiList />
              View Embryo Transfers
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/embryo-transfers/add">
              <FiPlus />
              New Embryo Transfers
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Sperm Freezings" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/sperm-freezings">
              <FiList />
              View Sperm Freezings
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/sperm-freezings/add">
              <FiPlus />
              Add Sperm Freezing
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Reports" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/reports/embryo-freezing-report">
              <FiList />
              Embryo Freezing Report
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/reports/sperm-cryopreservation">
              <FiList />
              Sperm Cryopreservation Report
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/reports/embryology-summary-report">
              <FiList />
              Embryology Summary Report
            </NavDropdown.Item>
            {/* <NavDropdown.Item as={Link} to="/temp-links/thawedcyclereport">
              Thawed Cycle Report
            </NavDropdown.Item> */}
          </NavDropdown>
        </Nav>
        <Nav>
          <NavDropdown
            title={`${user?.userName}@ivf.com`}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item as={Link} to="/account/update-password">
              <FiLock />
              Update Password
            </NavDropdown.Item>
            <NavDropdown.Item onClick={logout}>
              <FiLogOut />
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default PrivateLayoutHeader;
