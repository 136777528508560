import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import ScrollToTop from "./app/layouts/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "bootswatch/dist/pulse/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css";
import "../src/app/assets/style/report.css"
import { IconContext } from "react-icons/lib/esm/iconContext";

export const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <ScrollToTop>
      <IconContext.Provider
        value={{
          style: {
            // verticalAlign: "baseline",
            fontSize: "1.1rem",
            marginRight: "0.5rem",
            marginBottom: "0.2rem",
          },
        }}
      >
        <ToastContainer />
        <App />
      </IconContext.Provider>
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
