import { OptionHTMLAttributes } from "react";

export const boolOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "No",
    value: "false",
  },
  {
    label: "Yes",
    value: "true",
  },
];
