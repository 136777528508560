import { IPagination } from "./pagination";
import { Gender, IdType, PatientType } from "./types";
import { format } from "date-fns";
import { IDoctorOption } from "./doctor";
import { IIVFOption } from "./ivf";

export interface IPatient {
 id?: number;
 firstName: string;
 middleName: string;
 lastName: string;
 address: string;
 contactNumber: string;
 email: string;
 occupation: string;
 height: string;
 weight: number;
 birthDate: Date | string;
 age: number;
 idType: IdType;
 idNumber: string;
 gender: Gender;
 doctorId: number;
 doctor?: IDoctorOption;
 withHMO: boolean;
 imageUrl: string;
 patientType: PatientType;
}

export interface IPatientData {
 id: number;
 firstName: string;
 lastName: string;
 contactNumber: string;
 address: string;
}

export interface IPatientOption {
 id: number;
 firstName: string;
 lastName: string;
 age: number;
 ivfs: IIVFOption[];
}

export interface IPatientPagination extends IPagination {
 data: IPatientData[];
}

export class PatientFormValues implements IPatient {
 firstName = "";
 middleName = "";
 lastName = "";
 address = "";
 contactNumber = "";
 email = "";
 occupation = "";
 height = "";
 weight = 0;
 birthDate = format(new Date(), "yyyy-MM-dd");
 age = 0;
 idType = "None";
 idNumber = "N/A";
 gender = "Male";
 doctorId = 0;
 withHMO = true;
 imageUrl = "";
 patientType = "Husband";
 constructor(init?: IPatient) {
  Object.assign(this, init);
  if (init && init.birthDate) {
   this.birthDate = format(new Date(init.birthDate), "yyyy-MM-dd");
  }
 }
}
