import { history } from "./../../index";
import { observable, action, runInAction } from "mobx";
import { ILoginForm, IResetPasswordForm } from "../models/auth";
import { RootStore } from "./rootStore";
import Auth from "../services/authService";
import { toast } from "react-toastify";

export default class AuthStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable authLoading = false;

  @action login = async (values: ILoginForm) => {
    this.authLoading = true;
    try {
      const user = await Auth.login(values);
      runInAction(() => {
        this.rootStore.userStore.user = user;
        this.rootStore.commonStore.setToken(user.token);
      });
      history.push("/");
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.authLoading = false;
      });
    }
  };

  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
    this.rootStore.userStore.user = null;
    history.push("/login");
  };

  @action forgotPassword = async (email: string) => {
    this.authLoading = true;
    try {
      await Auth.forgotPassword(email);
      toast.success("Password reset link has been sent to your email.");
      history.push("/login");
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.authLoading = false;
      });
    }
  };

  @action resetPassword = async (values: IResetPasswordForm) => {
    this.authLoading = true;
    try {
      await Auth.resetPassword(values);
      toast.success("Password updated successfully.");
      history.push("/login");
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.authLoading = false;
      });
    }
  };
}
