import { OptionHTMLAttributes } from "react";

export const incubatorOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "ISSYSTEM",
    value: "ISSYSTEM",
  },
  {
    label: "Others",
    value: "Others",
  },
];
