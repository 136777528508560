import { OptionHTMLAttributes } from "react";

export const treatmentOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "IVF",
    value: "ivf",
  },
  {
    label: "GIFT",
    value: "gift",
  },
  {
    label: "ICSI",
    value: "icsi",
  },
  {
    label: "FET",
    value: "fet",
  },
  {
    label: "AH",
    value: "ah",
  },
  {
    label: "DI",
    value: "di",
  },
  {
    label: "OD",
    value: "od",
  },
  {
    label: "ED",
    value: "ed",
  },
  {
    label: "SpAsp",
    value: "spasp",
  },
];