import { OptionHTMLAttributes } from "react";

export const idTypeOptions: OptionHTMLAttributes<
  HTMLOptionElement
>[] = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "Passport",
    value: "Passport",
  },
  {
    label: "Drivers License",
    value: "DriversLicense",
  },
  {
    label: "SSS",
    value: "SssUmidCard",
  },
  {
    label: "PhilHealth",
    value: "PhilHealthId",
  },
  {
    label: "TIN",
    value: "TinCard",
  },
   {
    label: "Postal Id",
    value: "PostalId",
  },
   {
    label: "Voters Id",
    value: "VotersId",
  },
  
  

];
