import { IEmbryoTransfers } from "./../models/embryoTransfers";
import requests from "./httpService";
import { IEmbryoTransfersPagination } from "../models/embryoTransfers";

const api = "/embryo-transfers";

const EmbryoTransfers = {
  get: (
    pageIndex: number,
    pageSize: number,
    search?: string
  ): Promise<IEmbryoTransfersPagination> =>
    requests.get(
      `${api}?pageIndex=${pageIndex}&pageSize=${pageSize}${
        search ? `&search=${search}` : ``
      }`
    ),

  getById: (id: number): Promise<IEmbryoTransfers> =>
    requests.get(`${api}/${id}`),
  create: (values: IEmbryoTransfers): Promise<IEmbryoTransfers> =>
    requests.post(`${api}`, values),
  update: (values: IEmbryoTransfers): Promise<IEmbryoTransfers> =>
    requests.put(`${api}/${values.id}`, values),
};

export default EmbryoTransfers;

