import React from "react";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const FileInput: React.FC<IProps> = (props) => {
  return (
    <div className="input-group">
      <div className="custom-file">
        <input type="file" className="custom-file-input" {...props} />
        <label className="custom-file-label" htmlFor={props.id}>
          {props.label}
        </label>
      </div>
    </div>
  );
};

export default FileInput;
