import requests from "./httpService";
import { ILogPagination } from "../models/log";

const api = "/logs";

const Logs = {
  getUserLogs: (
    pageIndex: number,
    pageSize: number,
    search?: string
  ): Promise<ILogPagination> =>
    requests.get(
      `${api}/users?pageIndex=${pageIndex}&pageSize=${pageSize}${
        search ? `&search=${search}` : ``
      }`
    ),
};

export default Logs;
