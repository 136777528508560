import React from "react";
import EFTLsList from "../EFTL/EFTLsList";

const FormEmbryoFreezingReportIndex = () => {
    return (
        <>
            <EFTLsList printButton={true}/>
        </>
    );
};

export default FormEmbryoFreezingReportIndex;