import { RouteProps } from "react-router-dom";
import FormEmbryoFreezingReportIndex from "../../../components/EmbryoFreezingReport/FormEmbryoFreezingReportIndex";

const embryoFreezingReportRoutes: RouteProps[] = [
  {
    path: "/reports/embryo-freezing-report",
    component: FormEmbryoFreezingReportIndex,
  },
];

export default embryoFreezingReportRoutes;
