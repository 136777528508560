import { IICSI } from "./../models/icsi";
import requests from "./httpService";
import { IICSIPagination } from "../models/icsi";

const api = "/icsis";

const ICSIs = {
  get: (
    pageIndex: number,
    pageSize: number,
    search?: string
  ): Promise<IICSIPagination> =>
    requests.get(
      `${api}?pageIndex=${pageIndex}&pageSize=${pageSize}${
        search ? `&search=${search}` : ``
      }`
    ),
  getById: (id: number): Promise<IICSI> =>
    requests.get(`${api}/${id}`),
  create: (values: IICSI): Promise<IICSI> =>
    requests.post(`${api}`, values),
  update: (values: IICSI): Promise<IICSI> =>
    requests.put(`${api}/${values.id}`, values),

 
};

export default ICSIs;