import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import DataTable, { IDataTableProps } from "react-data-table-component";
import { RootStoreContext } from "../../stores/rootStore";
import SearchInput from "./SearchInput";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

interface IProps extends IDataTableProps {
  rightComponent?: React.ReactNode | React.ReactNode[];
  onSearch: (keywords: string) => void;
}

const CustomTable: React.FC<IProps> = (props) => {
  const rootStore = useContext(RootStoreContext);
  const { isDataLoading } = rootStore.commonStore;

  return (
    <>
      <h1>{props.title}</h1>
      <Row className="my-3">
        <Col>
          <SearchInput handleSearch={props.onSearch} />
        </Col>
        <Col>
          <div className="float-right">{props.rightComponent}</div>
        </Col>
      </Row>
      <DataTable
        {...props}
        noHeader
        pagination
        paginationServer
        highlightOnHover={true}
        progressPending={isDataLoading}
        progressComponent={<Spinner variant="primary" animation="border" />}
      />
    </>
  );
};

export default observer(CustomTable);
