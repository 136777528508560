import React, { useCallback, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";

const pixelRatio = window.devicePixelRatio || 1;

interface IProps {
  selectedImage: any;
  previewCanvasRef: any;
  crop: any;
  setCrop: any;
  completedCrop: any;
  setCompletedCrop: any;
}

const ImageCropper: React.FC<IProps> = ({
  selectedImage,
  previewCanvasRef,
  crop,
  setCrop,
  completedCrop,
  setCompletedCrop,
}) => {
  const imgRef = useRef<any>(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop, previewCanvasRef]);

  return (
    <ReactCrop
      src={selectedImage}
      crop={crop}
      onImageLoaded={onLoad}
      onChange={(c) => setCrop(c)}
      onComplete={(c) => setCompletedCrop(c)}
    />
  );
};

export default ImageCropper;
