import { RouteProps } from "react-router-dom";
import DoctorsList from "../../../components/Doctor/DoctorsList";
import FormDoctor from "../../../components/Doctor/FormDoctor";

const doctorRoutes: RouteProps[] = [
  {
    path: "/doctors/edit/:id",
    component: FormDoctor,
  },
  {
    path: "/doctors/add",
    component: FormDoctor,
  },
  {
    path: "/doctors",
    component: DoctorsList,
  },
];

export default doctorRoutes;
