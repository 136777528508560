import { format } from "date-fns";
import { IPagination } from "./pagination";
import { IPatient } from "./patient";
import {
  Source,
  SpermSource,
  PreparationProcedure,
  IVFProgression,
} from "./types";
import { IDoctorOption } from "./doctor";

export interface IIVF {
  id?: number;
  artNumber: string;
  artNumberYear: number;
  procedureDate: Date | string;
  attempt: number;
  stimulationRegimen: string;
  doctorId: number;
  doctor?: IDoctorOption;
  follicle: string;
  wifeId: number;
  wife?: IPatient;
  wifeDiagnosis: string;
  oocyte: Source;
  wifeRemarks: string;
  wifeDonorName: string;
  husbandId: number;
  husband?: IPatient;
  husbandDiagnosis: string;
  sperm: Source;
  husbandRemarks: string;
  husbandDonorName: string;
  opuDishPreparationTime: Date | string;
  pxDataCheckedBy: string;
  hcg: string;
  gravida: number;
  para: number;
  previousIVF: string;
  isScreeningTestDone: boolean;
  spermPreparationDate: Date | string;
  prevVolume: string;
  prevDensity: string;
  prevMotility: string;
  prevMorphology: string;
  timeReceived: Date | string;
  timeExamined: Date | string;
  daysOfAbstinence: number;
  preparationProcedure: PreparationProcedure;
  otherProcedure: string;
  spermSource: SpermSource;
  volume: string;
  liquefaction: string;
  density: string;
  motility: string;
  progression: IVFProgression;
  roundCell: string;
  morphology: string;
  agglutination: string;
  debris: string;
  finalVolume: string;
  finalDensity: string;
  finalMotility: string;
  doneBy: string;
  finalPreparationRemarks: string;
  ivfRemarks: string;
}

export interface IIVFOption {
  id: number;
  artNumber: string;
  artNumberYear: number;
  procedureDate: Date | string;
  density: string;
  morphology: string;
  motility: string;
}

export interface IIVFData {
  id: number;
  artNumber: string;
  artNumberYear: number;
  procedureDate: Date | string;
}

export interface IIVFPagination extends IPagination {
  data: IIVFData[];
}

export class IVFFormValues implements IIVF {
  artNumber = "";
  artNumberYear = new Date().getFullYear();
  procedureDate = format(new Date(), "yyyy-MM-dd");
  attempt = 0;
  stimulationRegimen = "";
  doctorId = 0;
  follicle = "";
  wifeId = 0;
  wifeDiagnosis = "";
  oocyte = "Patient";
  wifeRemarks = "";
  wifeDonorName = "";
  husbandId = 0;
  husbandDiagnosis = "";
  sperm = "Patient";
  husbandRemarks = "";
  husbandDonorName = "";
  opuDishPreparationTime = format(new Date(), "HH:mm");
  pxDataCheckedBy = "";
  hcg = "";
  gravida = 0;
  para = 0;
  previousIVF = "";
  isScreeningTestDone = true;
  spermPreparationDate = format(new Date(), "yyyy-MM-dd");
  prevVolume = "";
  prevDensity = "";
  prevMotility = "";
  prevMorphology = "";
  timeReceived = format(new Date(), "HH:mm");
  timeExamined = format(new Date(), "HH:mm");
  daysOfAbstinence = 0;
  preparationProcedure = "SwimUp";
  otherProcedure = "";
  spermSource = "Ejaculate";
  volume = "";
  liquefaction = "";
  density = "";
  motility = "";
  progression = "Progressive";
  roundCell = "";
  morphology = "";
  agglutination = "";
  debris = "";
  finalVolume = "";
  finalDensity = "";
  finalMotility = "";
  doneBy = "";
  finalPreparationRemarks = "";
  ivfRemarks = "";

  constructor(init?: IIVF) {
    Object.assign(this, init);
    
    if (init && init.procedureDate) {
      this.procedureDate = format(new Date(init.procedureDate), "yyyy-MM-dd");
    }
    if (init && init.opuDishPreparationTime) {
      this.opuDishPreparationTime = format(
        new Date(init.opuDishPreparationTime),
        "HH:mm:ss"
      );
    }
    if (init && init.spermPreparationDate) {
      this.spermPreparationDate = format(
        new Date(init.spermPreparationDate),
        "yyyy-MM-dd"
      );
    }
    if (init && init.timeReceived) {
      this.timeReceived = format(new Date(init.timeReceived), "HH:mm");
    }
    if (init && init.timeExamined) {
      this.timeExamined = format(new Date(init.timeExamined), "HH:mm");
    }
  }
}
