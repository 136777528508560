import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import * as Yup from "yup";
import { DoctorFormValues, IDoctor } from "../../app/models/doctor";
import { Form, Formik } from "formik";
import TextInput from "../../app/common/forms/TextInput";
import Spinner from "react-bootstrap/esm/Spinner";
import { RootStoreContext } from "../../app/stores/rootStore";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";
import CheckInput from "../../app/common/forms/CheckInput";
import FormSpinner from "../../app/common/forms/FormSpinner";
import Container from "react-bootstrap/esm/Container";

interface IParams {
  id: string;
}

const validationSchema = Yup.object<IDoctor>().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
});

const FormDoctor: React.FC<RouteComponentProps<IParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const rootStore = useContext(RootStoreContext);
  const { createDoctor, updateDoctor, getDoctorById } = rootStore.doctorStore;
  const [doctor, setDoctor] = useState(new DoctorFormValues());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getDoctorById(+id)
        .then((doctor) => {
          setDoctor(new DoctorFormValues(doctor));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [getDoctorById, id]);

  return (
    <Container>
      <Col className="col-lg-4 offset-lg-4 my-5">
        <Card>
          <Card.Body>
            <h2 className="text-center mb-5">{id ? "Update" : "Add"} Doctor</h2>
            {isLoading ? (
              <FormSpinner />
            ) : (
              <Formik
                validationSchema={validationSchema}
                onSubmit={(values: IDoctor) => {
                  if (id) {
                    updateDoctor(values);
                  } else {
                    createDoctor(values);
                  }
                }}
                initialValues={doctor}
                enableReinitialize={true}
              >
                {(formikProps) => (
                  <Form>
                    <TextInput
                      name="firstName"
                      label="First Name"
                      type="text"
                      className="text-uppercase"
                      formikProps={formikProps}
                    />
                    <TextInput
                      name="middleName"
                      label="Middle Name"
                      type="text"
                      className="text-uppercase"
                      formikProps={formikProps}
                    />
                    <TextInput
                      name="lastName"
                      label="Last Name"
                      type="text"
                      className="text-uppercase"
                      formikProps={formikProps}
                    />
                    {id && (
                      <CheckInput
                        name="isActive"
                        label="Is Active?"
                        formikProps={formikProps}
                      />
                    )}
                    <Button
                      variant="primary"
                      type="submit"
                      block
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    >
                      {formikProps.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mr-2"
                        />
                      )}
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Container>
  );
};

export default observer(FormDoctor);
