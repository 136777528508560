import React, { useContext } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import * as Yup from "yup";
import { IUser } from "../../app/models/user";
import { Form, Formik } from "formik";
import TextInput from "../../app/common/forms/TextInput";
import Spinner from "react-bootstrap/esm/Spinner";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import Alert from "react-bootstrap/esm/Alert";
import Container from "react-bootstrap/esm/Container";

const validationSchema = Yup.object<IUser>().shape({
 userName: Yup.string().required("Username is required"),
 email: Yup.string().required("Email is required"),
 firstName: Yup.string().required("First name is required"),
 lastName: Yup.string().required("Last name is required"),
});

const FormUser = () => {
 const rootStore = useContext(RootStoreContext);
 const { createUser } = rootStore.userStore;

 return (
  <Container>
   <Col className="col-lg-4 offset-lg-4 my-5">
    <Card>
     <Card.Body>
      <h2 className="text-center mb-5">Add User</h2>
      <Formik
       validationSchema={validationSchema}
       onSubmit={(values: IUser, { setErrors, setSubmitting }) => {
        createUser(values).catch((error) => {
         const { data } = error;
         if (data.message) {
          setErrors({ submitError: data.message });
         }
         setSubmitting(false);
        });
       }}
       initialValues={{
        userName: "",
        email: "",
        firstName: "",
        lastName: "",
       }}
      >
       {(formikProps) => (
        <Form>
         {formikProps.errors.submitError !== undefined ? (
          <Alert variant="danger">{formikProps.errors.submitError}</Alert>
         ) : null}
         <TextInput
          name="userName"
          label="Username"
          type="userName"
          formikProps={formikProps}
         />
         <TextInput
          name="email"
          label="Email"
          type="email"
          formikProps={formikProps}
         />
         <TextInput
          name="firstName"
          label="First Name"
          type="text"
          className="text-uppercase"
          formikProps={formikProps}
         />
         <TextInput
          name="lastName"
          label="Last Name"
          type="text"
          className="text-uppercase"
          formikProps={formikProps}
         />
         <Button
          variant="primary"
          type="submit"
          block
          disabled={formikProps.isSubmitting || !formikProps.isValid}
         >
          {formikProps.isSubmitting && (
           <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
           />
          )}
          Submit
         </Button>
        </Form>
       )}
      </Formik>
     </Card.Body>
    </Card>
   </Col>
  </Container>
 );
};

export default observer(FormUser);
