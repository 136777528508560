import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomTable from "../../app/common/table/CustomTable";
import { IPatientData } from "../../app/models/patient";
import { RootStoreContext } from "../../app/stores/rootStore";

const PatientsList = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getPatients,
    patientsList,
    total,
    pageSize,
    pageIndex,
    keywords,
  } = rootStore.patientStore;

  useEffect(() => {
    getPatients();
  }, [getPatients]);

  const columns: IDataTableColumn[] = [
    {
      name: "Id",
      selector: "id",
    },
    {
      name: "First Name",
      selector: "firstName",
    },
    {
      name: "Last Name",
      selector: "lastName",
    },
    {
      name: "Contact Number",
      selector: "contactNumber",
    },
    {
      name: "Address",
      selector: "address",
    },
    {
      name: "",
      button: true,
      cell: (row: IPatientData) => (
        <Button
          as={Link}
          to={`/patients/edit/${row.id}`}
          variant="outline-primary"
        >
          Edit
        </Button>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    getPatients(newPage, pageSize, keywords);
  };

  const handlePerRowsChange = (newPageSize: number, page: number) => {
    getPatients(page, newPageSize, keywords);
  };

  const handleSearch = (keywords: string) => {
    getPatients(pageIndex, pageSize, keywords);
  };

  return (
    <Container>
      <CustomTable
        title="Patients"
        columns={columns}
        data={patientsList}
        paginationTotalRows={total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSearch={handleSearch}
        rightComponent={
          <>
            <Button as={Link} to="/patients/add">
              Add Patient
            </Button>
          </>
        }
      />
    </Container>
  );
};

export default observer(PatientsList);
