import { observable, action, runInAction } from "mobx";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { ISpermFreezing, ISpermFreezingData } from "../models/spermFreezing";
import SpermFreezings from "../services/spermFreezingService";

export default class SpermFreezingStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable spermFreezingsList: ISpermFreezingData[] = [];
  @observable selectedSpermFreezing: ISpermFreezing | undefined = undefined;
  @observable pageIndex: number = 1;
  @observable pageSize: number = 10;
  @observable total: number = 0;
  @observable keywords: string | undefined = undefined;

  @action getSpermFreezings = async (
    pageIndex: number = 1,
    pageSize: number = 10,
    search?: string
  ) => {
    this.spermFreezingsList = [];
    this.rootStore.commonStore.isDataLoading = true;
    try {
      const response = await SpermFreezings.get(pageIndex, pageSize, search);
      runInAction(() => {
        this.pageIndex = response.pageIndex;
        this.pageSize = response.pageSize;
        this.total = response.count;
        this.spermFreezingsList = response.data;
        this.keywords = search;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isDataLoading = false;
      });
    }
  };

  @action getSpermFreezingById = async (id: number) => {
    try {
      const patient = await SpermFreezings.getById(id);
      runInAction(() => {
        this.selectedSpermFreezing = patient;
      });
      return patient;
    } catch (error) {
      console.log(error);
    }
  };

  @action createSpermFreezing = async (values: ISpermFreezing) => {
    this.rootStore.commonStore.isOverlayLoading = true;
    try {
      await SpermFreezings.create(values);
      history.push("/sperm-freezings");
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isOverlayLoading = false;
      });
    }
  };

  @action updateSpermFreezing = async (values: ISpermFreezing) => {
    this.rootStore.commonStore.isOverlayLoading = true;
    try {
      await SpermFreezings.update(values);
      history.push("/sperm-freezings");
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isOverlayLoading = false;
      });
    }
  };
}
