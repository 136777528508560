import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";
import FormSpinner from "../../app/common/forms/FormSpinner";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/esm/Card";
import Row from "react-bootstrap/esm/Row";
import moment from "moment";
import defaultImage from "../../app/assets/images/embryo.png";

interface IParams {
  id: string;
}

const FormEmbryoFreezingReport: React.FC<RouteComponentProps<IParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const { Body } = Card;

  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState("");
  const [note, setNote] = useState("");
  const [cycleNo, setCycleNo] = useState("");

  const [selectedImage1, setSelectedImage1] = useState<any>(defaultImage);
  const [selectedImage2, setSelectedImage2] = useState<any>(defaultImage);

  const {
    eftlStore: { getEFTLById, selectedEFTL },
  } = useContext(RootStoreContext);

  const handleFileSelect1 = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSelectedImage1(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleFileSelect2 = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSelectedImage2(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getEFTLById(+id).finally(() => setIsLoading(false));
    }
  }, [getEFTLById, id]);

  return (
    <Col className="col-lg-14 my-5 reportFont">
      <Card>
        <Body>
          <h2 className="text-center mb-5 reportTextTitle ">
            Embryo Freezing Report
          </h2>
          {isLoading ? (
            <FormSpinner />
          ) : (
            <div className="reportFontSize">
              <Row>
                <Col>
                  <img
                    src={selectedImage1}
                    alt="patient-img"
                    className="w-100 mb-3"
                    width="415"
                    height="233"
                  />
                  <div className="custom-file hide-on-print">
                    <input
                      type="file"
                      accept="image/*"
                      className="custom-file-input"
                      onChange={handleFileSelect1}
                    />
                    <label className="custom-file-label">Select Image</label>
                  </div>
                </Col>
                <Col className="offset-lg-1 ">
                  <img
                    src={selectedImage2}
                    alt="patient-img"
                    className="w-100 mb-3"
                    width="415"
                    height="233"
                  />
                  <div className="custom-file hide-on-print">
                    <input
                      type="file"
                      accept="image/*"
                      className="custom-file-input"
                      onChange={handleFileSelect2}
                    />
                    <label className="custom-file-label">Select Image</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="offset-lg-2">
                  <text>"Details"</text>
                </Col>
                <Col>
                  <text className="offset-lg-2">
                    {" "}
                    &nbsp; &nbsp; &nbsp; "Details"
                  </text>
                </Col>
              </Row>
              <Row className="offset-lg-2 mt-5">
                <Col sm={12} md={3}>
                  <text>Name:</text>
                </Col>
                <Col sm={12} md={6}>
                  {`${selectedEFTL?.wife?.lastName}, ${selectedEFTL?.wife?.firstName}`}
                </Col>
              </Row>
              <Row className="offset-lg-2 mt-1">
                <Col sm={12} md={3}>
                  <text>Storage No:</text>
                </Col>
                <Col sm={12} md={6}>
                  {selectedEFTL?.efNumber}
                </Col>
              </Row>
              <Row className="offset-lg-2 mt-1">
                <Col sm={12} md={3}>
                  <text>Date:</text>
                </Col>

                <Col sm={12} md={6}>
                  {moment(selectedEFTL?.freezingDateTime).format(
                    "DD MMMM YYYY"
                  )}
                </Col>
              </Row>
              <Row className="offset-lg-2 mt-1">
                <Col sm={12} md={3}>
                  <text>Cycle No.:</text>
                </Col>
                <Col sm={12} md={6}>
                  <input
                    placeholder="Cycle No."
                    type="text"
                    className="text-uppercase form-control hide-on-print"
                    value={cycleNo}
                    onChange={(e) => setCycleNo(e.target.value)}
                  />
                  {cycleNo.toUpperCase()}
                </Col>
              </Row>
              <Row className="offset-lg-2 mt-1">
                <Col sm={12} md={3}>
                  <text>Doctor:</text>
                </Col>
                <Col sm={12} md={6}>
                  {`${selectedEFTL?.doctor?.lastName}, ${selectedEFTL?.doctor?.firstName} ${selectedEFTL?.doctor?.middleName}`}
                </Col>
              </Row>
              <Row className="offset-lg-2 mt-1">
                <Col sm={12} md={3}>
                  <text>Remarks:</text>
                </Col>
                <Col sm={12} md={6}>
                  <input
                    placeholder="Remarks"
                    type="text"
                    className="text-uppercase form-control hide-on-print"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                  {remark.toUpperCase()}
                </Col>
              </Row>
              <Row className="offset-lg-2 mt-1">
                <Col sm={12} md={3}>
                  <text>Location (Dewar/Canister):</text>
                </Col>
                <Col sm={12} md={6}>
                  {selectedEFTL?.dewarLocation}
                </Col>
              </Row>
              <Row className="offset-lg-2 mt-3 mb-4">
                <Col sm={12} md={3}>
                  <text>Note:</text>
                </Col>
                <Col sm={12} md={6}>
                  <textarea
                    placeholder="Note"
                    rows={3}
                    className="text-uppercase form-control hide-on-print"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                  {note.toUpperCase()}
                </Col>
              </Row>
            </div>
          )}
        </Body>
      </Card>
    </Col >
  );
};

export default FormEmbryoFreezingReport;
