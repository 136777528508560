import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomTable from "../../app/common/table/CustomTable";
import { IEmbryoTransfersData } from "../../app/models/embryoTransfers";
import { RootStoreContext } from "../../app/stores/rootStore";

const EmbryoTransfersList = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getEmbryoTransfers,
    embryoTransfersList,
    total,
    pageSize,
    pageIndex,
    keywords,
  } = rootStore.embryoTransferStore;

  useEffect(() => {
    getEmbryoTransfers();
  }, [getEmbryoTransfers]);

  const columns: IDataTableColumn[] = [
    {
      name: "Name",
      selector: "name",
    },
    {
      name: "ART/TW No.",
      selector: "artNumber",
    },
    {
      name: "Date",
      selector: "dateTime",
      format: (row) => format(new Date(row.dateTime), "yyyy-MM-dd"),
    },
    {
      name: "",
      button: true,
      cell: (row: IEmbryoTransfersData) => (
        <Button
          as={Link}
          to={`/embryo-transfers/edit/${row.id}`}
          variant="outline-primary"
        >
          Edit
        </Button>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    getEmbryoTransfers(newPage, pageSize, keywords);
  };

  const handlePerRowsChange = (newPageSize: number, page: number) => {
    getEmbryoTransfers(page, newPageSize, keywords);
  };

  const handleSearch = (keywords: string) => {
    getEmbryoTransfers(pageIndex, pageSize, keywords);
  };

  return (
    <Container>
      <CustomTable
        title="Embryo Transfers"
        columns={columns}
        data={embryoTransfersList}
        paginationTotalRows={total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSearch={handleSearch}
        rightComponent={
          <>
            <Button as={Link} to="/embryo-transfers/add">
              Add Embryo Transfer
            </Button>
          </>
        }
      />
    </Container>
  );
};

export default observer(EmbryoTransfersList);
