import { Formik, Form } from "formik";
import React, { useContext } from "react";
import { ILoginForm } from "../../app/models/auth";
import * as Yup from "yup";
import { RootStoreContext } from "../../app/stores/rootStore";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Alert from "react-bootstrap/esm/Alert";
import TextInput from "../../app/common/forms/TextInput";
import Spinner from "react-bootstrap/esm/Spinner";
import { Link } from "react-router-dom";

const validationSchema = Yup.object().shape({
  userName: Yup.string().required(),
  password: Yup.string().required(),
});

const FormLogin = () => {
  const rootStore = useContext(RootStoreContext);
  const { login } = rootStore.authStore;

  return (
    <Col sm={12} md={4} className="align-self-center">
      <Card>
        <Card.Body>
          <h1 className="text-center mb-5">Welcome!</h1>
          <Formik
            validationSchema={validationSchema}
            onSubmit={(values: ILoginForm, { setSubmitting, setErrors }) => {
              login(values).catch((error) => {
                const { status } = error;
                if (status === 401) {
                  setErrors({ submitError: "Invalid username/password." });
                  setSubmitting(false);
                }
              });
            }}
            initialValues={{ userName: "", password: "" }}
          >
            {(formikProps) => (
              <Form>
                {formikProps.errors.submitError !== undefined ? (
                  <Alert variant="danger">
                    {formikProps.errors.submitError}
                  </Alert>
                ) : null}
                <TextInput
                  name="userName"
                  label="Username"
                  type="userName"
                  placeholder="Username"
                  formikProps={formikProps}
                />
                <TextInput
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Password"
                  formikProps={formikProps}
                />
                <div className="mb-3">
                  <Link to="/forgot-password">Forgot your password?</Link>
                </div>
                <Button
                  variant="primary"
                  type="submit"
                  block
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                >
                  {formikProps.isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-2"
                    />
                  )}
                  Login
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default FormLogin;
