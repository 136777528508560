import axios, { AxiosResponse } from "axios";
import { history } from "../../index";
import { toast } from "react-toastify";

axios.defaults.baseURL = window.__RUNTIME_CONFIG__.API_URL;

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    toast.error("Network error - make sure the API is running!");
  }
  const { status, data, config, headers } = error.response;
  // if (status === 404) {
  //   history.push("/not-found");
  // }
  if (
    status === 401 &&
    headers["www-authenticate"] ===
      'Bearer error="invalid_token", error_description="The token is expired"'
  ) {
    window.localStorage.removeItem("jwt");
    history.push("/");
    toast.info("Your session has expired, please login again");
  }
  if (
    status === 400 &&
    config.method === "get" &&
    data.errors.hasOwnProperty("id")
  ) {
    history.push("/not-found");
  }
  if (status === 500) {
    toast.error("Server error - check the terminal for more info!");
  }
  throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const sleep = (ms: number) => (response: AxiosResponse) =>
  new Promise<AxiosResponse>((resolve) =>
    setTimeout(() => resolve(response), ms)
  );

export default {
  get: (url: string) => axios.get(url).then(sleep(2000)).then(responseBody),
  post: (url: string, body: {}) =>
    axios.post(url, body).then(sleep(2000)).then(responseBody),
  put: (url: string, body: {}) =>
    axios.put(url, body).then(sleep(2000)).then(responseBody),
  delete: (url: string) =>
    axios.delete(url).then(sleep(2000)).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    return axios
      .post(url, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .then(sleep(2000))
      .then(responseBody);
  },
};
