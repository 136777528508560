import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useContext } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import FormSpinner from "../../app/common/forms/FormSpinner";
import moment from "moment";

interface IParams {
  id: string;
}

const FormEmbryologySummaryReport: React.FC<RouteComponentProps<IParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const { Body } = Card;

  const [isLoading, setIsLoading] = useState(false);
  const [cycleNo, setCycleNo] = useState("");
  const [opuDate, setOpuDate] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );
  const [follicle, setfollicle] = useState<number>(0);
  const [eggCollected, setEggCollected] = useState<number>(0);
  const [eggCollectedRt, setEggCollectedRt] = useState<number>(0);
  const [eggCollectedLt, setEggCollectedLt] = useState<number>(0);
  const [eggAvailable, setEggAvailable] = useState<number>(0);
  const [eggAvailableInsemination, setEggAvailableInsemination] =
    useState<number>(0);
  const [eggFertilizedICSI, setEggFertilizedICS] = useState<number>(0);
  const [eggFertilizedIVF, setEggFertilizedIVF] = useState<number>(0);
  const [eggCleaved, setEggCleaved] = useState<number>(0);
  const [embryosTransferred, setEmbryosTransferred] = useState<number>(0);
  const [embryosDiscarded, setEmbryosDiscarded] = useState<number>(0);
  const [embryosFrozen, setEmbryosFrozen] = useState<number>(0);

  const [density, setDensity] = useState<number>(0);
  const [motility, setMotility] = useState<number>(0);
  const [morphology, setMorphology] = useState<number>(0);

  const [gradeEmbryosTransferred, setGradeEmbryosTransferred] =
    useState<string>("");

  const {
    icsiStore: { getICSIById, selectedICSI },
  } = useContext(RootStoreContext);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getICSIById(+id).finally(() => {
        setIsLoading(false);
      });
    }
  }, [getICSIById, id]);

  return (
    <Card>
      <Body>
        <Container className="text-center">
          <h1 className=" font-weight-bold  reportTextTitle">
            In-Vitro Fertilization-Davao, Inc
          </h1>
          <article className="reportTextTitle reportFontSize">
            Ground Floor, Brokenshire WomanCenter Building
            <br />
            Brokenshire Hospital, Madapo Hills, Davao City
            <br />
            Tel No. +63 (082) 298-0311 Cell No. 0917-712-3327 / 0946-368-8104
            <br />
            Email Add: ivfdavaoinc@gmail.com
            <br />
            <br />
          </article>
        </Container>
        {isLoading ? (
          <FormSpinner />
        ) : (
          <div className="reportFontSize">
            <div className="flex">
              <h3 className="text-center mb-5 reportTextTitle mt-4">
                EMBRYOLOGY SUMMARY REPORT
              </h3>
            </div>
            <Row className="offset-lg-1">
              <Col sm={8} md={6}>
                <span className="pr-3 font-weight-bold">Physician</span>
                {`${selectedICSI?.doctor?.lastName}, ${selectedICSI?.doctor?.firstName}`}
              </Col>
              <Col sm={8} md={6}>
                <span className="pr-3 font-weight-bold offset-lg-6">Date</span>
                {moment(selectedICSI?.date).format("YYYY-MM-DD")}
              </Col>
            </Row>
            <Row className="offset-lg-1 mt1">
              <Col sm={8} md={6}>
                <span className="pr-3 font-weight-bold">Patient's Name</span>
                {`${selectedICSI?.patient?.lastName}, ${selectedICSI?.patient?.firstName}`}
              </Col>
              <Col sm={8} md={6}>
                <span className="pr-3 font-weight-bold offset-lg-6">Age</span>
                {selectedICSI?.patient?.age}
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={12}>
                <span className="pr-3 font-weight-bold">Cycle No.:</span>
                {cycleNo.toUpperCase()}
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col>
                <input
                  style={{ width: "20%" }}
                  placeholder="Cycle No."
                  type="text"
                  className="text-uppercase form-control hide-on-print"
                  value={cycleNo}
                  onChange={(e) => setCycleNo(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={12}>
                <span className="pr-3 font-weight-bold">OPU Date</span>
                {moment(opuDate).format("MM/DD/YYYY")}
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col>
                <input
                  style={{ width: "20%" }}
                  placeholder="OPU Date"
                  type="date"
                  className="text-uppercase form-control hide-on-print"
                  value={opuDate}
                  onChange={(e) => setOpuDate(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>1) Total No. of Egg Collected:</article>
              </Col>
              <Col style={{ paddingLeft: "30px" }} sm={8} md={6}>
                <Row>
                  <span className="pr-2">{eggCollected}</span>
                  <span className="pr-2">Rt: </span>
                  <span className="pr-2">{eggCollectedRt}</span>
                  <span className="pr-2">Lt :</span>
                  <span className="pr-2">{eggCollectedLt}</span>
                </Row>
                <Row>
                  <input
                    style={{ width: "30%" }}
                    placeholder="Egg Collected"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={eggCollected}
                    onChange={(e) => setEggCollected(parseInt(e.target.value))}
                  />
                  <input
                    style={{ width: "20%" }}
                    placeholder="Rt"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={eggCollectedRt}
                    onChange={(e) =>
                      setEggCollectedRt(parseInt(e.target.value))
                    }
                  />
                  <input
                    style={{ width: "20%" }}
                    placeholder="Lt"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={eggCollectedLt}
                    onChange={(e) =>
                      setEggCollectedLt(parseInt(e.target.value))
                    }
                  />
                </Row>
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article className="pr-3">
                  2) Total No. of Empty Follicle:
                </article>
              </Col>
              <Col style={{ paddingLeft: "30px" }} sm={8} md={6}>
                <Row>
                  <span>{follicle}</span>
                </Row>
                <Row>
                  <input
                    style={{ width: "30%" }}
                    placeholder="Egg Cleaved"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={follicle}
                    onChange={(e) => setfollicle(parseInt(e.target.value))}
                  />
                </Row>
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article className="pr-3">3) Total No. of Empty Zona:</article>
              </Col>
              <Col sm={8} md={6}>
                <span className="pr-2">{selectedICSI?.emptyZona}</span>
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={4}>
                <article>4) Total No. of Immature Egg:</article>
              </Col>
              <Col sm={8} md={2}>
                <span className=" font-weight-bold">PI: </span>
              </Col>
              <Col sm={8} md={2}>
                <span>{selectedICSI?.gv}</span>
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={4}>
                <article></article>
              </Col>
              <Col sm={8} md={2}>
                <span className=" font-weight-bold">MI: </span>
              </Col>
              <Col sm={8} md={2}>
                <span>{selectedICSI?.mi}</span>
                <span>{selectedICSI?.mi}</span>
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={4}>
                <article>5) Total No. of Mature Egg:</article>
              </Col>
              <Col sm={8} md={2}>
                <span className=" font-weight-bold">MII: </span>
              </Col>
              <Col sm={8} md={2}>
                <span>{selectedICSI?.mii}</span>
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>6) Total No. of Atretic Egg:</article>
              </Col>
              <Col sm={8} md={6}>
                <span>{selectedICSI?.atretic} </span>
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>7) Total No. of Pathenogenic Egg:</article>
              </Col>
              <Col sm={8} md={6}>
                <span>{selectedICSI?.partheno}</span>
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>8) Total No. of Egg Available for Injection:</article>
              </Col>
              <Col style={{ paddingLeft: "30px" }} sm={8} md={6}>
                <Row>
                  <span>
                    {`${eggAvailable} and/or insemination: ${eggAvailableInsemination}`}
                  </span>
                </Row>
                <Row>
                  <input
                    style={{ width: "20%" }}
                    placeholder="Injection"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={eggAvailable}
                    onChange={(e) => setEggAvailable(parseInt(e.target.value))}
                  />
                  <input
                    style={{ width: "20%" }}
                    placeholder="Insemination"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={eggAvailableInsemination}
                    onChange={(e) =>
                      setEggAvailableInsemination(parseInt(e.target.value))
                    }
                  />
                </Row>
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>9) Total No. of Egg Fertilized:</article>
              </Col>
              <Col style={{ paddingLeft: "30px" }} sm={8} md={6}>
                <Row>
                  <span>
                    {`${eggFertilizedICSI} (ICSI) and/or (IVF): ${eggFertilizedIVF}`}
                  </span>
                </Row>
                <Row>
                  <input
                    style={{ width: "20%" }}
                    placeholder="ICSI"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={eggFertilizedICSI}
                    onChange={(e) =>
                      setEggFertilizedICS(parseInt(e.target.value))
                    }
                  />
                  <input
                    style={{ width: "20%" }}
                    placeholder="IVF"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={eggFertilizedIVF}
                    onChange={(e) =>
                      setEggFertilizedIVF(parseInt(e.target.value))
                    }
                  />
                </Row>
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>10) Total No. of Egg Cleaved:</article>
              </Col>
              <Col style={{ paddingLeft: "30px" }} sm={8} md={6}>
                <Row>
                  <span>{eggCleaved}</span>
                </Row>
                <Row>
                  <input
                    style={{ width: "30%" }}
                    placeholder="Egg Cleaved"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={eggCleaved}
                    onChange={(e) => setEggCleaved(parseInt(e.target.value))}
                  />
                </Row>
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>11) Total No. of Embryo(s) Transferred:</article>
              </Col>
              <Col style={{ paddingLeft: "30px" }} sm={8} md={6}>
                <Row>
                  <span>{embryosTransferred}</span>
                </Row>
                <Row>
                  <input
                    style={{ width: "30%" }}
                    placeholder="Embryo(s) Transferred"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={embryosTransferred}
                    onChange={(e) =>
                      setEmbryosTransferred(parseInt(e.target.value))
                    }
                  />
                </Row>
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>12) Grade of Embryo(s) Transferred:</article>
              </Col>
              <Col style={{ paddingLeft: "30px" }} sm={8} md={6}>
                <Row>
                  <span>
                    {" "}
                    {`${gradeEmbryosTransferred} for Embryo Freezing Only`}{" "}
                  </span>
                </Row>
                <Row>
                  <input
                    style={{ width: "50%" }}
                    placeholder="Embryo(s) Transferred"
                    type="text"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={gradeEmbryosTransferred}
                    onChange={(e) => setGradeEmbryosTransferred(e.target.value)}
                  />
                </Row>
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>13) Total No. of Embryo(s) Discarded:</article>
              </Col>
              <Col style={{ paddingLeft: "30px" }} sm={8} md={6}>
                <Row>
                  <span>{embryosDiscarded}</span>
                </Row>
                <Row>
                  <input
                    style={{ width: "30%" }}
                    placeholder="Embryo(s) Discarded"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={embryosDiscarded}
                    onChange={(e) =>
                      setEmbryosDiscarded(parseInt(e.target.value))
                    }
                  />
                </Row>
              </Col>
            </Row>
            <Row className=" offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>14) Total No. of Embryo(s) Frozen:</article>
              </Col>
              <Col style={{ paddingLeft: "30px" }} sm={8} md={6}>
                <Row>
                  <span>{embryosFrozen}</span>
                </Row>
                <Row>
                  <input
                    style={{ width: "30%" }}
                    placeholder="Embryo(s) Frozen"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={embryosFrozen}
                    onChange={(e) => setEmbryosFrozen(parseInt(e.target.value))}
                  />
                </Row>
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article>15) Husband's Semen Analysis on OPU Day:</article>
              </Col>
              <Col sm={8} md={6}>
                <Row>
                  <span className=" font-weight-bold">Density: {density}</span>
                </Row>
                <Row>
                  <input
                    style={{ width: "30%" }}
                    placeholder="Density"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={density}
                    onChange={(e) => setDensity(parseInt(e.target.value))}
                  />
                </Row>
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article></article>
              </Col>
              <Col sm={8} md={6}>
                <Row>
                  <span className=" font-weight-bold">
                    Motility: {motility}
                  </span>
                </Row>
                <Row>
                  <input
                    style={{ width: "30%" }}
                    placeholder="Motility"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={motility}
                    onChange={(e) => setMotility(parseInt(e.target.value))}
                  />
                </Row>
              </Col>
            </Row>
            <Row className="offset-lg-1 mt-1">
              <Col sm={8} md={6}>
                <article></article>
              </Col>
              <Col sm={8} md={6}>
                <Row>
                  <span className=" font-weight-bold">
                    Morphology: {morphology}
                  </span>
                </Row>
                <Row>
                  <input
                    style={{ width: "30%" }}
                    placeholder="Morphology"
                    type="number"
                    className="text-uppercase form-control hide-on-print mr-3"
                    value={morphology}
                    onChange={(e) => setMorphology(parseInt(e.target.value))}
                  />
                </Row>
              </Col>
            </Row>
            <article>{selectedICSI?.emb}</article>
            <article>Embryologist</article>
            <article className="mb-2">IVF Davao Inc</article>
            <article className="mb-5 ">
              N.B Great care is given to the frozeen embryos. However, we cannot
              guarantee that the embryos will survive the
              freezing/storage/thawing process
            </article>
          </div>
        )}
      </Body>
    </Card>
  );
};

export default FormEmbryologySummaryReport;
