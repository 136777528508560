import { OptionHTMLAttributes } from "react";

export const vaginalDischargeOptions: OptionHTMLAttributes<
  HTMLOptionElement
>[] = [
  {
    label: "Normal",
    value: "Normal",
  },
  {
    label: "Abnormal",
    value: "Abnormal",
  },
];
