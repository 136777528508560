import { OptionHTMLAttributes } from "react";

export const patientTypeOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  
  {
    label: "Wife",
    value: "Wife",
  },
  {
    label: "Husband",
    value: "Husband",
  },
  {
    label: "Donor",
    value: "Donor",
  },
];
