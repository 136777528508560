import { FormikProps } from "formik";
import React from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import TextInput from "../../app/common/forms/TextInput";
import { IICSIDetail } from "../../app/models/icsi";
import ICSIDay1Table from "./ICSIDay1Table";
import ICSIDay2Table from "./ICSIDay2Table";
import ICSIDay3Table from "./ICSIDay3Table";
import ICSIDay4Table from "./ICSIDay4Table";
import ICSIDay5Table from "./ICSIDay5Table";
import ICSITable from "./ICSITable";

interface IProps {
  formikProps: FormikProps<any>;
}

const ICSIDetails: React.FC<IProps> = ({ formikProps }) => {
  const { icsiDetails } = formikProps.values;

  const handleAddRow = () => {
    const data = [...icsiDetails];
    const index = (data.length + 1).toString();
    const newRow: IICSIDetail = {
      id: 0,
      icsi: "",
      day1Egg: index,
      day1PN: "",
      day1PB: "",
      day1Remarks: "",
      day2Egg: index,
      day2Dish: "",
      day2Cell: "",
      day2Frag: "",
      day2Score: "",
      day2Notes: "",
      day3Egg: index,
      day3Dish: "",
      day3Cell: "",
      day3Frag: "",
      day3Score: "",
      day3Notes: "",
      day4Egg: index,
      day4Dish: "",
      day4Cell: "",
      day4Frag: "",
      day4Score: "",
      day4Notes: "",
      day5Egg: index,
      day5Dish: "",
      day5Cell: "",
      day5Frag: "",
      day5Score: "",
      day5Notes: "",
      option: "EmbryoTransfer",
    };
    data.push(newRow);
    formikProps.setFieldValue("icsiDetails", data);
  };

  const handleRemoveRow = () => {
    const data = [...icsiDetails];
    data.pop();
    formikProps.setFieldValue("icsiDetails", data);
  };

  return (
    <div className="mx-5 mb-3">
      <Row>
        <Col>
          <div className="mb-3">
            <Button className="mr-2" onClick={handleAddRow}>
              Add
            </Button>
            <Button
              onClick={handleRemoveRow}
              disabled={icsiDetails.length === 0}
            >
              Remove
            </Button>
          </div>
        </Col>
      </Row>
      <div style={{ overflowX: "scroll" }}>
        <Row style={{ width: "200%" }}>
          <Col md={formikProps.values["isDay5Visible"] ? 2 : 3}>
            <h4>ICSI</h4>
            <TextInput
              name="icsiTimeStarted"
              label="Time Started"
              type="time"
              formikProps={formikProps}
            />
            <TextInput
              name="icsiTimeEnded"
              label="Time Ended"
              type="time"
              formikProps={formikProps}
            />
            <ICSITable formikProps={formikProps} />
          </Col>
          <Col md={formikProps.values["isDay5Visible"] ? 2 : 3}>
            <h4>Day 1</h4>
            <TextInput
              name="day1Time"
              label="Time"
              type="time"
              formikProps={formikProps}
            />
            <TextInput
              name="day1Date"
              label="Date"
              type="date"
              formikProps={formikProps}
            />
            <ICSIDay1Table formikProps={formikProps} />
          </Col>
          <Col md={formikProps.values["isDay5Visible"] ? 2 : 3}>
            <h4>Day 2</h4>
            <TextInput
              name="day2Time"
              label="Time"
              type="time"
              formikProps={formikProps}
            />
            <TextInput
              name="day2Date"
              label="Date"
              type="date"
              formikProps={formikProps}
            />
            <ICSIDay2Table formikProps={formikProps} />
          </Col>
          <Col md={formikProps.values["isDay5Visible"] ? 2 : 3}>
            <h4>Day 3</h4>
            <TextInput
              name="day3Time"
              label="Time"
              type="time"
              formikProps={formikProps}
            />
            <TextInput
              name="day3Date"
              label="Date"
              type="date"
              formikProps={formikProps}
            />
            <ICSIDay3Table formikProps={formikProps} />
          </Col>
          {formikProps.values["isDay5Visible"] && (
            <>
              <Col md={2}>
                <h4>Day 4</h4>
                <TextInput
                  name="day4Time"
                  label="Time"
                  type="time"
                  formikProps={formikProps}
                />
                <TextInput
                  name="day4Date"
                  label="Date"
                  type="date"
                  formikProps={formikProps}
                />
                <ICSIDay4Table formikProps={formikProps} />
              </Col>
              <Col md={2}>
                <h4>Day 5</h4>
                <TextInput
                  name="day5Time"
                  label="Time"
                  type="time"
                  formikProps={formikProps}
                />
                <TextInput
                  name="day5Date"
                  label="Date"
                  type="date"
                  formikProps={formikProps}
                />
                <ICSIDay5Table formikProps={formikProps} />
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ICSIDetails;
