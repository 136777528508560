import { FormikProps } from "formik";
import React from "react";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";

interface IProps {
 formikProps: FormikProps<any>;
}

const ICSIDay3Table: React.FC<IProps> = ({ formikProps }) => {
 const { icsiDetails } = formikProps.values;

 const editableColumns = [
  {
   title: "EGG",
   field: "day3Egg",
   editor: "label",
   headerSort: false,
  },
  {
   title: "Dish",
   field: "day3Dish",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Cell",
   field: "day3Cell",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Frag",
   field: "day3Frag",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Score",
   field: "day3Score",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Notes",
   field: "day3Notes",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Option",
   field: "option",
   editor: "select",
   editorParams: {
    EmbryoTransfer: "Embryo Transfer",
    EmbryoFreezing: "Embryo Freezing",
    FurtherCulture: "Further Culture",
   },
   headerSort: false,
  },
 ];

 return (
  <ReactTabulator
   className="table-sm mb-3 table-bordered table-responsive text-uppercase"
   columns={editableColumns}
   data={icsiDetails}
  />
 );
};

export default ICSIDay3Table;
