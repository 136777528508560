import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/esm/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import Row from "react-bootstrap/esm/Row";
import SelectInput from "../../app/common/forms/SelectInput";
import { incubatorOptions } from "../../app/common/options/incubatorOptions";
import { embOptions } from "../../app/common/options/embOptions";
import { denPipetteOptions } from "../../app/common/options/denPipetteOptions";
import Col from "react-bootstrap/esm/Col";
import { Form, Formik } from "formik";
import TextInput from "../../app/common/forms/TextInput";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";
import FormSpinner from "../../app/common/forms/FormSpinner";
import { IICSI, ICSIFormValues } from "../../app/models/icsi";
import PatientSelect from "../../app/common/forms/PatientSelect";
import IvfSelect from "../../app/common/forms/IvfSelect";
import ICSIDetails from "./ICSIDetails";
import DoctorSelect from "../../app/common/forms/DoctorSelect";
import convertTime from "../../app/common/util";
import Container from "react-bootstrap/esm/Container";

interface IParams {
  id: string;
}

const validationSchema = Yup.object<IICSI>().shape({
  patientId: Yup.string().required("Name is required"),
  date: Yup.string().required("Patient ID is required"),
  emb: Yup.string().required("Embryologist is required"),
  doctorId: Yup.string().required("Doctor is required"),
  time: Yup.string()
    .matches(
      new RegExp("(?:(?:([01]?|2[0-3]):)?([0-5]?):)?([0-5]?)$"),
      "Time is required."
    )
    .required("Time is required"),
  incubator: Yup.string().required("Incubator is required"),
  denPipette: Yup.string().required("Denpipette is required"),
  total: Yup.number().required("Total is required"),
  injected: Yup.number().required("Injected is required"),
  mii: Yup.number().required("MII is required"),
  mi: Yup.number().required("MI is required"),
  gv: Yup.number().required("GV is required"),
  atretic: Yup.number().required("Atretic is required"),
  partheno: Yup.number().required("Partheno is required"),
  emptyZona: Yup.number().required("Empty Zona is required"),
  ndi: Yup.number().required("Ndi is required"),
  fertRate: Yup.number().required("Fert Rate is required"),
  cleavageRate: Yup.number().required("Cleavage Rate is required"),
  embryoQuality: Yup.string().required("Embryo quality is required"),
  holdingRemarks: Yup.string().required("Holding Remarks is required"),
  injectionRemarks: Yup.string().required("Injection Remarks is required"),
  oocyteRemarks: Yup.string().required("Oocyte is required"),
  spermRemarks: Yup.string().required("Sperm Remarks is required"),
  icsiRemarks: Yup.string().required("ICSI Remarks is required"),
});

const FormIcsi: React.FC<RouteComponentProps<IParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const rootStore = useContext(RootStoreContext);
  const { createICSI, getICSIById, updateICSI } = rootStore.icsiStore;
  const [icsi, setICSI] = useState(new ICSIFormValues());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getICSIById(+id)
        .then((data) => {
          setICSI(new ICSIFormValues(data));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [getICSIById, id]);

  return (
    <>
      <h2 className="text-center mb-5 text-primary">ICSI Form</h2>
      {isLoading ? (
        <FormSpinner />
      ) : (
        <Formik
          validationSchema={validationSchema}
          onSubmit={(values: IICSI) => {
            values.time = convertTime(values.time);
            values.icsiTimeEnded = convertTime(values.icsiTimeEnded);
            values.icsiTimeStarted = convertTime(values.icsiTimeStarted);
            values.day1Time = convertTime(values.day1Time);
            values.day2Time = convertTime(values.day2Time);
            values.day3Time = convertTime(values.day3Time);

            if (values.day4Time) {
              values.day4Time = convertTime(values.day4Time);
            }
            if (values.day5Time) {
              values.day5Time = convertTime(values.day5Time);
            }

            if (id) {
              updateICSI(values);
            } else {
              createICSI(values);
            }
          }}
          initialValues={icsi}
          enableReinitialize={true}
        >
          {(formikProps) => (
            <Form>
              <Container>
                <Row>
                  <Col sm={12} md={4}>
                    <PatientSelect
                      name="patientId"
                      label="Name"
                      placeholder="Select Patient"
                      formikProps={formikProps}
                      showRequiredIcon={true}
                    />
                  </Col>
                  <Col sm={12} md={2}>
                    <IvfSelect
                      name="artTwNo"
                      label="ART / TW No."
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col sm={12} md={2}>
                    <TextInput
                      name="date"
                      label="Date"
                      type="date"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <DoctorSelect
                      name="doctorId"
                      label="Doctor"
                      placeholder="Select Doctor"
                      formikProps={formikProps}
                      showRequiredIcon={true}
                    />
                  </Col>
                </Row>
                <h4 className="mb-3 text-primary mt-3">Denuding</h4>
                <Row>
                  <Col>
                    <TextInput
                      name="time"
                      label="Time"
                      type="time"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <SelectInput
                      name="incubator"
                      label="Incubator"
                      formikProps={formikProps}
                      options={incubatorOptions}
                    />
                  </Col>
                  <Col>
                    <SelectInput
                      name="emb"
                      label="Embryologist"
                      formikProps={formikProps}
                      options={embOptions}
                    />
                  </Col>
                  <Col>
                    <SelectInput
                      name="denPipette"
                      label="DenPipette"
                      formikProps={formikProps}
                      options={denPipetteOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      name="total"
                      label="Total"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      name="injected"
                      label="Injected"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      name="mii"
                      label="MII"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      name="mi"
                      label="MI"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      name="gv"
                      label="GV"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>

                  <Col>
                    <TextInput
                      name="atretic"
                      label="Atretic"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      name="partheno"
                      label="Partheno"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      name="emptyZona"
                      label="Empty Zona"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      name="ndi"
                      label="NDI"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-2">
                    <ButtonGroup className="mr-2">
                      <Button
                        onClick={() =>
                          formikProps.setFieldValue("isDay5Visible", false)
                        }
                      >
                        Day 1 to Day 3
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup className="mr-2">
                      <Button
                        onClick={() =>
                          formikProps.setFieldValue("isDay5Visible", true)
                        }
                      >
                        Day 1 to Day 5
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Container>

              <ICSIDetails formikProps={formikProps} />

              <Container>
                <Row>
                  <Col>
                    <TextInput
                      name="fertRate"
                      label="Fert Rate"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      name="cleavageRate"
                      label="Cleavage"
                      type="number"
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      name="embryoQuality"
                      label="Embryo Quality"
                      type="text"
                      className="text-uppercase"
                      formikProps={formikProps}
                      showRequiredIcon={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      name="holdingRemarks"
                      label="Holding"
                      type="text"
                      formikProps={formikProps}
                      className="text-uppercase"
                      showRequiredIcon={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      name="injectionRemarks"
                      label="Injection"
                      type="text"
                      formikProps={formikProps}
                      className="text-uppercase"
                      showRequiredIcon={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      name="oocyteRemarks"
                      label="Oocyte"
                      type="text"
                      formikProps={formikProps}
                      className="text-uppercase"
                      showRequiredIcon={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      name="spermRemarks"
                      label="Sperm"
                      type="text"
                      formikProps={formikProps}
                      className="text-uppercase"
                      showRequiredIcon={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      name="icsiRemarks"
                      label="ICSI"
                      type="text"
                      formikProps={formikProps}
                      className="text-uppercase"
                      showRequiredIcon={true}
                    />
                  </Col>
                </Row>
                <Button
                  className="float-right mb-5"
                  type="submit"
                  size="lg"
                  variant="primary"
                  disabled={
                    formikProps.isSubmitting ||
                    !formikProps.isValid ||
                    !formikProps.values.patientId ||
                    !formikProps.values.doctorId
                  }
                >
                  Submit
                </Button>
              </Container>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormIcsi;
