import { observable, action, runInAction } from "mobx";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { IEmbryoTransfers, IEmbryoTransfersData } from "../models/embryoTransfers";
import EmbryoTransfers from "../services/embryoTransferService"

export default class EmbryoTransferStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable embryoTransfersList: IEmbryoTransfersData[] = [];
  @observable selectedEmbryoTransfers: IEmbryoTransfers | undefined = undefined;
  @observable pageIndex: number = 1;
  @observable pageSize: number = 10;
  @observable total: number = 0;
  @observable keywords: string | undefined = undefined;

  @action getEmbryoTransfers = async (
    pageIndex: number = 1,
    pageSize: number = 10,
    search?: string
  ) => {
    this.embryoTransfersList = [];
    this.rootStore.commonStore.isDataLoading = true;
    try {
      const response = await EmbryoTransfers.get(pageIndex, pageSize, search);
      runInAction(() => {
        this.pageIndex = response.pageIndex;
        this.pageSize = response.pageSize;
        this.total = response.count;
        this.embryoTransfersList = response.data;
        this.keywords = search;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isDataLoading = false;
      });
    }
  };

  @action getEmbryoTransfersById = async (id: number) => {
    try {
      const embryoTransfers = await EmbryoTransfers.getById(id);
      runInAction(() => {
        this.selectedEmbryoTransfers = embryoTransfers;
      });
      return embryoTransfers;
    } catch (error) {
      console.log(error);
    }
  };

  @action createEmbryoTransfers = async (values: IEmbryoTransfers) => {
    this.rootStore.commonStore.isOverlayLoading = true;
    try {
      await EmbryoTransfers.create(values);
      history.push("/embryo-transfers");
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isOverlayLoading = false;
      });
    }
  };

  @action updateEmbryoTransfers = async (values: IEmbryoTransfers) => {
    this.rootStore.commonStore.isOverlayLoading = true;
    try {
      await EmbryoTransfers.update(values);
      history.push("/embryo-transfers");
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isOverlayLoading = false;
      });
    }
  };
}
