import { RouteProps } from "react-router-dom";
import FormUpdatePassword from "../../../components/Account/FormUpdatePassword";

const accountRoutes: RouteProps[] = [
  {
    path: "/account/update-password",
    component: FormUpdatePassword,
  },
];

export default accountRoutes;
