import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import Badge from "react-bootstrap/esm/Badge";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomTable from "../../app/common/table/CustomTable";
import { IDoctor } from "../../app/models/doctor";
import { RootStoreContext } from "../../app/stores/rootStore";

const DoctorsList = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getDoctors,
    doctorsList,
    total,
    pageSize,
    pageIndex,
    keywords,
  } = rootStore.doctorStore;

  useEffect(() => {
    getDoctors();
  }, [getDoctors]);

  const columns: IDataTableColumn[] = [
    {
      name: "Id",
      selector: "id",
    },
    {
      name: "First Name",
      selector: "firstName",
    },
    {
      name: "Last Name",
      selector: "lastName",
    },
    {
      name: "Status",
      cell: (row: IDoctor) => (
        <>
          {row.isActive ? (
            <Badge variant="success">Active</Badge>
          ) : (
            <Badge variant="danger">Inactive</Badge>
          )}
        </>
      ),
    },
    {
      name: "",
      button: true,
      cell: (row: IDoctor) => (
        <Button
          as={Link}
          to={`/doctors/edit/${row.id}`}
          variant="outline-primary"
        >
          Edit
        </Button>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    getDoctors(newPage, pageSize, keywords);
  };

  const handlePerRowsChange = (newPageSize: number, page: number) => {
    getDoctors(page, newPageSize, keywords);
  };

  const handleSearch = (keywords: string) => {
    getDoctors(pageIndex, pageSize, keywords);
  };

  return (
    <Container>
      <CustomTable
        title="Doctors"
        columns={columns}
        data={doctorsList}
        paginationTotalRows={total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSearch={handleSearch}
        rightComponent={
          <>
            <Button as={Link} to="/doctors/add">
              Add Doctor
            </Button>
          </>
        }
      />
    </Container>
  );
};

export default observer(DoctorsList);
