import { observable, action, runInAction } from "mobx";
import { RootStore } from "./rootStore";
import { IIVF, IIVFData } from "./../models/ivf";
import { history } from "../..";
import IVFs from "../services/ivfService";

export default class IVFStore {
 rootStore: RootStore;

 constructor(rootStore: RootStore) {
  this.rootStore = rootStore;
 }

 @observable ivfsList: IIVFData[] = [];
 @observable selectedIVF: IIVF | undefined = undefined;
 @observable pageIndex: number = 1;
 @observable pageSize: number = 10;
 @observable total: number = 0;
 @observable keywords: string | undefined = undefined;

 @action getIVFs = async (
  pageIndex: number = 1,
  pageSize: number = 10,
  search?: string
 ) => {
  this.ivfsList = [];
  this.rootStore.commonStore.isDataLoading = true;
  try {
   const response = await IVFs.get(pageIndex, pageSize, search);
   runInAction(() => {
    this.pageIndex = response.pageIndex;
    this.pageSize = response.pageSize;
    this.total = response.count;
    this.ivfsList = response.data;
    this.keywords = search;
   });
  } catch (error) {
   console.log(error);
  } finally {
   runInAction(() => {
    this.rootStore.commonStore.isDataLoading = false;
   });
  }
 };

 @action getIVFById = async (id: number) => {
  try {
   const patient = await IVFs.getById(id);
   runInAction(() => {
    this.selectedIVF = patient;
   });
   return patient;
  } catch (error) {
   console.log(error);
  }
 };

 @action createIvf = async (values: IIVF) => {
  this.rootStore.commonStore.isOverlayLoading = true;
  try {
   await IVFs.create(values);
   history.push("/ivfs");
  } catch (error) {
   console.log(error);
  } finally {
   runInAction(() => {
    this.rootStore.commonStore.isOverlayLoading = false;
   });
  }
 };

 @action updateIVF = async (values: IIVF) => {
  this.rootStore.commonStore.isOverlayLoading = true;
  try {
   await IVFs.update(values);
   history.push("/ivfs");
  } catch (error) {
   console.log(error);
  } finally {
   runInAction(() => {
    this.rootStore.commonStore.isOverlayLoading = false;
   });
  }
 };
}
