import React, { useContext, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { IPatientOption } from "../../models/patient";
import Patients from "../../services/patientService";
import Form from "react-bootstrap/esm/Form";
import { FormikProps } from "formik";
import { PatientType } from "../../models/types";
import { RootStoreContext } from "../../stores/rootStore";
import RequiredIcon from "./RequiredIcon";

interface IProps {
 formikProps: FormikProps<any>;
 name: string;
 label?: string;
 placeholder?: string;
 patientType?: PatientType;
 showRequiredIcon?: boolean;
 setOnChange?: (values: any) => void;
}

const PatientSelect: React.FC<IProps> = ({
 name,
 formikProps,
 label = "",
 placeholder = "",
 patientType = "",
 showRequiredIcon = false,
 setOnChange,
}) => {
 const [options, setOptions] = useState<any[]>([]);
 const [isLoading, setIsLoading] = useState(false);
 const [isDisabled, setIsDisabled] = useState(true);
 const [selectedOption, setSelectedOption] = useState<any>();
 const [isEmpty, setIsEmpty] = useState(false);

 const rootStore = useContext(RootStoreContext);
 const { setSelectedPatientOption } = rootStore.patientStore;

 const getSelectedPatient = async () => {
  setIsLoading(true);
  const patient = formikProps.values[name.slice(0, -2)];
  if (patient) {
   const option = {
    value: patient.id,
    label: `${patient.lastName}, ${patient.firstName}`,
    age: patient.age,
    ivfs: patient.ivfs,
   };
   setSelectedOption(option);
   setOptions([option]);
   handleChange(option);
  }
  setIsLoading(false);
  setIsDisabled(false);
 };

 useEffect(() => {
  if (formikProps.values[name] > 0) {
   getSelectedPatient();
  } else {
   setIsDisabled(false);
  }
  // eslint-disable-next-line
 }, []);

 const handleChange = (values: any) => {
  formikProps.setFieldValue(name, values.value);
  if (setOnChange) {
   setOnChange(values);
  }
  setSelectedOption(values);
  setSelectedPatientOption(values);
 };

 const loadOptions = async (input: string) => {
  setIsLoading(true);
  const patients = await Patients.getOptions(input, patientType);
  const options = patients.map((patient: IPatientOption) => {
   const option = {
    value: patient.id,
    label: `${patient.lastName}, ${patient.firstName}`,
    age: patient.age,
    ivfs: patient.ivfs,
   };
   return option;
  });
  setOptions(options);
  setIsLoading(false);
  return options;
 };

 const handleBlur = () => {
  if (!selectedOption) setIsEmpty(true);
  else setIsEmpty(false);
 };

 return (
  <>
   <Form.Group controlId={name}>
    <Form.Label>
     {label}
     {showRequiredIcon && <RequiredIcon />}
    </Form.Label>
    <AsyncSelect
     cacheOptions
     isLoading={isLoading}
     defaultOptions={options}
     loadOptions={loadOptions}
     placeholder={placeholder}
     onChange={handleChange}
     value={selectedOption}
     isDisabled={isDisabled}
     onBlur={handleBlur}
    />
    {isEmpty && (
     <Form.Text style={{ color: "red" }}>{label} is required.</Form.Text>
    )}
   </Form.Group>
  </>
 );
};

export default PatientSelect;
