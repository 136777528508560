import { OptionHTMLAttributes } from "react";

export const etPositionOption: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "Dorsal",
    value: "dorsal",
  },
  {
    label: "Lateral",
    value: "lateral",
  },
  {
    label: "Lithotomy",
    value: "lithotomy",
  },
];
