import { IDoctorOption } from "./doctor";
import { IPagination } from "./pagination";
import {
  ETCervix,
  ETFinalOutcome,
  ETPosition,
  ETTransfer,
  ETTreatment,
  ETVaginalDischarge,
  Gender,
} from "./types";
import { format } from "date-fns";
import { IPatientOption } from "./patient";

export interface IEmbryoTransfers {
  id?: number;
  wifeId: number;
  wife?: IPatientOption;
  artNumber: string;
  dateTime: Date | string;
  date: Date | string;
  time: Date | string;
  intervalTimeAfterOrIns: string;
  embryoTransferCells: IEmbryoTransferCells[];
  catheterUsed: string;
  numberOfAttempts: number;
  mucusOnCatheter: boolean;
  bloodOnCatheter: boolean;
  etMedium: string;
  lot: string;
  lotExpiry: Date | string;
  volume: number;
  embryologist: string;
  witness: string;
  rn: string;
  medicalDoctorId: number;
  medicalDoctor?: IDoctorOption;
  sono: string;
  endoThickness: number;
  tenaculum: boolean;
  etPosition: ETPosition;
  vaginalDischarge: ETVaginalDischarge;
  transfer: ETTransfer;
  ifDifficultReason: string;
  bleedingOrDiscomfort: string;
  postTransferPosition: ETPosition;
  duration: Date | string;
  // durationTime: Date | string;
  distanceFromFundus: number;
  styletUsed: boolean;
  ga: boolean;
  sonologist: string;
  cervix: ETCervix;
  myometrialContractility: string;
  treatment: ETTreatment;
  treatmentCycleArtNumber: string;
  totalNumberOocytesEmbryos: number;
  numberFertilisedNormal: number;
  numberFertilisedAbnormal: number;
  anyAbnormalities: string;
  eggQuality: string;
  spermQuality: string;
  numberStageOfEmbryosFrozen: string;
  efNumber: string;
  remarks: string;
  hcgLevels: IEmbryoTransferHCGLevel[];
  ultrasoundScans: IEmbryoTransferUltrasoundScan[];
  finalOutcome: ETFinalOutcome;
  placeOfDeliveries: IEmbryoTransferPlaceOfDelivery[];
}

export interface IEmbryoTransferCells {
  index?: number;
  cells: string;
  grade: string;
  remarks: string;
  comments: string;
  id?: number;
}

export interface IEmbryoTransferHCGLevel {
  date: Date | string;
  hcgLevel: string;
  id?: number;
}

export interface IEmbryoTransferUltrasoundScan {
  date: Date | string;
  numberOfSacs: number;
  crl: string;
  fh: boolean;
  id?: number;
}

export interface IEmbryoTransferPlaceOfDelivery {
  date: Date | string;
  gestation: string;
  weight: number;
  gender: Gender;
  congAbnormality: string;
  id?: number;
}

export interface IEmbryoTransfersData {
  id: number;
  wifeId: number;
  artNumber: string;
  dateTime: Date | string;
  name: string;
}

export interface IEmbryoTransfersPagination extends IPagination {
  data: IEmbryoTransfersData[];
}

export class EmbryoTransfersFormValues implements IEmbryoTransfers {
  wifeId = 0;
  artNumber = "";
  dateTime = "";
  date = format(new Date(), "yyyy-MM-dd");
  time = format(new Date(), "HH:mm");
  intervalTimeAfterOrIns = "";
  embryoTransferCells = [] as IEmbryoTransferCells[];
  catheterUsed = "";
  numberOfAttempts = 0;
  mucusOnCatheter = true;
  bloodOnCatheter = true;
  etMedium = "";
  lot = "";
  lotExpiry = format(new Date(), "yyyy-MM-dd");
  volume = 0;
  embryologist = "GEPTE";
  witness = "";
  rn = "";
  medicalDoctorId = 0;
  sono = "";
  endoThickness = 0;
  tenaculum = true;
  etPosition = "Dorsal";
  vaginalDischarge = "Normal";
  transfer = "Easy";
  ifDifficultReason = "";
  bleedingOrDiscomfort = "";
  postTransferPosition = "Dorsal";
  duration = "";
  durationTime = format(new Date(), "HH:mm");
  distanceFromFundus = 0;
  styletUsed = true;
  ga = true;
  sonologist = "";
  cervix = "Healthy";
  myometrialContractility = "";
  treatment = "IVF";
  treatmentCycleArtNumber = "";
  totalNumberOocytesEmbryos = 0;
  numberFertilisedNormal = 0;
  numberFertilisedAbnormal = 0;
  anyAbnormalities = "";
  eggQuality = "";
  spermQuality = "";
  numberStageOfEmbryosFrozen = "";
  efNumber = "";
  remarks = "";
  hcgLevels = [] as IEmbryoTransferHCGLevel[];
  ultrasoundScans = [] as IEmbryoTransferUltrasoundScan[];
  finalOutcome = "NotPregnant";
  placeOfDeliveries = [] as IEmbryoTransferPlaceOfDelivery[];

  constructor(init?: IEmbryoTransfers) {
    Object.assign(this, init);

    if (init && init.embryoTransferCells) {
      var embryoTransferCells = init.embryoTransferCells.map(
        (embryoTransferCell: IEmbryoTransferCells, index: number) => {
          embryoTransferCell.index = index + 1;

          return embryoTransferCell;
        }
      );

      this.embryoTransferCells = embryoTransferCells;
    }

    if (init && init.lotExpiry) {
      this.lotExpiry = format(new Date(init.lotExpiry), "yyyy-MM-dd");
    }

    if (init && init.dateTime) {
      this.date = format(new Date(init.dateTime), "yyyy-MM-dd");
      this.time = format(new Date(init.dateTime), "HH:mm");
    }

    if (init && init.time) {
      this.time = format(new Date(init.time), "HH:mm");
    }

    if (init && init.duration) {
      this.duration = format(new Date(init.duration), "HH:mm");
    }
  }
}
