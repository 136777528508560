import { IEFTLData, IEFTL } from "./../models/eftl";
import { observable, action, runInAction } from "mobx";

import EFTLs from "../services/eftlService";
import { RootStore } from "./rootStore";
import { history } from "../..";

export default class EFTLStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable eftlsList: IEFTLData[] = [];
  @observable selectedEFTL: IEFTL | undefined = undefined;
  @observable pageIndex: number = 1;
  @observable pageSize: number = 10;
  @observable total: number = 0;
  @observable keywords: string | undefined = undefined;

  @action getEFTLs = async (
    pageIndex: number = 1,
    pageSize: number = 10,
    search?: string
  ) => {
    this.eftlsList = [];
    this.rootStore.commonStore.isDataLoading = true;
    try {
      const response = await EFTLs.get(pageIndex, pageSize, search);
      runInAction(() => {
        this.pageIndex = response.pageIndex;
        this.pageSize = response.pageSize;
        this.total = response.count;
        this.eftlsList = response.data;
        this.keywords = search;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isDataLoading = false;
      });
    }
  };

  @action getEFTLById = async (id: number) => {
    try {
      const eftl = await EFTLs.getById(id);
      runInAction(() => {
        this.selectedEFTL = eftl;
      });
      return eftl;
    } catch (error) {
      console.log(error);
    }
  };

  @action createEFTL = async (values: IEFTL) => {
    this.rootStore.commonStore.isOverlayLoading = true;
    try {
      await EFTLs.create(values);
      history.push("/embryo-freezing-and-thawing");
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isOverlayLoading = false;
      });
    }
  };

  @action updateEFTL = async (values: IEFTL) => {
    try {
      await EFTLs.update(values);
      history.push("/embryo-freezing-and-thawing");
    } catch (error) {
      console.log(error);
    }
  };
}
