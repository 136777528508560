import { RootStore } from "./rootStore";
import { observable, action } from "mobx";

export default class ModalStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable.shallow modal = {
    open: false,
    body: null,
    title: "",
    loadingTip: ""
  };

  @action openModal = (content: any, title: string, loadingTip: string) => {
    this.modal.open = true;
    this.modal.title = title;
    this.modal.loadingTip = loadingTip;
    this.modal.body = content;
  };

  @action closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };
}
