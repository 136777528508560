import { RouteProps } from "react-router-dom";
import FormPatient from "../../../components/Patient/FormPatient";
import PatientsList from "../../../components/Patient/PatientsList";

const patientRoutes: RouteProps[] = [
  {
    path: "/patients/edit/:id",
    component: FormPatient,
  },
  {
    path: "/patients/add",
    component: FormPatient,
  },
  {
    path: "/patients",
    component: PatientsList,
  },
];

export default patientRoutes;
