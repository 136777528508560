import { RouteProps } from "react-router-dom";
import FormEFTL from "../../../components/EFTL/FormEFTL";
import EFTLsList from "../../../components/EFTL/EFTLsList";

const eftlRoutes: RouteProps[] = [
  {
    path: "/embryo-freezing-and-thawing/edit/:id",
    component: FormEFTL,
  },
  {
    path: "/embryo-freezing-and-thawing/add",
    component: FormEFTL,
  },
  {
    path: "/embryo-freezing-and-thawing",
    component: EFTLsList,
  },
];

export default eftlRoutes;
