import { IEFTL } from "./../models/eftl";
import requests from "./httpService";
import { IEFTLPagination } from "../models/eftl";

const api = "/eftls";

const EFTLs = {
  get: (
    pageIndex: number,
    pageSize: number,
    search?: string
  ): Promise<IEFTLPagination> =>
    requests.get(
      `${api}?pageIndex=${pageIndex}&pageSize=${pageSize}${
        search ? `&search=${search}` : ``
      }`
    ),
  getById: (id: number): Promise<IEFTL> =>
    requests.get(`${api}/${id}`),
  
  create: (values: IEFTL): Promise<IEFTL> =>
    requests.post(`${api}`, values),
  update: (values: IEFTL): Promise<IEFTL> =>
    requests.put(`${api}/${values.id}`, values),

 
};

export default EFTLs;