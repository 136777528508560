import React from "react";
import Container from "react-bootstrap/esm/Container";

const Dashboard = () => {
  return (
    <Container>
      <h1>Dashboard</h1>
    </Container>
  );
};

export default Dashboard;
