import { IDoctorData } from "./doctor";
import { IPatientOption } from "./patient";
import { DenPipette, Emb, Incubator, IcsiType as ICSIType } from "./types";
import { IPagination } from "./pagination";
import { format } from "date-fns";

export interface IICSI {
  id?: number;
  patientId: number;
  patient?: IPatientOption;
  date: Date | string;
  doctorId: number;
  doctor?: IDoctorData;
  time: Date | string;
  incubator: Incubator;
  emb: Emb;
  denPipette: DenPipette;
  total: number;
  injected: number;
  mii: number;
  mi: number;
  gv: number;
  atretic: number;
  partheno: number;
  emptyZona: number;
  ndi: number;
  icsiTimeStarted: Date | string;
  icsiTimeEnded: Date | string;
  day1Time: Date | string;
  day1Date: Date | string;
  day2Time: Date | string;
  day2Date: Date | string;
  day3Time: Date | string;
  day3Date: Date | string;
  day4Time?: Date | string;
  day4Date?: Date | string;
  day5Time?: Date | string;
  day5Date?: Date | string;
  isDay4Visible: boolean;
  isDay5Visible: boolean;
  icsiDetails: IICSIDetail[];
  fertRate: number;
  cleavageRate: number;
  embryoQuality: string;
  holdingRemarks: string;
  injectionRemarks: string;
  oocyteRemarks: string;
  spermRemarks: string;
  icsiRemarks: string;
}

export interface IICSIDetail {
  id?: number;
  icsi: string;
  day1Egg: string;
  day1PN: string;
  day1PB: string;
  day1Remarks: string;
  day2Egg: string;
  day2Dish: string;
  day2Cell: string;
  day2Frag: string;
  day2Score: string;
  day2Notes: string;
  day3Egg: string;
  day3Dish: string;
  day3Cell: string;
  day3Frag: string;
  day3Score: string;
  day3Notes: string;
  day4Egg: string;
  day4Dish?: string;
  day4Cell?: string;
  day4Frag?: string;
  day4Score?: string;
  day4Notes?: string;
  day5Egg: string;
  day5Dish?: string;
  day5Cell?: string;
  day5Frag?: string;
  day5Score?: string;
  day5Notes?: string;
  option: ICSIType;
}

export interface IICSIData {
  id: number;
  patientName: string;
  procedureDate: Date | string;
}

export interface IICSIPagination extends IPagination {
  data: IICSIData[];
}

export class ICSIFormValues implements IICSI {
  patientId = 0;
  date = format(new Date(), "yyyy-MM-dd");
  doctorId = 0;
  time = format(new Date(), "HH:mm");
  incubator = "ISSYSTEM";
  emb = "GEPTE";
  denPipette = "R1";
  total = 0;
  injected = 0;
  mii = 0;
  mi = 0;
  gv = 0;
  atretic = 0;
  partheno = 0;
  emptyZona = 0;
  ndi = 0;
  icsiTimeStarted = format(new Date(), "HH:mm");
  icsiTimeEnded = format(new Date(), "HH:mm");
  day1Time = format(new Date(), "HH:mm");
  day1Date = format(new Date(), "yyyy-MM-dd");
  day2Time = format(new Date(), "HH:mm");
  day2Date = format(new Date(), "yyyy-MM-dd");
  day3Time = format(new Date(), "HH:mm");
  day3Date = format(new Date(), "yyyy-MM-dd");
  day4Time = format(new Date(), "HH:mm");
  day4Date = format(new Date(), "yyyy-MM-dd");
  day5Time = format(new Date(), "HH:mm");
  day5Date = format(new Date(), "yyyy-MM-dd");
  icsiDetails = [] as IICSIDetail[];
  fertRate = 0;
  cleavageRate = 0;
  embryoQuality = "";
  holdingRemarks = "";
  injectionRemarks = "";
  oocyteRemarks = "";
  spermRemarks = "";
  isDay4Visible = false;
  isDay5Visible = false;
  icsiRemarks = "";

  constructor(init?: IICSI) {
    Object.assign(this, init);

    if (init && init.icsiDetails) {
      var icsiDetails = init.icsiDetails.map(
        (icsiDetail: IICSIDetail, index: number) => {
          icsiDetail.day1Egg = (index + 1).toString();
          icsiDetail.day2Egg = (index + 1).toString();
          icsiDetail.day3Egg = (index + 1).toString();
          icsiDetail.day4Egg = (index + 1).toString();
          icsiDetail.day5Egg = (index + 1).toString();

          return icsiDetail;
        }
      );

      this.icsiDetails = icsiDetails;
    }
    //Date
    if (init && init.date) {
      this.date = format(new Date(init.date), "yyyy-MM-dd");
    }

    if (init && init.day1Date) {
      this.day1Date = format(new Date(init.day1Date), "yyyy-MM-dd");
    }

    if (init && init.day2Date) {
      this.day2Date = format(new Date(init.day2Date), "yyyy-MM-dd");
    }

    if (init && init.day3Date) {
      this.day3Date = format(new Date(init.day3Date), "yyyy-MM-dd");
    }

    if (init && init.day4Date) {
      this.day4Date = format(new Date(init.day4Date), "yyyy-MM-dd");
    }

    if (init && init.day5Date) {
      this.day5Date = format(new Date(init.day5Date), "yyyy-MM-dd");
    }
    //Time
    if (init && init.time) {
      this.time = format(new Date(init.time), "HH:mm:ss");
    }

    if (init && init.icsiTimeStarted) {
      this.icsiTimeStarted = format(new Date(init.icsiTimeStarted), "HH:mm:ss");
    }

    if (init && init.icsiTimeEnded) {
      this.icsiTimeEnded = format(new Date(init.icsiTimeEnded), "HH:mm:ss");
    }

    if (init && init.day1Time) {
      this.day1Time = format(new Date(init.day1Time), "HH:mm:ss");
    }

    if (init && init.day1Time) {
      this.day1Time = format(new Date(init.day1Time), "HH:mm:ss");
    }

    if (init && init.day2Time) {
      this.day2Time = format(new Date(init.day2Time), "HH:mm:ss");
    }

    if (init && init.day2Time) {
      this.day2Time = format(new Date(init.day2Time), "HH:mm:ss");
    }

    if (init && init.day3Time) {
      this.day3Time = format(new Date(init.day3Time), "HH:mm:ss");
    }

    if (init && init.day4Time) {
      this.day4Time = format(new Date(init.day4Time), "HH:mm:ss");
    }

    if (init && init.day5Time) {
      this.day5Time = format(new Date(init.day5Time), "HH:mm:ss");
    }
  }
}
