import { IUserCredentials, IUserData } from "./../models/user";
import { observable, computed, action, runInAction } from "mobx";
import { IUser } from "../models/user";
import Users from "../services/userService";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { IUpdatePasswordForm } from "../models/auth";
import { toast } from "react-toastify";

export default class UserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable user: IUserCredentials | null = null;
  @observable usersList: IUserData[] = [];
  @observable pageIndex: number = 1;
  @observable pageSize: number = 10;
  @observable total: number = 0;
  @observable keywords: string | undefined = undefined;

  @computed get isLoggedIn() {
    return !!this.user;
  }

  @action getCurrentUser = async () => {
    try {
      const user = await Users.current();
      runInAction(() => {
        this.user = user;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action getUsers = async (
    pageIndex: number = 1,
    pageSize: number = 10,
    search?: string
  ) => {
    this.usersList = [];
    this.rootStore.commonStore.isDataLoading = true;
    try {
      const response = await Users.getUsers(pageIndex, pageSize, search);
      runInAction(() => {
        this.pageIndex = response.pageIndex;
        this.pageSize = response.pageSize;
        this.total = response.count;
        this.usersList = response.data;
        this.keywords = search;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.rootStore.commonStore.isDataLoading = false;
      });
    }
  };

  @action createUser = async (values: IUser) => {
    try {
      await Users.create(values);
      history.push("/users");
    } catch (error) {
      throw error;
    }
  };

  @action updatePassword = async (values: IUpdatePasswordForm) => {
    try {
      await Users.updatePassword(values);
      toast.success("Password successfully updated!");
      history.push("/");
    } catch (error) {
      throw error;
    }
  };
}
