import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { IDoctorOption } from "../../models/doctor";
import Form from "react-bootstrap/esm/Form";
import { FormikProps } from "formik";
import Doctors from "../../services/doctorService";
import RequiredIcon from "./RequiredIcon";

interface IProps {
 formikProps: FormikProps<any>;
 name: string;
 label?: string;
 placeholder?: string;
 showRequiredIcon?: boolean;
}

const DoctorSelect: React.FC<IProps> = ({
 name,
 formikProps,
 label = "",
 placeholder = "",
 showRequiredIcon = false,
}) => {
 const [options, setOptions] = useState<any[]>([]);
 const [isLoading, setIsLoading] = useState(false);
 const [isDisabled, setIsDisabled] = useState(true);
 const [selectedOption, setSelectedOption] = useState<any>();
 const [isEmpty, setIsEmpty] = useState(false);

 const getSelectedDoctor = async () => {
  setIsLoading(true);
  const doctor = formikProps.values[name.slice(0, -2)];
  if (doctor) {
   const option = {
    value: doctor.id,
    label: `${doctor.lastName}, ${doctor.firstName}`,
   };
   setSelectedOption(option);
   setOptions([option]);
   handleChange(option);
  }
  setIsLoading(false);
  setIsDisabled(false);
 };

 useEffect(() => {
  if (formikProps.values[name] > 0) {
   getSelectedDoctor();
  } else {
   setIsDisabled(false);
  }
  // eslint-disable-next-line
 }, []);

 const handleChange = (values: any) => {
  formikProps.setFieldValue(name, values.value);
  setSelectedOption(values);
 };

 const loadOptions = async (input: string) => {
  setIsLoading(true);
  const doctors = await Doctors.getOptions(input);
  const options = doctors.map((doctor: IDoctorOption) => {
   const option = {
    value: doctor.id,
    label: `${doctor.lastName}, ${doctor.firstName}`,
   };
   return option;
  });
  setOptions(options);
  setIsLoading(false);
  return options;
 };

 const handleBlur = () => {
  if (!selectedOption) setIsEmpty(true);
  else setIsEmpty(false);
 };

 return (
  <>
   <Form.Group controlId={name}>
    <Form.Label>
     {" "}
     {label}
     {showRequiredIcon && <RequiredIcon />}
    </Form.Label>
    <AsyncSelect
     cacheOptions
     isLoading={isLoading}
     defaultOptions={options}
     loadOptions={loadOptions}
     placeholder={placeholder}
     onChange={handleChange}
     value={selectedOption}
     isDisabled={isDisabled}
     onBlur={handleBlur}
    />
    {isEmpty && (
     <Form.Text style={{ color: "red" }}>{label} is required.</Form.Text>
    )}
   </Form.Group>
  </>
 );
};

export default DoctorSelect;
