import { RouteProps } from "react-router-dom";
import FormEmbryoTransfer from "../../../components/EmbryoTransfer/FormEmbryoTransfer";
import EmbryoTransfersList from "../../../components/EmbryoTransfer/EmbryoTransfersList"

const embryoTransferRoutes: RouteProps[] = [
  {
    path: "/embryo-transfers/edit/:id",
    component: FormEmbryoTransfer,
  },
  {
    path: "/embryo-transfers/add",
    component: FormEmbryoTransfer,
  },
  {
    path: "/embryo-transfers",
    component: EmbryoTransfersList,
  },
];

export default embryoTransferRoutes;

