import { RouteProps } from "react-router-dom";
import FormIcsi from "../../../components/ICSI/FormIcsi";
import ICSIsList from "../../../components/ICSI/ICSIsList";

const icsiRoutes: RouteProps[] = [
  {
    path: "/icsis/edit/:id",
    component: FormIcsi,
  },
  {
    path: "/icsis/add",
    component: FormIcsi,
  },
  {
    path: "/icsis",
    component: ICSIsList,
  },
];

export default icsiRoutes;
