import { format } from "date-fns/esm";
import { FormikProps } from "formik";
import React from "react";
import Button from "react-bootstrap/esm/Button";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import { IStoragePayment } from "../../app/models/spermFreezing";

interface IProps {
  formikProps: FormikProps<any>;
}

const StoragePaymentTable: React.FC<IProps> = ({ formikProps }) => {
  const { storagePayments } = formikProps.values;

  const editableColumns = [
    {
      title: "Date",
      field: "date",
      editor: DateEditor,
      editorParams: { format: "YYYY-MM-dd" },
      headerSort: false,
    },
    {
      title: "Amount",
      field: "amount",
      editor: "number",
      headerSort: false,
    },
    {
      title: "Next Date",
      field: "nextDate",
      editor: DateEditor,
      editorParams: { format: "YYYY-MM-dd" },
      headerSort: false,
    },
    {
      title: "Remarks",
      field: "remarks",
      editor: "input",
      headerSort: false,
    },
  ];

  const handleAddRow = () => {
    const newDate = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    const data = [...storagePayments];
    const newRow: IStoragePayment = {
      id: 0,
      date: format(new Date(), "yyyy-MM-dd"),
      amount: 0,
      nextDate: format(newDate, "yyyy-MM-dd"),
      remarks: "",
    };
    data.push(newRow);
    formikProps.setFieldValue("storagePayments", data);
  };

  const handleRemoveRow = () => {
    const data = [...storagePayments];
    data.pop();
    formikProps.setFieldValue("storagePayments", data);
  };

  return (
    <>
      <h4 className="mb-3 text-primary">Storage Fee Payment</h4>
      <div className="mb-3">
        <Button className="mr-2" onClick={handleAddRow}>
          Add
        </Button>
        <Button
          onClick={handleRemoveRow}
          disabled={storagePayments.length === 0}
        >
          Remove
        </Button>
      </div>
      <ReactTabulator
        className="table-sm mb-3 table-bordered"
        columns={editableColumns}
        data={formikProps.values.storagePayments}
      />
    </>
  );
};

export default StoragePaymentTable;
