import { RouteProps } from "react-router-dom";
import FormSpermCryopreservationReportIndex from "../../../components/SpermCryopreservationReport/FormSpermCryopreservationReportIndex";

const spermCryoPreservationRoutes: RouteProps[] = [
  {
    path: "/reports/sperm-cryopreservation",
    component: FormSpermCryopreservationReportIndex,
  },
];

export default spermCryoPreservationRoutes;
