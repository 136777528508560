import { OptionHTMLAttributes } from "react";

export const cervixOptions: OptionHTMLAttributes<HTMLOptionElement>[] = [
  {
    label: "Healthy",
    value: "Healthy",
  },
  {
    label: "Cervicitis",
    value: "Cervicitis",
  },
];
