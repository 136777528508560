import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomTable from "../../app/common/table/CustomTable";
import { IICSI } from "../../app/models/icsi";
import { RootStoreContext } from "../../app/stores/rootStore";

interface IProps {
  printButton?: boolean;
}

const ICSIsList: React.FC<IProps> = ({ printButton = false }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    getICSIs,
    icsisList,
    total,
    pageSize,
    pageIndex,
    keywords,
  } = rootStore.icsiStore;

  useEffect(() => {
    getICSIs();
  }, [getICSIs]);

  const columns: IDataTableColumn[] = [
    {
      name: "Id",
      selector: "id",
    },
    {
      name: "Patient Name",
      selector: "patientName",
    },
    {
      name: "Procedure Date",
      selector: "procedureDate",
      format: (row) => format(new Date(row.procedureDate), "yyyy-MM-dd"),
    },
    {
      name: "",
      button: true,
      cell: (row: IICSI) => (
        <>
          {!printButton ? (
            <Button
              as={Link}
              to={`/icsis/edit/${row.id}`}
              variant="outline-primary"
            >
              Edit
            </Button>
          ) : (
            <Button
              as={Link}
              to={`/reports/embryology-summary-report/edit/${row.id}`}
              variant="outline-primary"
            >
              Print
            </Button>
          )}
        </>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    getICSIs(newPage, pageSize, keywords);
  };

  const handlePerRowsChange = (newPageSize: number, page: number) => {
    getICSIs(page, newPageSize, keywords);
  };

  const handleSearch = (keywords: string) => {
    getICSIs(pageIndex, pageSize, keywords);
  };

  return (
    <Container>
      <CustomTable
        title="ICSI Records"
        columns={columns}
        data={icsisList}
        paginationTotalRows={total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSearch={handleSearch}
        rightComponent={
          <>
            {!printButton ? (
              <Button as={Link} to="/icsis/add">
                Add ICSI
              </Button>
            ) : null}
          </>
        }
      />
    </Container>
  );
};

export default observer(ICSIsList);
