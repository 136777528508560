import { IUserCredentials, IUserData, IUserPagination } from "./../models/user";
import { IUser } from "../models/user";
import requests from "./httpService";
import { IUpdatePasswordForm } from "../models/auth";

const api = "/users";

const Users = {
  current: (): Promise<IUserCredentials> => requests.get(`${api}/current-user`),
  getUsers: (
    pageIndex: number,
    pageSize: number,
    search?: string
  ): Promise<IUserPagination> =>
    requests.get(
      `${api}?pageIndex=${pageIndex}&pageSize=${pageSize}${
        search ? `&search=${search}` : ``
      }`
    ),
  create: (values: IUser): Promise<IUserData> =>
    requests.post(`${api}`, values),
  updatePassword: (values: IUpdatePasswordForm): Promise<string> =>
    requests.put(`${api}/update-password`, values),
};

export default Users;
