import { IPagination } from "./pagination";

export interface IDoctor {
  id?: number;
  firstName: string;
  middleName: string;
  lastName: string;
  isActive?: boolean;
}

export interface IDoctorData {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface IDoctorOption {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  isActive: boolean;
}

export interface IDoctorPagination extends IPagination {
  data: IDoctorData[];
}

export class DoctorFormValues implements IDoctor {
  firstName = "";
  middleName = "";
  lastName = "";
  isActive = false;

  constructor(init?: IDoctor) {
    Object.assign(this, init);
  }
}
