import { FormikProps } from "formik";
import React from "react";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";

interface IProps {
 formikProps: FormikProps<any>;
}

const ICSIDay2Table: React.FC<IProps> = ({ formikProps }) => {
 const { icsiDetails } = formikProps.values;

 const editableColumns = [
  {
   title: "EGG",
   field: "day2Egg",
   headerSort: false,
   width: 50,
  },
  {
   title: "Dish",
   field: "day2Dish",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Cell",
   field: "day2Cell",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Frag",
   field: "day2Frag",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Score",
   field: "day2Score",
   editor: "input",
   headerSort: false,
  },
  {
   title: "Notes",
   field: "day2Notes",
   editor: "input",
   headerSort: false,
  },
 ];

 return (
  <ReactTabulator
   className="table-sm mb-3 table-bordered table-responsive text-uppercase"
   columns={editableColumns}
   data={icsiDetails}
  />
 );
};

export default ICSIDay2Table;
