import React, { useContext } from "react";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import { IResetPasswordForm } from "../../app/models/auth";
import { RootStoreContext } from "../../app/stores/rootStore";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Button from "react-bootstrap/esm/Button";
import { Link, RouteComponentProps } from "react-router-dom";
import Spinner from "react-bootstrap/esm/Spinner";
import TextInput from "../../app/common/forms/TextInput";
import Alert from "react-bootstrap/esm/Alert";

const validationSchema = Yup.object<IResetPasswordForm>().shape({
  code: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string()
    .min(6)
    .matches(new RegExp("[a-z]"), "password must contain a lowercase letter")
    .matches(new RegExp("[A-Z]"), "password must contain an uppercase letter")
    .matches(new RegExp("[0-9]"), "password must contain a number")
    .matches(
      new RegExp("[^a-zA-Z0-9]"),
      "password must contain a non-alphanumeric character"
    )
    .required("New password is required"),
});

interface IParams {
  code: string;
}

const FormResetPassword: React.FC<RouteComponentProps<IParams>> = ({
  match: {
    params: { code },
  },
}) => {
  const rootStore = useContext(RootStoreContext);
  const { resetPassword } = rootStore.authStore;

  return (
    <Col sm={12} md={4} className="align-self-center">
      <Card>
        <Card.Body>
          <h1 className="text-center mb-3">Password Reset Form</h1>
          <Formik
            validationSchema={validationSchema}
            onSubmit={(
              values: IResetPasswordForm,
              { setSubmitting, setErrors }
            ) => {
              resetPassword(values).catch((error) => {
                if (error) {
                  setErrors({ submitError: error.data.message });
                  setSubmitting(false);
                }
              });
            }}
            initialValues={{ code, email: "", password: "" }}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <Form>
                {formikProps.errors.submitError !== undefined ? (
                  <Alert variant="danger">
                    {formikProps.errors.submitError}
                  </Alert>
                ) : null}
                <TextInput
                  name="email"
                  label="Email"
                  placeholder="Email"
                  formikProps={formikProps}
                />
                <TextInput
                  name="password"
                  label="New Password"
                  type="password"
                  placeholder="New Password"
                  formikProps={formikProps}
                />
                <Button
                  variant="primary"
                  type="submit"
                  block
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                >
                  {formikProps.isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-2"
                    />
                  )}
                  Submit
                </Button>
                <Button as={Link} to="/login" variant="outline-secondary" block>
                  Back
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default FormResetPassword;
