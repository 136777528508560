import { FormikProps } from "formik";
import React from "react";
import Form from "react-bootstrap/esm/Form";
import { FormControlProps } from "react-bootstrap/esm/FormControl";
import _ from "lodash";
import RequiredIcon from "./RequiredIcon";

interface IProps extends FormControlProps {
 formikProps: FormikProps<any>;
 label?: string;
 name: string;
 placeholder?: string;
 showRequiredIcon?: boolean;
 afterOnChange?: (values: any) => void;
}

const TextInput: React.FC<IProps> = (props) => {
 const {
  formikProps,
  label = "",
  name,
  placeholder = "",
  showRequiredIcon = false,
  afterOnChange,
 } = props;
 const baseProps = _.omit(props, ["formikProps", "afterOnChange"]);

 return (
  <>
   <Form.Group controlId={name}>
    <Form.Label>
     {label}
     {showRequiredIcon && <RequiredIcon />}
    </Form.Label>
    <Form.Control
     placeholder={placeholder}
     value={formikProps.values[name]}
     onChange={(e) => {
      formikProps.handleChange(e);
      if (afterOnChange) {
       afterOnChange(e.target.value);
      }
     }}
     onBlur={formikProps.handleBlur}
     {...baseProps}
    />
    {formikProps.errors[name] && (
     <Form.Control.Feedback className="d-block" type="invalid">
      <p className="m-0">{formikProps.errors[name]}</p>
     </Form.Control.Feedback>
    )}
   </Form.Group>
  </>
 );
};

export default TextInput;
