import { Formik, Form } from "formik";
import React, { useContext } from "react";
import { IForgotPasswordForm } from "../../app/models/auth";
import * as Yup from "yup";
import { RootStoreContext } from "../../app/stores/rootStore";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Alert from "react-bootstrap/esm/Alert";
import TextInput from "../../app/common/forms/TextInput";
import Spinner from "react-bootstrap/esm/Spinner";
import { Link } from "react-router-dom";

const validationSchema = Yup.object<IForgotPasswordForm>().shape({
  email: Yup.string().email().required(),
});

const FormForgotPassword = () => {
  const rootStore = useContext(RootStoreContext);
  const { forgotPassword } = rootStore.authStore;

  return (
    <Col sm={12} md={4} className="align-self-center">
      <Card>
        <Card.Body>
          <h1 className="text-center mb-3">Request a Password Reset</h1>
          <Formik
            validationSchema={validationSchema}
            onSubmit={(
              values: IForgotPasswordForm,
              { setSubmitting, setErrors }
            ) => {
              forgotPassword(values.email).catch((error) => {
                if (error) {
                  setErrors({ submitError: error.data.message });
                  setSubmitting(false);
                }
              });
            }}
            initialValues={{ email: "" }}
          >
            {(formikProps) => (
              <Form>
                {formikProps.errors.submitError !== undefined ? (
                  <Alert variant="danger">
                    {formikProps.errors.submitError}
                  </Alert>
                ) : null}
                <TextInput
                  name="email"
                  label="Enter Your Email"
                  type="email"
                  placeholder="Email"
                  formikProps={formikProps}
                />
                <Button
                  variant="primary"
                  type="submit"
                  block
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                >
                  {formikProps.isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-2"
                    />
                  )}
                  Submit
                </Button>
                <Button as={Link} to="/login" variant="outline-secondary" block>
                  Back
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default FormForgotPassword;
